import { observable, makeObservable , action, computed ,toJS} from "mobx";
import axios from 'axios';
import { PayabliStorageManager } from '../api/localStorageManager'

class PaymentPageStore {

    constructor() {
        makeObservable(this)
    }

    encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();

    @observable
    previewLogo = null;

    @observable
    saveAction = 'new';

    @observable
    currentPage = '';
   
    @observable
    showPaymentPageNameError = false;
    
    @observable
    showPaymentPageSubdomainError = false;

    @observable
    paymentPageErrors = {
        paymentMethodsAchAccountHolderNameError: false
    }

    @observable
    paymentPages= [];

    maskValidator(validation){

        switch(validation) {
            case 'alpha':
                return /^[a-zA-Z\s]*$/;
            case 'numbers':
                return Number;
            case 'text':
                return String;
            case 'email':
                return  /^([a-z0-9-._]*)@?([a-z0-9-]*)\.?([a-z]*)$/;
            case 'phone':
                return '000 000-00-00';
            case 'routing':
                return '000000000';
            case 'accountnumber':
                return /^[0-9]+$/i;
            case 'cardDate':
                return '00/00';
            case 'cvv':
                return '000';
            case 'zipcode':
                return /^(?=.{0,7}$)([A-Za-z0-9])+ ?([A-Za-z0-9]){0,3}$/g
            case 'creditCard':
                return '0000 0000 0000 0000';
            case 'creditCardAmex':
                return '0000 0000 0000 0000';
            default:
                return String;
          }

    }


    @observable
    paymentPage= {
        subdomain: '',
        name: '',
        logo:{
            enabled: true,
            order: 0
        },
        page:{
            header: 'Make a Payment',
            description: 'Please enter your information below',
            enabled: true,
            order: 1
        },
        amount:{
            categories: [],
            enabled: true,
            order: 2
        },
        autopay:{
            header: 'Payment Schedule',
            finish: {
                untilCancelled: true,
                calendar: false,
            },
            frequency: {
                weekly: false,
                every2weeks: false,
                every6months: false,
                monthly: false,
                every3months: false,
                annually: false
            },
            enabled: true,
            order: 3
        },
        payor: {
            header: 'Payor Information',
            enabled: true,
            order: 4,
            fields: [
                {
                    name: "companyName",
                    required: true,
                    label: 'Company Name',
                    validation: 'alpha',
                    display: true,
                    Fixed: false,
                    order: 0,
                    width: 12,
                    identifier: false
                },
                {
                    name: "firstName",
                    required: true,
                    label: 'First Name',
                    validation: 'alpha',
                    display: true,
                    Fixed: true,
                    order: 1,
                    width: 6,
                    identifier: false
                },
                {
                    name: "lastName",
                    required: true,
                    label: 'Last Name',
                    validation: 'alpha',
                    display: true,
                    Fixed: true,
                    order: 2,
                    width: 6,
                    identifier: false
                },
                {
                    name: "email",
                    required: true,
                    label: 'Email',
                    validation: 'email',
                    display: true,
                    order: 3,
                    Fixed: true,
                    width: 6,
                    identifier: false
                },
                {
                    name: "primaryPhone",
                    required: true,
                    label: 'Primary Phone',
                    validation: 'phone',
                    display: true,
                    order: 4,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "customerNumber",
                    required: false,
                    label: 'Customer #',
                    validation: 'numbers',
                    display: true,
                    order: 5,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "invoiceNumber",
                    required: true,
                    label: 'Invoice #',
                    validation: 'numbers',
                    display: true,
                    order: 5,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "billingAddress",
                    required: true,
                    label: 'Billing Address #',
                    validation: 'text',
                    display: false,
                    order: 6,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "shippingAddress",
                    required: true,
                    label: 'Shipping Address',
                    validation: 'text',
                    display: false,
                    order: 7,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "miscField",
                    required: true,
                    label: 'Misc. Field',
                    validation: 'text',
                    display: false,
                    order: 8,
                    Fixed: false,
                    width: 12,
                    identifier: false
                }
            ]
        },
        paymentMethods:{
            header: 'Payment Method',
            methods: {
                visa: true,
                mastercard: true,
                discover: true,
                amex: true,
                eCheck: true,
                applePay: true,
                googlePay: true,
                payPal: true,
                bitPay: true,
            },
            enabled: true,
            allMethodsChecked: true,
            order: 5
        },
        notes:{
            header: 'Notes',
            placeholder: 'Please enter additional information...',
            enabled: true,
            value: '',
            order: 6
        },
        review:{
            header: 'Review & Confirm Payment',
            enabled: true,
            order: 7
        },
        messageBeforePaying:{
            label: 'Disclaimer - Please note your payment may take 1 - 3 days to post.',
            enabled: true,
            order: 8
        },
        paymentButton:{
            label: 'Pay',
            order: 9
        },
        contactUs:{
            header: 'Contact Us',
            emailLabel: '',
            phoneLabel: '',
            paymentIcons: true,
            enabled: true,
            order: 10
        }
    }

    paymentPageTemplate= {
        subdomain: '',
        name: '',
        logo:{
            enabled: true,
            order: 0
        },
        page:{
            header: 'Make a Payment',
            description: 'Please enter your information below',
            enabled: true,
            order: 1
        },
        amount:{
            categories: [],
            enabled: true,
            order: 2
        },
        autopay:{
            header: 'Payment Schedule',
            finish: {
                untilCancelled: true,
                calendar: false,
            },
            frequency: {
                weekly: false,
                every2weeks: false,
                every6months: false,
                monthly: false,
                every3months: false,
                annually: false
            },
            enabled: true,
            order: 3
        },
        paymentMethods:{
            header: 'Payment Method',
            methods: {
                visa: true,
                mastercard: true,
                discover: true,
                amex: true,
                eCheck: true,
                applePay: true,
                googlePay: true,
                payPal: true,
                bitPay: true,
            },
            enabled: true,
            allMethodsChecked: true,
            order: 5
        },
        notes:{
            header: 'Notes',
            placeholder: 'Please enter additional information...',
            enabled: true,
            value: '',
            order: 6
        },
        messageBeforePaying:{
            label: 'Disclaimer - Please note your payment may take 1 - 3 days to post.',
            enabled: true,
            order: 8
        },
        paymentButton:{
            label: 'Pay',
            order: 9
        },
        contactUs:{
            header: 'Contact Us',
            emailLabel: '',
            phoneLabel: '',
            paymentIcons: true,
            enabled: true,
            order: 10
        },
        payor: {
            header: 'Payor Information',
            enabled: true,
            order: 4,
            fields: [
                {
                    name: "companyName",
                    required: true,
                    label: 'Company Name',
                    validation: 'alpha',
                    display: true,
                    Fixed: false,
                    order: 0,
                    width: 12,
                    identifier: false
                },
                {
                    name: "firstName",
                    required: true,
                    label: 'First Name',
                    validation: 'alpha',
                    display: true,
                    Fixed: true,
                    order: 1,
                    width: 6,
                    identifier: false
                },
                {
                    name: "lastName",
                    required: true,
                    label: 'Last Name',
                    validation: 'alpha',
                    display: true,
                    Fixed: true,
                    order: 2,
                    width: 6,
                    identifier: false
                },
                {
                    name: "email",
                    required: true,
                    label: 'Email',
                    validation: 'email',
                    display: true,
                    order: 3,
                    Fixed: true,
                    width: 6,
                    identifier: false
                },
                {
                    name: "primaryPhone",
                    required: true,
                    label: 'Primary Phone',
                    validation: 'phone',
                    display: true,
                    order: 4,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "customerNumber",
                    required: true,
                    label: 'Customer #',
                    validation: 'numbers',
                    display: true,
                    order: 5,
                    Fixed: true,
                    width: 6,
                    identifier: false
                },
                {
                    name: "invoiceNumber",
                    required: true,
                    label: 'Invoice #',
                    validation: 'numbers',
                    display: true,
                    order: 5,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "billingAddress",
                    required: true,
                    label: 'Billing Address #',
                    validation: 'text',
                    display: false,
                    order: 6,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "shippingAddress",
                    required: true,
                    label: 'Shipping Address',
                    validation: 'text',
                    display: false,
                    order: 7,
                    Fixed: false,
                    width: 6,
                    identifier: false
                },
                {
                    name: "miscField",
                    required: true,
                    label: 'Misc. Field',
                    validation: 'text',
                    display: false,
                    order: 8,
                    Fixed: false,
                    width: 12,
                    identifier: false
                }
            ]
        },
        review:{
            header: 'Review & Confirm Payment',
            enabled: true,
            order: 7
        }
    }

    paymentReceiptTemplate = {
        logo:{
            enabled: true,
            order: 0
        },
        page:{
            header: 'Payment Receipt',
            description: '',
            enabled: true,
            order: 1
        },
        amount:{
            enabled: true,
            order: 2
        },
        settings:{
            sendAuto: false,
            sendManual: true,
            enabled: true,
            order: 3,
            fields: [
                {
                    name: 'firstName',
                    display: true,
                    Fixed: true,
                },
                {
                    name: 'lastName',
                    display: true,
                    Fixed: true,
                },
                {
                    name: 'primaryPhone',
                    display: true,
                    Fixed: false,
                },
                {
                    name: 'invoiceNumber',
                    display: true,
                    Fixed: true,
                },
                {
                    name: 'miscCreated',
                    display: true,
                    Fixed: false,
                },
                {
                    name: 'notes',
                    display: true,
                    Fixed: false,
                }
            ]
        },
        details:{
            enabled: true,
            order: 4
        },
        paymentInformation:{
            enabled: true,
            order: 5
        },
        messageBeforeButton:{
            label: '',
            enabled: true,
            order: 6
        },
        paymentButton:{
            enabled: true,
            order: 7,
            label: 'Setup One Click Payment',
        },
        contactUs:{
            enabled: true,
            order: 8
        }

    }

    paymentPageSettingsTemplate = {
        color: '#10A0E3',
        paymentButton: {
            label: 'Pay Now',
            size: 'sm'
        },
        customCssUrl: '',
        language: 'en',
        customUrl: '',
        pageLogo: {
            ftype: "",
            filename: null,
            furl: null,
            fContent: ""
        },
        redirectAfterApprove: false,
        redirectAfterApproveUrl: '',
        notificationEmail: '',
        allApprovedPaymentNotification: false,
        dailyBatchReportNotification: false
    }

    @observable
    paymentReceipt = {
        logo:{
            enabled: true,
            order: 0
        },
        page:{
            header: 'Payment Receipt',
            description: 'Transaction details',
            enabled: true,
            order: 1
        },
        amount:{
            enabled: true,
            order: 2
        },
        settings:{
            sendAuto: false,
            sendManual: true,
            enabled: true,
            order: 3,
            fields: [
                {
                    name: 'firstName',
                    display: true,
                    Fixed: true,
                },
                {
                    name: 'lastName',
                    display: true,
                    Fixed: true,
                },
                {
                    name: 'primaryPhone',
                    display: true,
                    Fixed: false,
                },
                {
                    name: 'invoiceNumber',
                    display: true,
                    Fixed: true,
                },
                {
                    name: 'miscCreated',
                    display: true,
                    Fixed: false,
                },
                {
                    name: 'notes',
                    display: true,
                    Fixed: false,
                }
            ]
        },
        details:{
            enabled: true,
            order: 4
        },
        paymentInformation:{
            enabled: true,
            order: 5
        },
        messageBeforeButton:{
            label: 'Thank you for your payment.',
            enabled: true,
            order: 6
        },
        paymentButton:{
            enabled: true,
            order: 7,
            label: 'Setup One Click Payment',
        },
        contactUs:{
            enabled: true,
            order: 8
        }

    }

    @observable
    paymentPageSettings = {
        color: '#10A0E3',
        paymentButton: {
            label: 'Pay Now',
            size: 'sm'
        },
        customCssUrl: '',
        language: 'en',
        customUrl: '',
        pageLogo: {
            ftype: "",
            filename: null,
            furl: null,
            fContent: ""
        },
        redirectAfterApprove: false,
        redirectAfterApproveUrl: '',
        notificationEmail: '',
        allApprovedPaymentNotification: false,
        dailyBatchReportNotification: false
    }

    @computed
    get hasCards(){
        return this.paymentPage.paymentMethods.methods.visa || 
        this.paymentPage.paymentMethods.methods.mastercard || 
        this.paymentPage.paymentMethods.methods.discover || 
        this.paymentPage.paymentMethods.methods.amex
        ;
    }

    @computed
    get payerFields(){
        return this.paymentPage.payor.fields; 
    }

    @computed
    get getPaymentPageErrors(){
        return this.paymentPageErrors;
    }
    
    @computed
    get paymentPageColor(){
        return this.paymentPageSettings.color; 
    }

    @computed
    get amountCategories(){
        return this.paymentPage.amount.categories; 
    }

    @computed
    get hasECheck(){
        return this.paymentPage.paymentMethods.methods.eCheck;
    }

    @action
    handleTextChange(block, key ,event) {
        this.paymentPage[block][key]= event.target.value
    }
    
    @action
    handleReceiptTextChange(block, key ,event) {
        this.paymentReceipt[block][key]= event.target.value;
    }
    
    @action
    setPPSettingsColor(color) {
        this.paymentPageSettings.color = color;
    }

    @action
    setCurrentPage(page) {
        this.currentPage = page;
    }

    @computed
    get getCurrentPage(){
        return this.currentPage;
    }
    
    @action
    handlePPSettingsPaymentButtonTextChange(event) {
        this.paymentPageSettings.paymentButton.label = event.target.value;
    }
    
    @action
    handlePageNameChange(event) {
        var value = event.target.value;
        this.paymentPage.name= value;
    }

    @action
    setPageName(name) {
        this.paymentPage.name= name;
    }
    
    @action
    setPageSubdomain(subdomain) {
        this.paymentPage.subdomain= subdomain;
    }

    @action
    setPreviewLogo(base64){
        this.previewLogo = base64;
    }

    @action
    setLogo(base64, ext) {
        
        if(base64 &&  ext){
            this.paymentPageSettings.pageLogo = {
                ftype: ext,
                filename: null,
                furl: null,
                fContent:base64
            }  
        }
        
    }

    @computed
    get getPageName() {
        return this.paymentPage.name;
    }
    
    @action
    handleCategoryTextChange(field, key ,event) {
        this.paymentPage.amount.categories[field][key]= event.target.value;
    }

    @action
    setSaveAction(action) {
        this.saveAction = action;
    }
    
    @action
    handleCategoryTextChangeMask(field, key ,value) {
        this.paymentPage.amount.categories[field][key]= value;
    }

    @action
    testDataJson() {
        return toJS(this.paymentPage);
    }
    
    @action
    handlePayorfieldTextChange(event) {
        this.paymentPage.payor.fields[event.target.id].label= event.target.value
    }
    
    @action
    disableTitle(checked) {
        this.paymentPage.page.enabled= checked;
    }
    
    @action
    disableContactUs(checked) {
        this.paymentPage.contactUs.enabled= checked;
    }
    
    @action
    disableReceiptSettings(checked) {
        this.paymentReceipt.settings.enabled= checked;
    }
    
    @action
    disableReceiptContactUs(checked) {
        this.paymentReceipt.contactUs.enabled= checked;
    }
    
    @action
    disableReview(checked) {
        this.paymentPage.review.enabled= checked;
    }
    
    @action
    disableLogo(checked) {
        this.paymentPage.logo.enabled= checked;
    }
    
    @action
    disableReceiptLogo(checked) {
        this.paymentReceipt.logo.enabled= checked;
    }

    @action
    disableNotes(checked){
        this.paymentPage.notes.enabled= checked;
    }

    @action
    disableMessageBeforePaying(checked){
        this.paymentPage.messageBeforePaying.enabled= checked;
    }
    
    @action
    disableMessageBeforeButton(checked){
        this.paymentReceipt.messageBeforeButton.enabled= checked;
    }

    @action
    disableReceiptTitle(checked){
        this.paymentReceipt.page.enabled= checked;
    }

    @action
    disableAutopay(checked){
        this.paymentPage.autopay.enabled = checked;
    }

    @action
    disablePayer(checked){
        this.paymentPage.payor.enabled = checked;
    }
   
    @action
    handleAutopayFrequency(event){
        this.paymentPage.autopay.frequency[event.target.id] = !this.paymentPage.autopay.frequency[event.target.id];
    }
    
    @action
    handleContactUsPaymentIcons(){
        this.paymentPage.contactUs.paymentIcons = !this.paymentPage.contactUs.paymentIcons;
    }

    @action
    handlePaymentMethods(event){
        this.paymentPage.paymentMethods.methods[event.target.id] = !this.paymentPage.paymentMethods.methods[event.target.id];
    }
    
    @action
    handleReceiptSettings(event){

        var field = (event.target.id).replace("settingField", "");
        this.paymentReceipt.settings.fields[field].display = !this.paymentReceipt.settings.fields[field].display;
    }
    
    @action
    handlePPSettingsPaymentButtonSize(event){
        this.paymentPageSettings.paymentButton.size = event.target.value;
    }
    
    @action
    handlePayorFieldsChecked(event){
        var field = (event.target.id).replace("payerFieldRequired", "");
        this.paymentPage.payor.fields[field].required = !this.paymentPage.payor.fields[field].required;

       
        if(this.paymentPage.payor.fields[field].required){
            this.paymentPage.payor.fields[field].display = true;
        }
    }
    
    @action
    handlePayorFieldsIdentifier(event){
        var field = (event.target.id).replace("payerFieldIdentifier", "");
        this.paymentPage.payor.fields[field].identifier = !this.paymentPage.payor.fields[field].identifier;

        if(this.paymentPage.payor.fields[field].identifier){
            this.paymentPage.payor.fields[field].required = true;
            this.paymentPage.payor.fields[field].display = true;
        }
    }
    
    @action
    handleCategoryShowDescription(event){
        var category = (event.target.id).replace("amountCategoryCheckDescription", "");
        this.paymentPage.amount.categories[category].showDescription = !this.paymentPage.amount.categories[category].showDescription
    }
    
    @action
    handleCategoryOptionalPay(event){
        var category = (event.target.id).replace("amountCategoryCheckOptional", "");
        this.paymentPage.amount.categories[category].optionalPay = !this.paymentPage.amount.categories[category].optionalPay
    }
    
    @action
    handlePayorFieldsWidth(event){
        var field = (event.target.id).replace("payerFieldWidth", "");
        this.paymentPage.payor.fields[field].width = parseInt(event.target.value);
    }

    @action
    handlePayorFieldsValidation(event){
        var field = (event.target.id).replace("payerFieldValidation", "");
        this.paymentPage.payor.fields[field].validation = event.target.value;
    }
    
    @action
    removePayorfield(event){
        var idRemove = (event.target.id).replace("payerFieldRemove", "");
        this.paymentPage.payor.fields.splice(idRemove, 1);
    }
    
    @action
    removeAmountCategory(event){
        var idRemove = (event.target.id).replace("categoryRemove", "");
        this.paymentPage.amount.categories.splice(idRemove, 1);
    }

    @action
    handleAutopayFinish(event){
        this.paymentPage.autopay.finish.calendar = false;
        this.paymentPage.autopay.finish.untilCancelled = false;
        this.paymentPage.autopay.finish[event.target.id] = true;
    }

    @action
    handleReceiptSend(event){
        this.paymentReceipt.settings.sendAuto = false;
        this.paymentReceipt.settings.sendManual = false;
        this.paymentReceipt.settings[event.target.id] = true;
    }

    @computed
    get getPaymentReceiptSettingsFields(){
        return this.paymentReceipt.settings.fields;
    }
    
    @action
    handleAmountCategoryType(event){
        var category = event.target.id.replace("amountCategoryTypeQuantity", "");
        category = category.replace("amountCategoryTypeFixed", "");
        category = category.replace("amountCategoryTypeCustomer", "");
        this.paymentPage.amount.categories[category].type = event.target.value;
    }
    
    @action
    handleCategoryQtySubstract(event){
        var category = event.target.id.replace("amountCategoryQtySubstract", "");
        if(this.paymentPage.amount.categories[category].quantity > 1)
            this.paymentPage.amount.categories[category].quantity--;
    }

    @action
    handleCategoryQtyAdd(event){
        var category = event.target.id.replace("amountCategoryQtyAdd", "");
        this.paymentPage.amount.categories[category].quantity++;
    }
    
    @action
    handleAllPaymentMethods(){
        var checked = !this.paymentPage.paymentMethods.allMethodsChecked;
        this.paymentPage.paymentMethods.allMethodsChecked = checked;

        this.paymentPage.paymentMethods.methods.visa = checked;
        this.paymentPage.paymentMethods.methods.mastercard = checked;
        this.paymentPage.paymentMethods.methods.discover = checked;
        this.paymentPage.paymentMethods.methods.amex = checked;
        this.paymentPage.paymentMethods.methods.eCheck = checked;
        this.paymentPage.paymentMethods.methods.applePay = checked;
        this.paymentPage.paymentMethods.methods.googlePay = checked;
        this.paymentPage.paymentMethods.methods.payPal = checked;
        this.paymentPage.paymentMethods.methods.bitPay = checked;
    }

    @action
    displayField(checked, event, field){
        this.paymentPage.payor.fields[field].display = checked;
    }
    
    @action
    updateBlocksOrder(updateBlocksOrder){
        var paymentPage  = this.paymentPage;
        updateBlocksOrder.forEach(function (item, index) {
            var block = item.replace("block", "");
            if(paymentPage[block]){
                paymentPage[block].order = index;
            }
           
        });
    }
    
    @action
    updateReceiptBlocksOrder(updateBlocksOrder){
        var paymentReceipt  = this.paymentReceipt;
        updateBlocksOrder.forEach(function (item, index) {
            var block = item.replace("block", "");
            if(paymentReceipt[block]){
                paymentReceipt[block].order = index;
            }
           
        });
    }
    
    @action
    updateCategoryBlocksOrder(updateCategoryBlocksOrder){
        var paymentPage  = this.paymentPage;
        
        updateCategoryBlocksOrder.forEach(function (item, index) {
            var block = item.replace("blockcategory", "");
            paymentPage.amount.categories[block].order = index;
        });
    }

    @action
    updatePayerFieldsOrder(idsInOrder){
        var paymentPage  = this.paymentPage;
        idsInOrder.forEach(function (item, index) {
            var field = item.replace("payerConfig", "");
            paymentPage.payor.fields[field].order = index;
        });
    }
    
    @action
    addNewPayorField(){
        var fieldId = Date.now();
        var newField = {
            name: "misc_"+String(fieldId),
            required: false,
            label: 'Item Name',
            validation: '',
            display: true,
            Fixed: false,
            order: this.getFieldOrder(this.paymentPage.payor.fields),
            width: 12,
            identifier: false
        };
        this.paymentPage.payor.fields.push(newField);
    }

    getFieldOrder(items){
        var max = 0;
        items.forEach(function (item, index) {
            if(parseInt(item.order) > parseInt(max)){
                max = parseInt(item.order);
            }
        });
        return max + 1;
    }

    @action 
    newPaymentPageData(){
        this.setPageName('');
        this.paymentPage = this.paymentPageTemplate;
        this.paymentReceipt = this.paymentReceiptTemplate;
        this.paymentPageSettings = this.paymentPageSettingsTemplate;
    }

    @action
    addNewAmountCategoryField(){
        var fieldId = Date.now();
        var newField = {
            name: String(fieldId),
            type: 'customer',
            label: 'Item Name',
            value: '0.00',
            description: 'Item description (optional)',
            order: this.getFieldOrder(this.paymentPage.amount.categories),
            quantity: 1,
            showDescription: false,
            optionalPay: false,
            optionalPayChecked: false
        };
        
        this.paymentPage.amount.categories.push(newField);
    }

    @action
    getPaymentPagesFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Paypoint/' + this.entryPoint,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                this.setPaymentPages(res.data.responseData.EntryPages);
            })
            .catch(error => {
                throw error;
            });
    }

    normalizeBase64(string){
        let stringArray = string.split(',');
        if(stringArray[1]){
            return stringArray[1];
        }
        else{
            return string;
        }
    }

    @action
    savePaymentPage(){
       
        if(this.getSaveAction === 'new'){
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            
            return axios.post(process.env.REACT_APP_URL_API+ 'Paypoint/' + this.entryPoint, {
                "subdomain": this.paymentPage.subdomain,
                "PageContent": toJS(this.paymentPage),
                "ReceiptContent": toJS(this.paymentReceipt),
                "PageSettings": toJS(this.paymentPageSettings),
                "AdditionalData": {}
            },
            {
                headers: {'requestToken': encryptStorage.getItem('pToken')}
            })
            .then(res => {
                
            })
            .catch(error => {
                
                throw error;
            });
        }
        else if(this.getSaveAction === 'edit'){
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();

            return axios.put(process.env.REACT_APP_URL_API+ 'Paypoint/' + this.entryPoint + '/' + this.paymentPage.subdomain, {
                "subdomain": this.paymentPage.subdomain,
                "PageContent": toJS(this.paymentPage),
                "ReceiptContent": toJS(this.paymentReceipt),
                "PageSettings": toJS(this.paymentPageSettings),
                "AdditionalData": {}
            },
            {
                headers: {'requestToken': encryptStorage.getItem('pToken')}
            })
            .then(res => {
               
            })
            .catch(error => {
                throw error;
            });
        }
    }
    

    @action
    deletePaymentPage(sudomain){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.delete(process.env.REACT_APP_URL_API+ 'Paypoint/' + this.entryPoint + '/' + sudomain,
        {
            headers: {'requestToken': encryptStorage.getItem('pToken')}
        })
        .then(res => {
            var paymentPages = this.paymentPages;
            paymentPages.forEach(function (item, index) {
                if(item.Subdomain === sudomain){
                    paymentPages.splice(index, 1);
                    return;
                }
            });
        })
        .catch(error => {
            throw error;
        });
    }

    @action
    setPaymentPages(EntryPages){
        this.paymentPages = EntryPages;
    }
   
    @action
    setPaymentPage(paymentPage){
        this.paymentPage = paymentPage;
    }
    
    @action
    setPaymentReceipt(paymentReceipt){
        this.paymentReceipt = paymentReceipt;
    }

    @action
    setPaymentPageSubdomain(subdomain){
        this.paymentPage.subdomain = subdomain;
    }

    @computed
    get getPaymentPageSubdomain(){
        return this.paymentPage.subdomain;
    }
   
    @action
    setPaymentSettings(pageSettings){
        this.paymentPageSettings = pageSettings;
    }

    
    
    @action
    getPaymentPageFromApi(subdomain){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        return axios.get(process.env.REACT_APP_URL_API+ 'Paypoint/' + this.entryPoint + "/" + subdomain,{
            headers: {
              'requestToken': encryptStorage.getItem('pToken'),
            }
            })
            .then(res => {
                this.setPaymentPage(res.data.responseData.PageContent);
                this.setPaymentSettings(res.data.responseData.PageSettings);
                this.setPaymentReceipt(res.data.responseData.ReceiptContent);
                this.setPaymentPageSubdomain(res.data.responseData.Subdomain);
                
            })
            .catch(error => {
                throw error;
            }
        );
    }
    
    @computed
    get getPaymentPages(){
        return this.paymentPages;
    }
    
    @computed
    get getShowPaymentPageNameError(){
       return this.showPaymentPageNameError;
    }
    
    @computed
    get getShowPaymentPageSubdomainError(){
       return this.showPaymentPageSubdomainError;
    }

    @action
    setShowPaymentPageNameError(status){
       this.showPaymentPageNameError = status;
    }
    
    @action
    setShowPaymentPageSubdomainError(status){
       this.showPaymentPageSubdomainError = status;
    }

    @computed
    get isAutopay(){
        return this.paymentPage.autopay.enabled;
    }

    @computed
    get getSaveAction(){
        return this.saveAction;
    }

    
}

const paymentPageStore = new PaymentPageStore();
export default paymentPageStore;