import React from 'react';
import { inject, observer } from 'mobx-react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { AiFillSetting } from 'react-icons/ai';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import Schedule from './Schedule';

@inject('store', 'global', 'invoice', 'reports')
@observer
class SectionInvoiceDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedDefault: false,
      invoiceNumber: '',
      dueDate: '',
      schedule: false,
      paymentTerms: 'none',
      paymentTermsText: '',
      formErrors: {
        invoiceNumber: null,
      },
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.handleSchedule = this.handleSchedule.bind(this);
    this.loadInvoiceNumber = this.loadInvoiceNumber.bind(this);
    this.handleChangePaymentTerm = this.handleChangePaymentTerm.bind(this);
  }

  handleChangeInput(e) {
    const { value } = e.target;
    this.setState({ invoiceNumber: value });
    this.props.invoice.setInvoiceDetails({
      invoiceNumber: value,
    });
  }

  handleChangeDueDate(date) {
    this.props.invoice.setInvoiceDetails({
      dueDate: new Date(date),
      term: null,
    });
    this.setState({ dueDate: date, paymentTermsText: '', paymentTerms: 'none' });
  }

  handleChangePaymentTerm(item) {
    const dueDateCalculated = this.calculateDueDate(item.value);

    let paymentTermsText = item.text;
    let paymentTermsValue = item.value;

    if (item.value === 'custom') {
      paymentTermsText = '';
      paymentTermsValue = 'none';
    }

    this.setState({ paymentTermsText: paymentTermsText, paymentTerms: paymentTermsValue, dueDate: dueDateCalculated });
    this.props.invoice.setInvoiceDetails({
      dueDate: dueDateCalculated,
      term: paymentTermsValue
    });
  }

  calculateDueDate(term) {
    let invoiceDetails = this.props.invoice.invoiceDetails;
    let day = new Date();
    if(invoiceDetails.scheduleCheck === true && invoiceDetails.startDate){
      day = new Date(invoiceDetails.startDate);
    }
    
    switch (term) {
      case 'DOR':
        return day;
      case 'NET10':
        day.setDate(day.getDate() + 10);
        return day;
      case 'NET15':
        day.setDate(day.getDate() + 15);
        return day;
      case 'NET20':
        day.setDate(day.getDate() + 20);
        return day;
      case 'NET30':
        day.setDate(day.getDate() + 30);
        return day;
      case 'NET45':
        day.setDate(day.getDate() + 45);
        return day;
      case 'NET60':
        day.setDate(day.getDate() + 60);
        return day;
      case 'NET90':
        day.setDate(day.getDate() + 90);
        return day;
      case 'EOM':
        return new Date(day.getFullYear(), day.getMonth() + 1, 0);
      case '1MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 1);
      case '5MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 5);
      case '10MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 10);
      case '15MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 15);
      case '20MFI':
        return new Date(day.getFullYear(), day.getMonth() + 1, 20);
      case 'custom':
        // open datepicker
        document.getElementById('dueDate').click();
        return;
      default:
        day.setDate(day.getDate() + 1);
        return day;
    }
  }

  handleSchedule(e) {
    const { checked, id } = e.target;
    this.setState({ [id]: checked });
    this.props.invoice.setInvoiceDetails({
      scheduleCheck: checked,
    });
  }

  componentDidMount() {
    const { requiredInvoiceNumber, invoicePrefix, dueDate } = this.props.settings;

    let invoiceNumber = '';
    if (requiredInvoiceNumber) {
      this.loadInvoiceNumber(invoicePrefix);
    }

    const dueDateCalculated = this.calculateDueDate(dueDate);
    this.setState({ invoiceNumber, dueDate: dueDateCalculated });
    this.props.invoice.setInvoiceDetails({
      invoiceNumber,
      dueDate: dueDateCalculated,
    });
  }

  componentDidUpdate() {
    const invoiceStatus = this.props.invoice.status;

    if (invoiceStatus === 'new' && !this.state.loadedDefault && Object.keys(this.props.settings).length !== 0) {
      const { dueDate, invoicePrefix, requiredInvoiceNumber } = this.props.settings;

      let invoiceNumber = '';
      if (requiredInvoiceNumber) {
        this.loadInvoiceNumber(invoicePrefix);
      }

      const dueDateCalculated = this.calculateDueDate(dueDate);
      this.setState({ invoiceNumber, loadedDefault: true, dueDate: dueDateCalculated });
      this.props.invoice.setInvoiceDetails({
        invoiceNumber,
        dueDate: dueDateCalculated,
      });
    }

    const invoiceDataLoaded = this.props.invoiceDataLoaded;

    if (invoiceStatus !== 'new' && !this.state.loadedDefault && invoiceDataLoaded) {
      let { invoiceScheduled } = invoiceDataLoaded.Customer.AdditionalData;
      invoiceScheduled = invoiceScheduled === 'true';

      this.setState({
        loadedDefault: true,
        invoiceNumber: invoiceDataLoaded.invoiceNumber,
        dueDate: new Date(invoiceDataLoaded.invoiceDueDate),
        schedule: invoiceScheduled,
      });
      this.props.invoice.setInvoiceDetails({
        invoiceNumber: invoiceDataLoaded.invoiceNumber,
        dueDate: new Date(invoiceDataLoaded.invoiceDueDate),
        scheduleCheck: invoiceScheduled,
      });
    }
  }

  loadInvoiceNumber(invoicePrefix) {
    this.props.invoice
      .getNextInvoice()
      .then((result) => {
        const invoiceNumber = result.responseData;
        this.setState({ invoiceNumber });
        this.props.invoice.setInvoiceDetails({
          invoiceNumber,
        });
      })
      .catch((error) => {
        this.setState({ invoiceNumber: `${invoicePrefix}-` });
      });
  }

  render() {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const { invoiceDetails } = this.props.invoice;

    const paymentTermsToggle = React.forwardRef(({ children, onClick }, ref) => <>{children}</>);

    return (
      <div className="section-form">
        <h6 className="mb-3">Invoice Details</h6>
        <div className="row">
          <div className="col-sm mb-3">
            <div className="form-floating">
              <input
                className={this.state.formErrors.invoiceNumber ? 'form-control input-error' : 'form-control'}
                onChange={this.handleChangeInput}
                placeholder="Invoice number"
                id="invoiceNumber"
                value={this.state.invoiceNumber}
                disabled={this.props.invoice.status !== 'new'}
              />
              <label htmlFor="invoiceNumber">Invoice #</label>
            </div>
          </div>
          
          <div className="col-sm">
            <DatePicker
              customInput={
                <div className="form-floating">
                  <input
                    readOnly
                    autoComplete="off"
                    onChange={function () {}}
                    value={invoiceDetails.dueDate ? this.props.reports.dateStringFormat(invoiceDetails.dueDate) : ''}
                    name="dueDate"
                    id="dueDate"
                    className={
                      this.state.formErrors.dueDate
                        ? 'form-control input-calendar input-error input-lg'
                        : 'form-control input-calendar input-lg'
                    }
                    placeholder="Select Date"
                  />
                  <label htmlFor="dueDate">Due Date</label>
                </div>
              }
              selected={invoiceDetails.dueDate}
              onChange={(date) => this.handleChangeDueDate(date)}
              minDate={ invoiceDetails.scheduleCheck === true ? invoiceDetails.startDate ? invoiceDetails.startDate : today : today}
              dayClassName={(date) => 'calendar-day'}
              popperPlacement="bottom-start"
            />
          </div>
        </div>

        <div className="row-schedule">
          <div className="icheck-primary">
            <input
              checked={this.state.schedule}
              type="checkbox"
              name="schedule"
              id="schedule"
              onChange={this.handleSchedule}
            />
            <label htmlFor="schedule">Schedule</label>
          </div>
          <span id="payment-terms">
            <span className="text hide-sm-sm">
              {invoiceDetails.term === null  ? '+ payment terms' : `Term: ${this.props.invoice.getTermText()}`}
            </span>
            <div className="icon-settings">
              <DropdownButton
                as={paymentTermsToggle}
                menuAlign="right"
                title={<AiFillSetting />}
                size="sm"
                variant="default"
              >
                {this.props.invoice.getOptionsDueDate().map((item) => (
                  <Dropdown.Item
                    key={item.value}
                    as="span"
                    id={item.value}
                    onClick={() => this.handleChangePaymentTerm(item)}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </span>
        </div>

        {this.state.schedule && (
          <div className="row-fields-schedule">
            <Schedule invoiceDataLoaded={this.props.invoiceDataLoaded} />
          </div>
        )}
      </div>
    );
  }
}

export default SectionInvoiceDetails;
