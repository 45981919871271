import { useParams, useHistory } from 'react-router-dom'
import { action } from 'mobx'
import { toast } from 'react-toastify'
import { templateMasterGET, templateGET, userOneTimePUT, templatesV1GET, createTemplatePOST, updateTemplatePUT, organizationsTreeGET, getTiersDetailsGET } from '../Connections/connections'
import { PayabliStorageManager } from '../../../api/localStorageManager'
import GlobalStore from '../../GlobalStore'

export default function Record({ setLoading, rootTemplate, setRootTemplate, setOrgTree }) {
	const params = useParams()
	const history = useHistory()
	const loadRootTemplate = action(async () => {
		try {
			let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
			let orgId = encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry').orgId
			const res = await templateMasterGET(encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry').orgId)
			setRootTemplate({
				...rootTemplate,
				rootTemplate: res.data,
				isRoot: encryptStorage && encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry') && encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry').isRoot ? encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry').isRoot : false
			})

			return {
				rootTemplate: res.data,
				orgId: orgId,
				isRoot: encryptStorage && encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry') && encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry').isRoot ? encryptStorage.getItem(GlobalStore.getURLEntry() + '_pEntry').isRoot : false
			}
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const loadRootNewTemplate = action(async () => {
		try {
			const resonse = await loadRootTemplate()
			if (!resonse) return false

			const respTree = await organizationsTreeGET(resonse.orgId)
			if (!respTree) return false
			setOrgTree({ orgTree: respTree.data })

			let value = {
				rootTemplate: resonse.rootTemplate,
				towner: resonse.orgId,
				isRoot: resonse.isRoot
			}
			const uniqueUserToken = await userOneTimePUT(params.id)
			if (uniqueUserToken.data.responseData) {
				let result = [{ value: '', text: 'Select' }]
				try {
					const options = await templatesV1GET(uniqueUserToken.data.responseData)
					if (options) {
						options?.data?.forEach((option) => {
							result.push({ value: option.id, text: option.name })
						})
					}
				} catch (error) {
					console.log('error', error)
				}
				value['templates'] = result
			}
			const tiersResult = await getTiersDetailsGET()
			const tiersName = tiersResult?.data?.Records?.map((tier) => {
				return { value: tier.TierName, text: tier.TierName }
			})
			tiersName.unshift({ value: '', text: 'Select' })
			value['tiersName'] = tiersName
			return value
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})

			return false
		}
	})
	const loadBaseTemplate = action(async () => {
		try {
			const resonse = await loadRootTemplate()
			if (!resonse) return false

			const res = await templateGET(params.id)
			let value = {
				baseTemplate: res.data.templateContent,
				title: res.data.templateTitle,
				description: res.data.templateDescription,
				code: res.data.templateCode,
				resumable: res.data.resumable,
				recipientEmailNotification: res.data.recipientEmailNotification,
				orgName: res.data.orgParentName
			}
			let all_nulls = Object.entries(res.data.templateContent)
				.map((e) => e[1])
				.filter((x) => x !== null)
			if (!all_nulls.length) {
				toast.error('Error in template integrity!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				return false
			} else {
				const uniqueUserToken = await userOneTimePUT(params.id)
				if (uniqueUserToken.data.responseData) {
					let result = [{ value: '', text: 'Select' }]
					try {
						const options = await templatesV1GET(uniqueUserToken.data.responseData)
						if (options) {
							options?.data?.forEach((option) => {
								result.push({
									value: option.id,
									text: option.name
								})
							})
						}
					} catch (error) {
						console.log('error', error)
					}
					value['templates'] = result
				}
				const tiersResult = await getTiersDetailsGET()
				const tiersName = tiersResult?.data?.Records?.map((tier) => {
					return { value: tier.TierName, text: tier.TierName }
				})
				tiersName.unshift({ value: '', text: 'Select' })
				value['tiersName'] = tiersName
				return value
			}
		} catch (error) {
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const createTemplate = action(async (orgId, template) => {
		try {
			setLoading(true)
			const res = await createTemplatePOST(orgId, template)
			if (res.data.isSuccess === true) {
				setLoading(false)
				history.push('/' + PayabliStorageManager.getEntryName() + '/boarding/templates')
				toast.success('Template saved successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				return true
			}
			return false
		} catch (error) {
			setLoading(false)
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})
	const updateTemplate = action(async (template) => {
		try {
			setLoading(true)
			const res = await updateTemplatePUT(params.id, template)
			if (res.data.isSuccess === true) {
				setLoading(false)
				history.push('/' + PayabliStorageManager.getEntryName() + '/boarding/templates')
				toast.success('Template saved successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				return true
			}
			return false
		} catch (error) {
			setLoading(false)
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return false
		}
	})

	return {
		loadRootNewTemplate,
		loadBaseTemplate,
		createTemplate,
		updateTemplate
	}
}
