import React from 'react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import {PayabliStorageManager, PayabliCookieManager } from '../../api/localStorageManager'
import { BiLowVision } from '@react-icons/all-files/bi/BiLowVision';
import { BiShowAlt } from '@react-icons/all-files/bi/BiShowAlt';
import ReactCodeInput from 'react-code-input';
import { SessionManager } from '../../api/sessionManager';
import Lottie from 'react-lottie';
import LoadingApplication from '../../assets/lotties/LoadingSquaredApplication.json';
import md5 from 'crypto-js/md5';
import { Brand } from '../../components/Brand';
import * as Sentry from "@sentry/react";

const propsReactCodeInput = {
    className: ReactCodeInput,
    inputStyle: {
      fontFamily: 'Poppins',
      margin:  '4px',
      MozAppearance: 'textfield',
      width: '45px',
      borderRadius: '5px',
      fontSize: '24px',
      height: '54px',
      textAlign: 'center',
      backgroundColor: '#ffffff',
      color: '#212529',
      border: '1px solid #ced4da',
      boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px'
    },
    
  }

@inject('global', 'user')
@observer
class Root extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: "",
            logo: "",
            showPassword: false,
            entry: null
        };
        this.getCredentialsFromApi = this.getCredentialsFromApi.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextMFACode = this.handleTextMFACode.bind(this);
        this.setMfa = this.setMfa.bind(this);
        this.sendMFACode = this.sendMFACode.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.receiveSocketMessage = this.receiveSocketMessage.bind(this);
    }

    receiveSocketMessage(message){
        if(message.body && message.body.idSessions && message.body.idSessions){
            this.goToDashboard();
        }
    }

    setMfa(value, e){
        if(e){
            e.preventDefault();
        }
        this.setState({mfa: value});
    }

    sendMFACode(){
        const val = (this.state.mfaMode === "authenticator" ? 6 : 5);
        if (this.state.mfaCode.length === val) {
            this.props.global.setLoading(true);
            return axios.post(process.env.REACT_APP_URL_API+ 'User/mfa', {
                "mfaCode": this.state.mfaCode,
                "mfaValidationCode": this.state.mfaValidationCode
            },
            {
                headers: {'requestToken': process.env.REACT_APP_TOKEN}
            })
            .then(res => {
                if(res.data.responseData){
                    this.getUserAccess(res);
                }else{
                    this.props.global.setLoading(false);
                }
                this.props.global.setLoading(false);
            })
            .catch(error => {
                this.setState({ mfaError : "Your multi-factor authentication code is not correct"});
                this.props.global.setLoading(false);
            });
        }
    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.getCredentialsFromApi();
        }
    }

    goToDashboard(){
        window.location = "/dashboard";
    }

    showPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    async getCredentialsFromApi() {
        this.setState({ error: "" });
        this.props.global.setLoading(true);
        return axios.post(process.env.REACT_APP_URL_API + 'User/auth/ ', {
            "email": this.state.email,
            "psw": this.state.password
        },
            {
                headers: { 'requestToken': process.env.REACT_APP_TOKEN }
            })
            .then(res => {
                if(res.data.mfa === true){
                    this.props.global.setLoading(false);
                    this.setState({mfa: true, mfaValidationCode: res.data.mfaValidationCode, mfaMode: res.data.mfaMode, mfaError: '', mfaCode: ''});
                }
                else if(res.data.responseData){
                    this.getUserAccess(res);
                }else{
                    this.setState({ error : "Something is Wrong"});
                    this.props.global.setLoading(false);
                }

            })
            .catch(error => {
                let errorMessage = "Invalid username or password or something is wrong!";
                this.setState({ error: errorMessage });
                this.props.global.setLoading(false);
            });
    }

    getUserAccess(res) {
        let userToken = res.data.responseData;
        // To refresh token 10 minutes before token expire
        PayabliStorageManager.setEncryptedLocalStorageKey(userToken);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let remaining = (new Date().getTime()) + ((parseInt(res.data.remaining) - 10)*60000);
        this.props.global.setLoading(true);

        axios.get(process.env.REACT_APP_URL_API + 'User/0',
            {
                headers: { 'requestToken': userToken }
            })
            .then(async (res) => {
                if (res.data.userId) {
                    let organizationItem = [];
                    res.data.Scope.every(function (item) {
                        if (item.orgType === 0) {
                        organizationItem = [item];
                        return;
                        }
                        organizationItem.push(item);
                    });

                    // if (organizationItem[0].orgType === 2) {
                    //     window.location.replace(`${process.env.REACT_APP_URL_PAYPOINT}${organizationItem[0].orgEntry}/login`);
                    //     return;
                    // }
                    if (organizationItem[0] && organizationItem[0].orgType === 2) {
                        encryptStorage.setItem(`${organizationItem[0].orgEntry}_pToken`, userToken);
                        this.redirectSso(organizationItem[0]);
                        return;
                    }

                    axios.get(process.env.REACT_APP_URL_API + 'organization/basic/' + organizationItem[0].orgEntry,
                        {
                            headers: { 'requestToken': process.env.REACT_APP_TOKEN }
                        })
                        .then(entryRes => {

                            try{
                            PayabliStorageManager.setEncryptedLocalStorageKey(userToken);
                            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
                            encryptStorage.setItem('pToken', userToken);
                            let entryImg = entryRes.data.responseData ? entryRes.data.responseData.OrgLogo : '';
                            encryptStorage.setItem(`${organizationItem[0].orgEntry}_pImg`, entryImg);
                            if (entryRes.data) {
                                //LegalName
                                encryptStorage.setItem( organizationItem[0].orgEntry + "_pEntry", { 
                                    pEntry: organizationItem[0].orgEntry, 
                                    legalName: entryRes.data.responseData.OrgName, 
                                    orgId: entryRes.data.responseData.idOrg, 
                                    isRoot: entryRes.data.responseData.isRoot, 
                                    OrgTimezone: entryRes.data.responseData.orgTimezone 
                                });
                               
                                
                                this.login(res, organizationItem, remaining, encryptStorage, userToken);
                            }
                            }catch(e){
                                console.log(e)
                            }

                            
                        })
                        .catch(error => {
                            this.setState({ error: "Something is Wrong. " + error.message });
                            this.props.global.setLoading(false);
                            setTimeout(function(){
                                window.location.href = '/';
                            }, 2000)
                        });
                } else {
                    this.setState({ error: "Something is Wrong" });
                }
            })
            .catch(error => {
                let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                this.setState({ error: errorMessage });
                this.props.global.setLoading(false);
            });
    }

    redirectSso(organizationItem) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entryName = organizationItem.orgEntry;
        PayabliCookieManager.createCookie(`${entryName}_pToken`,encryptStorage.getItem(`${entryName}_pToken`),1);
        window.location.replace(`${process.env.REACT_APP_URL_PAYPOINT}${organizationItem.orgEntry}/sso`);
    }
    
    reSendMFACode(e){
        let timeout = parseInt(process.env.REACT_APP_MFA_TIMEOUT);
        let thisObj = this;
        if(e){
            e.preventDefault();
        }
        this.setState({
            resendCodeShowDelay: true,
            mfaError : "A new verification code has been sent to you.",
            mfaCode: ""
        })

        let timer = setInterval(function(){
            thisObj.setState({
                resendCodeTimeout: timeout
            })
            timeout -= 1;

            if(timeout < 0){
                clearInterval(timer);
                thisObj.setState({
                    resendCodeShowDelay: false,
                    resendCodeTimeout: parseInt(process.env.REACT_APP_MFA_TIMEOUT)
                })
            }

        }, 1000);

        this.props.global.setLoading(true);
        return axios.post(process.env.REACT_APP_URL_API+ 'User/resendmfa/'+ this.state.email, {},
        {
            headers: {'requestToken': process.env.REACT_APP_TOKEN}
        })
        .then(res => {
            this.setState({mfaCode: ""}, () => {
                this.props.global.setLoading(false);
            })

            if(res.data.mfaValidationCode)
            {
                this.setState({ mfaValidationCode: res.data.mfaValidationCode})
            }
        })
        .catch(error => {
            this.setState({ mfaError : "We have a problem sending your multi-factor authentication code."});
            this.props.global.setLoading(false);
        });
    }

    login(res, organizationItem, remaining, encryptStorage, userToken) {

        

        this.props.user.getUserFromApi(res.data.userId, organizationItem[0].orgEntry).then(async (pres) => {
            if (pres) {
                if (pres && pres.Access) {
                    let permissionsArray = [];
                    pres.Access.forEach(function (item) {
                        if (item.roleValue === true) {
                            permissionsArray.push(item.roleLabel);
                        }
                    });

                    encryptStorage.setItem('pUser', {
                        name: res.data.Name,
                        id: res.data.userId,
                        email: this.state.email,
                        remaining: remaining,
                        permissions: permissionsArray,
                        timeZone: res.data.TimeZone ? res.data.TimeZone : 0
                    });

                    if(userToken){
                        try{
                            PayabliCookieManager.eraseCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`);
                            PayabliCookieManager.createCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`, userToken, 1);
                        }catch(e){
                            Sentry.captureException(e);
                            console.log(e);
                        }
                    }
                    let _dataStorage = localStorage.getItem("_dataStorage");
                    let _storageKey = localStorage.getItem("_storageKey");

                    localStorage.setItem(`${organizationItem[0].orgEntry}_dataStorage`,_dataStorage);
                    localStorage.setItem(`${organizationItem[0].orgEntry}_storageKey`,_storageKey);

                    localStorage.removeItem("_storageKey");
                    localStorage.removeItem("_dataStorage");
    
                    window.location = `/${organizationItem[0].orgEntry}/dashboard`;
                    
                }
            }

        })
        .catch(error => {
            this.props.global.setLoading(false);
            throw error;
        });
    }

    handleTextChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleTextMFACode(e) {
        document.addEventListener('keydown', (params) => {
            if (params.key === 'Enter') {
                this.sendMFACode();
            }
        });
        this.setState({mfaCode : e}, () => {
            let qty = this.state.mfaMode === "authenticator" ? 6 : 5;
            if(this.state.mfaCode.length === qty){
                this.sendMFACode();
            }
        });
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: LoadingApplication,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          };

        return (
            <>
                {this.props.global.isLoading &&
                <div id="main-loading-layer" style={{backgroundColor: "rgba(255,255,255,1)"}} className="d-flex justify-content-center align-items-center">
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                    Loading...
                </div>
                }
                <div className="root-page text-center">
                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100%", position: "absolute", width: "100%" }}>
                        {!this.state.mfa ?
                        <div className="card-login mb-3" style={{ width: '35em' }}>
                            <Brand classBrand={'loginBrand'} />
                            <p className="grey mb-4">Log Into Your PartnerHub</p>
                            <div className="form-floating mb-3">
                                <input autoCapitalize="off" onKeyDown={(e) => this.handleKeyDown(e)} type="text" value={this.state.email} name="email" className={this.state.error ? "form-control input-error" : "form-control"} placeholder="Email" onChange={(e) => this.handleTextChange(e)} />
                                <label htmlFor="email">Email</label>
                            </div>

                            <div className="form-floating mb-2">
                                <input onKeyDown={(e) => this.handleKeyDown(e)} type={this.state.showPassword ? "text" : "password"} value={this.state.password} name="password" className={this.state.error ? "form-control input-error" : "form-control"} placeholder="Password" onChange={(e) => this.handleTextChange(e)} />
                                <label htmlFor="password">Password</label>
                                {
                                    this.state.showPassword ?
                                        <BiLowVision className="right-icon-on-input" onClick={(e) => this.showPassword()} />
                                        :
                                        <BiShowAlt className="right-icon-on-input" onClick={(e) => this.showPassword()} />
                                }

                            </div>

                            {
                                this.state.error &&
                                (
                                    <div className="text-danger mb-3 small">{this.state.error}</div>
                                )
                            }

                            <div className="row  mb-4">


                                <div className="col-12 ">


                                </div>

                            </div>

                            <button className="full-w btn btn-lg btn-success mb-4" onClick={(e) => this.getCredentialsFromApi()}>Login</button>
                        </div>
                        :
                        <div className="card-login mb-3" style={{width: '35em'}}>                            
                                <Brand classBrand={'loginBrand'} />
                                <p className="grey mb-4">
                                    {this.state.mfaMode === "authenticator" &&
                                    <>Enter Google Authentication code for your multi-factor authentication.</>
                                    }
                                    {this.state.mfaMode === "email" &&
                                    <>You will receive code by email notification. Enter code for your multi-factor authentication.</>
                                    }
                                    {this.state.mfaMode === "sms" &&
                                    <>You will receive code by text notification. Enter code for your multi-factor authentication.</>
                                    }
                                </p>
                                <div className="mb-3">
                                    <ReactCodeInput onChange={(e)=> this.handleTextMFACode(e)} name="mfaCode" inputMode="tel" type="number" fields={this.state.mfaMode === "authenticator" ? 6 : 5} {...propsReactCodeInput}/>
                                </div>   
        
                                {
                                    this.state.mfaError &&
                                    (
                                        <div className="text-danger mb-4 small">{this.state.mfaError}</div>
                                    )
                                }
                               
                                <button className="full-w btn btn-lg btn-success mb-4" onClick={(e) => this.sendMFACode()}>Verify Code</button>
                                {
                                    this.state.mfaMode !== "authenticator" &&
                                    <span className={ this.state.resendCodeShowDelay ? "grey small cursor-pointer" : "small cursor-pointer blue-text" } href="/" onClick={(e)=> this.state.resendCodeShowDelay === false ? this.reSendMFACode(e) : ''}>Resend verification code {this.state.resendCodeShowDelay && <>({this.state.resendCodeTimeout})</> }</span>
                                }<br/><br/>
                                <a className="small mt-3 no-underline" href="/" onClick={(e)=> this.setMfa(false, e)}>Go back to login</a>
                        </div>
                        }
                        <p className="small-small">Powered by  <a href="/" className="small no-underline">Payabli</a></p>
                    </div>
                </div>
        </>
        )
    }
}

export { Root };