import React from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Tabs, Row, Button, Table, InputGroup, Modal, Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from 'axios';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { BoardingLinks } from '../../components/BoardingLinks';
import { Layout } from '../../components/Layout'
import { PayabliStorageManager } from '../../api/localStorageManager'
import { InputSimpleLink } from '../../components/InputSimpleLink';
import { ReadOnlySimpleLink } from '../../components/ReadOnlySimpleLink';
import { InputSelectTemplate } from '../../components/InputSelectTemplate';
import { InputSelectLink } from '../../components/InputSelectLink';
import { InputCalendarLink } from '../../components/InputCalendarLink';
import { CheckLink } from '../../components/CheckLink';
import { ReadOnlyCheckLink } from '../../components/ReadOnlyCheckLink';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { InputSelectSearchable } from '../../components/InputSelectSearchable';

import 'react-toastify/dist/ReactToastify.css';

import {BiTrash} from "@react-icons/all-files/bi/BiTrash";
import { BiChevronRight } from '@react-icons/all-files/bi/BiChevronRight';
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft';
import { BiPlus } from '@react-icons/all-files/bi/BiPlus';
import { BiDetail } from '@react-icons/all-files/bi/BiDetail';
import { BiCog } from '@react-icons/all-files/bi/BiCog';
import { BiUserCheck } from '@react-icons/all-files/bi/BiUserCheck';
import { BiDollarCircle } from '@react-icons/all-files/bi/BiDollarCircle';
import {BiX} from "@react-icons/all-files/bi/BiX";
import { RiBankLine } from '@react-icons/all-files/ri/RiBankLine';
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle';
import { LocationSearchInput } from '../../components/LocationSearchInput';
import visa from '../../assets/images/visa.svg';
import mastercard from '../../assets/images/mastercard.svg';
import amex from '../../assets/images/amex.png';
import discover from '../../assets/images/discover.svg';
import achIcon from '../../assets/images/ach.svg';
import PhoneInput from '../../components/PhoneInput';

@inject('boarding', 'store', 'global')
@observer
class NewAppFromTpl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            baseData: {
                averageMonthlyVolume: 0,
                averageTicketAmount: 0,
                bAddress1: "",
                bAddress2: "",
                bCity: "",
                bCountry: "US",
                bFax: "",
                bPhone: "",
                bStartdate: null,
                bState: "",
                bSummary: "",
                bZip: "",
                bankData: null,
                binPerson: 0,
                binPhone: 0,
                binWeb: 0,
                contactData: null,
                dbaName: "",
                ein: "",
                highTicketAmount: 0,
                legalName: "",
                license: "",
                licenseState: "",
                mAddress1: null,
                mAddress2: null,
                mCity: null,
                mCountry: null,
                mState: null,
                mZip: null,
                mccid: "",
                ownType: "",
                ownerData: null,
                serviceData: null,
                taxfillname: "",
                websiteAddress: "",
                whencharged: "",
                whendelivered: "",
                whenprovided: "",
                whenrefund: ""
            },
            baseTemplate: null,
            template_data: null,
            appId: 0,
            filesSize: 0,
            fileExceededSize: false,
            lastFileSize: 0,
            orgId:0,
            linkId:0,
            currentSection: 'business',
            complete: false,
            bsameaddress: false,
            endLinkModalIsOpen: false,
            additionalOwners: [],
            lastowner: 0,
            additionalContacts: [],
            lastcontact: 0,
            additionalFiles: [],
            lastfile: 0,
            currentPos: 0,
            activePos: [],
            isLoading: true,
            cancelTplModalIsOpen: false,
            saveTplModalIsOpen: false,
            isRoot: false,
            termsAccepted: [],
            checkAccepted: [],
            signer: {
                name: '',
                ssn: '',
                dob: '',
                phone: '',
                email: '',
                address: '',
                address1: '',
                state: '',
                country: '',
                city: '',
                zip: '',
                acceptance: false,
            },
            signers: [],
            validTabs: []
        };
        this.setTab = this.setTab.bind(this);
        this.bSameaddresshandleChange = this.bSameaddresshandleChange.bind(this);
        this.addOwner = this.addOwner.bind(this);
        this.removeOwner = this.removeOwner.bind(this);
        this.addOwnerContacts = this.addOwnerContacts.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.addDoc = this.addDoc.bind(this);
        this.removeDoc = this.removeDoc.bind(this);
        this.saveToComplete = this.saveToComplete.bind(this);
        this.submitComplete = this.submitComplete.bind(this);
        this.closeEndLinkModal = this.closeEndLinkModal.bind(this);
        this.getSignerBlock = this.getSignerBlock.bind(this);
        this.selectContactAsSigner = this.selectContactAsSigner.bind(this);
        this.handleAcceptClick = this.handleAcceptClick.bind(this); 
        this.handleAcceptCheckClick = this.handleAcceptCheckClick.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.handleGoogleAutocompleteBusinessAddress = this.handleGoogleAutocompleteBusinessAddress.bind(this);
        this.handleGoogleAutocompleteBusinessMAddress = this.handleGoogleAutocompleteBusinessMAddress.bind(this);
        this.handleGoogleAutocompleteOwnerAddress = this.handleGoogleAutocompleteOwnerAddress.bind(this);
        this.handleGoogleAutocompleteSignerAddress = this.handleGoogleAutocompleteSignerAddress.bind(this);
        this.onCopySigner2Owner = this.onCopySigner2Owner.bind(this)
        this.OwnerTypeChange = this.OwnerTypeChange.bind(this)
    }
    OwnerTypeChange(ownType){
		if(ownType === 'Non-Profit Org'){
			this.onCopySigner2Owner()
		}
	}
    handleGoogleAutocompleteSignerAddress(signer_address, address2, signer_city, signer_state, signer_zip, signer_country){
        this.props.boarding.setTemplateData('signer_address', signer_address)
        this.props.boarding.setTemplateData('signer_address2', address2)
        this.props.boarding.setTemplateData('signer_city', signer_city)
        this.props.boarding.setTemplateData('signer_state', signer_state)
        this.props.boarding.setTemplateData('signer_zip', signer_zip)
        this.props.boarding.setTemplateData('signer_country', signer_country)
        let signer = this.state.signer
        signer.country =  signer_country ?  signer_country : ''
        signer.city = signer_city ?  signer_city : ''
        signer.state = signer_state ?  signer_state : ''
        signer.address = signer_address ?  signer_address : ''
        signer.address1 = address2 ?  address2 : ''
        signer.zip = signer_zip ?  signer_zip : ''
        this.setState({
                signer:signer
        });         
    }

    handleGoogleAutocompleteOwnerAddress(oaddress, address2, ocity, ostate, ozip, ocountry, k){
        this.props.boarding.setTemplateData("oaddress_" + k, oaddress)
        this.props.boarding.setTemplateData("ocity_" + k, ocity)
        this.props.boarding.setTemplateData("ostate_" + k, ostate)
        this.props.boarding.setTemplateData("ozip_" + k, ozip)
        this.props.boarding.setTemplateData("ocountry_" + k, ocountry)
        let values = {}
        values["ocountry_" + k] = ocountry ?  ocountry : ''
        values["ocity_" + k] = ocity ?  ocity : ''
        values["ostate_" + k] = ostate ?  ostate : ''
        values["ozip_" + k] = ozip ?  ozip : ''
        values["oaddress_" + k] = oaddress ?  oaddress : ''
        this.setState(values)
         
    }

    handleGoogleAutocompleteBusinessAddress(baddress, baddress1, bcity, bstate, bzip, bcountry){
        this.props.boarding.setTemplateData('baddress', baddress)
        this.props.boarding.setTemplateData('baddress1', baddress1)
        this.props.boarding.setTemplateData('bcity', bcity)
        this.props.boarding.setTemplateData('bstate', bstate)
        this.props.boarding.setTemplateData('bzip', bzip)
        this.props.boarding.setTemplateData('bcountry', bcountry)
        this.setState({
            baddress : baddress ?  baddress : '',
            baddress1 : baddress1 ?  baddress1 : '',
            bcity : bcity ?  bcity : '',
            bstate : bstate ?  bstate : '',
            bzip : bzip ?  bzip : '',
            bcountry : bcountry ?  bcountry : ''
         });
         
    }

    handleGoogleAutocompleteBusinessMAddress(maddress, maddress1, mcity, mstate, mzip, mcountry){
        this.props.boarding.setTemplateData('maddress', maddress)
        this.props.boarding.setTemplateData('maddress1', maddress1)
        this.props.boarding.setTemplateData('mcity', mcity)
        this.props.boarding.setTemplateData('mstate', mstate)
        this.props.boarding.setTemplateData('mzip', mzip)
        this.props.boarding.setTemplateData('mcountry', mcountry)
        this.setState({
            maddress : maddress ?  maddress : '',
            maddress1 : maddress1 ?  maddress1 : '',
            mcity : mcity ?  mcity : '',
            mstate : mstate ?  mstate : '',
            mzip : mzip ?  mzip : '',
            mcountry : mcountry ?  mcountry : ''
         });
    }

    handleTextChangeMask(e, value){
        this.props.boarding.setTemplateData(e, value);
        this.setState({ [e]: value });
    }

    handleAcceptClick = (e) => {
        e.preventDefault();
        let index = e.target.getAttribute('term')
        if(this.state.termsAccepted?.length > 0) {
            let accept = this.state.termsAccepted
            accept[index] = true
            this.setState({termsAccepted: accept})
        }
        else{
            let accept = Array(this.state.baseTemplate.documentsData.termsConditions?.length).fill(false)
            accept[index] = true
            this.setState({termsAccepted: accept})
        }
        let href = e.target.getAttribute('href')
        if(href === null) {
            window.open('/404', '_blank')
        } else{
            window.open(href, '_blank')
        }
    }

    handleAcceptCheckClick = (e) => {
        let accept = this.state.termsAccepted
        let allchecked = arr => arr.every(v => v === true);
        let signer = this.state.signer
        let signer_acceptance = false
        if(e.target.checked){
            if (!allchecked(accept)){
                e.preventDefault()
                toast.error("You must click and review link(s) above before agreeing.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            }
            else{
                signer.acceptance = true
                signer_acceptance = true
                this.setState({signer: signer})
            }
        }
        else{
            signer.acceptance = false
            this.setState({signer: signer})
        }
        this.props.boarding.setTemplateData("signer_acceptance", signer_acceptance);
    }

    componentDidMount() {
        this.props.global.setLoading(true);
        this.props.global.protect(this.props.history);
        this.setState({ linkId: this.props.match.params.id, orgId: this.props.match.params?.idOrg }, function () {
            this.handlerGetBaseLink();
        });
        this.props.boarding.clearTemplateData();
        this.props.boarding.clearErrorData();
    }

    getTemplateData = async (templateContent, tdata) => {
            let all_nulls = Object.entries(templateContent).map(e => (e[1])).filter(x => x !== null)
            if(!all_nulls.length){
                this.props.global.setLoading(false);
                toast.error("Error in template integrity!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            }else{
            let template_data = templateContent
            let terms  = template_data.documentsData?.termsAndConditions
            let termsAccepted = []
            let baseTemplate = tdata.data
            if(terms && terms?.visible){
                termsAccepted = Array(template_data.documentsData.termsAndConditions.tcLinks.length).fill(false)
                // baseTemplate.banking.termsConditions = terms.tcLinks
            }
            
            this.setState({ baseTemplate: baseTemplate, template_data:template_data, termsAccepted: termsAccepted }, () => {
                if (this.state.baseTemplate) {
                    if (this.state.baseTemplate.documentsData && this.state.baseTemplate.documentsData.minimumDocuments) {
                        let x = parseInt(this.state.baseTemplate.documentsData.minimumDocuments, 10);
                        this.setState({ lastfile: x });
                    }
                    //bsameaddress checkbox
                    if (this.state.baseTemplate.businessData !== null && (this.state.baseTemplate.businessData.baddress !== null || this.state.baseTemplate.businessData.baddress1 !== null || this.state.baseTemplate.businessData.bcity !== null || this.state.baseTemplate.businessData.bstate !== null || this.state.baseTemplate.businessData.bzip !== null || this.state.baseTemplate.businessData.bcountry !== null) && (this.state.baseTemplate.businessData.maddress !== null || this.state.baseTemplate.businessData.maddress1 !== null || this.state.baseTemplate.businessData.mcity !== null || this.state.baseTemplate.businessData.mstate !== null || this.state.baseTemplate.businessData.mzip !== null || this.state.baseTemplate.businessData.mcountry !== null)) {
                        this.setState({ bsameaddress: true });
                    }
                    //init positions
                    var atabs = [];
                    var cs = '';
                    if (this.state.baseTemplate.businessData !== null) {
                        atabs.push(0);
                        cs = 'business';
                    }
                    if (this.state.baseTemplate.ownershipData !== null) {
                        atabs.push(1);
                        if (cs === '') cs = 'owners';
                    }
                    if (this.state.baseTemplate.processingDataData !== null) {
                        atabs.push(2);
                        if (cs === '') cs = 'processing';
                    }
                    if (this.state.baseTemplate.servicesData !== null) {
                        atabs.push(3);
                        if (cs === '') cs = 'services';
                    }
                    if (this.state.baseTemplate.documentsData !== null) {
                        atabs.push(4);
                        if (cs === '') cs = 'banking';
                    }
                    atabs.push(5);
                    this.setState({ currentSection: cs, activePos: atabs }, function(){
                        this.navigateAllTabs();
                    });

                }
            });
        }
    }

    handlerGetBaseLink = async () => {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        const orgId = this.state.orgId || encryptStorage.getItem(this.props.global.getURLEntry()+"_pEntry").orgId;
        axios.get(process.env.REACT_APP_URL_API + 'Templates/master/' + orgId, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }
        }).then(tdata => {
            if (!tdata.data || tdata.data === null) {
                this.props.global.setLoading(false);
                let errorMessage = "Missing Template Data!";
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
                //this.props.history.goBack();
                return;
            }
            if(parseInt(this.state.linkId) !== 0){
                axios.get(process.env.REACT_APP_URL_API + 'Templates/get/' + this.state.linkId, {
                    headers: {
                        'requestToken': encryptStorage.getItem('pToken')
                    }
                }).then( template => {
                    this.getTemplateData(template.data?.templateContent, tdata);
                }).catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                   // this.props.history.goBack();
                });
            }
            else{
                this.getTemplateData(tdata.data, tdata);
            }
            
        }).catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
           // this.props.history.goBack();
        });
    }

    navigateAllTabs(){
        let objThis = this;
        setTimeout(function(){

            objThis.gotoNextTab(0);
            objThis.gotoNextTab(1);
            objThis.gotoNextTab(2);
            // objThis.gotoNextTab(3);
            let validTabs = objThis.state.validTabs
            validTabs[3] = false
            objThis.setState({validTabs: validTabs});
            objThis.gotoNextTab(4);
            objThis.gotoNextTab(0);

            objThis.props.global.setLoading(false);

        }, 1000);
        
    }

    onFileChange(event) {
        const div = document.getElementById(event.target.name);
        const currentFileSize = this.state.filesSize + event.target.files[0].size - this.state.lastFileSize
        if(currentFileSize > 31457280){
            event.target.value = null
            this.setState({fileExceededSize: true})
        }
        else{
            if (event.target.files.length > 0) {
                div.classList.remove('input-error');
                this.props.boarding.setErrorData(event.target.name, false);
                this.props.boarding.setTemplateData(event.target.name, event.target.files[0]);
            }
            else {
                div.classList.add('input-error');
                this.props.boarding.setErrorData(event.target.name, true);
                this.props.boarding.setTemplateData(event.target.name, null);
            }
        }
    }

    bSameaddresshandleChange(checked) {
        this.setState({ bsameaddress: checked.target.checked }, function () {
            let template = this.state.template_data;
            let baseTemplate = this.state.baseTemplate;
            if (this.state.bsameaddress) {
                if (this.props.boarding.template_data.baddress) {
                    template.mAddress1 = this.props.boarding.template_data.baddress?.value;
                    this.props.boarding.setTemplateData("maddress", this.props.boarding.template_data.baddress?.value);
                    baseTemplate.businessData.maddress.readOnly =true;
                    this.props.boarding.setErrorData("maddress",false);
                }
                if (this.props.boarding.template_data.baddress1) {
                    template.mAddress2 = this.props.boarding.template_data.baddress1?.value;
                    this.props.boarding.setTemplateData("maddress1", this.props.boarding.template_data.baddress1?.value);
                    baseTemplate.businessData.maddress1.readOnly =true;
                    this.props.boarding.setErrorData("maddress1",false);
                }
                if (this.props.boarding.template_data.bcity) {
                    template.mCity = this.props.boarding.template_data.bcity?.value;
                    this.props.boarding.setTemplateData("mcity", this.props.boarding.template_data.bcity?.value);
                    baseTemplate.businessData.mcity.readOnly =true;
                    this.props.boarding.setErrorData("mcity",false);
                }
                if (this.props.boarding.template_data.bstate) {
                    template.mState = this.props.boarding.template_data.bstate?.value;
                    this.props.boarding.setTemplateData("mstate", this.props.boarding.template_data.bstate?.value);
                    baseTemplate.businessData.mstate.readOnly =true;
                    this.props.boarding.setErrorData("mstate",false);
                }
                if (this.props.boarding.template_data.bzip) {
                    template.mZip = this.props.boarding.template_data.bzip?.value;
                    this.props.boarding.setTemplateData("mzip", this.props.boarding.template_data.bzip?.value);
                    baseTemplate.businessData.mzip.readOnly =true;
                    this.props.boarding.setErrorData("mzip",false);
                }
                if (this.props.boarding.template_data.bcountry) {
                    template.mCountry = this.props.boarding.template_data.bcountry?.value;
                    this.props.boarding.setTemplateData("mcountry", this.props.boarding.template_data.bcountry?.value);
                    baseTemplate.businessData.mcountry.readOnly =true;
                    this.props.boarding.setErrorData("mcountry",false);
                }
            }
            else {
                if (this.props.boarding.template_data.maddress) {
                    baseTemplate.businessData.maddress.readOnly =false;
                }
                if (this.props.boarding.template_data.maddress1) {
                    baseTemplate.businessData.maddress1.readOnly =false;
                }
                if (this.props.boarding.template_data.mcity) {
                    baseTemplate.businessData.mcity.readOnly =false;
                }
                if (this.props.boarding.template_data.mstate) {
                    baseTemplate.businessData.mstate.readOnly =false;
                }
                if (this.props.boarding.template_data.mzip) {
                    baseTemplate.businessData.mzip.readOnly =false;
                }
                if (this.props.boarding.template_data.mcountry) {
                    baseTemplate.businessData.mcountry.readOnly =false;
                }
            }
            this.setState({template_data: template, baseTemplate: baseTemplate });
        });
    }

    getProgressBarColor(){
        let color = "#dc3545";
        let progressBarPercent = this.getProgressBarPercent();

        if(progressBarPercent <= 35){
            color = "#dc3545";
        }else if(progressBarPercent > 35 &&  progressBarPercent < 70){
            color = "#ffc107";
        }else if(progressBarPercent >= 70){
            color = "#88c952";
        }
        return color;
    }

    getProgressBarPercent(){
        let validTabs = this.state.validTabs;
        let percent = 0;
        validTabs.forEach(element => {
            if(element === true){
                percent = percent + 20;
            }
        });
        return percent;
    }

    gotoNextTabAndSubmit(_n){
        let validTabs = this.state.validTabs;
        for (let x = 0; x < 5; x++){
            if (this.props.boarding.isValidTab(x, this.state.additionalOwners, this.state.additionalContacts)) {
                validTabs[x]=true;
            }
            else{
                validTabs[x]=false;
            }
            this.props.boarding.setNextClicked(true);
        }
       
        this.gotoNextTab(_n)
        this.submitComplete();
        this.setState({validTabs:validTabs});
    }


    gotoNextTab(_n) {
        let validTabs = this.state.validTabs;
        if (this.props.boarding.isValidTab(this.state.currentPos, this.state.additionalOwners, this.state.additionalContacts) ) {
            validTabs[this.state.currentPos]=true;
        }else{
            validTabs[this.state.currentPos]=false;
        }
        this.setState({validTabs: validTabs});

        if (_n > 5) return;
        
        let actv = this.state.activePos;
        if (actv.indexOf(_n) < 0) {
            _n = _n + 1;
            this.gotoNextTab(_n);
            return;
        }
        
        if (_n === 5) _n = 4;

        this.setState({ currentPos: _n});
        this.setCurrentSection(_n);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    }

    gotoPrevTab(_n) {
        let validTabs = this.state.validTabs;
        if (this.props.boarding.isValidTab(this.state.currentPos, this.state.additionalOwners, this.state.additionalContacts) ) {
            validTabs[this.state.currentPos]=true;
        }else{
            validTabs[this.state.currentPos]=false;
        }
        this.setState({validTabs: validTabs});

        if (_n < 0) return;

        let actv = this.state.activePos;
        if (actv.indexOf(_n) < 0) {
            _n = _n - 1;
            this.gotoPrevTab(_n);
            return;
        }
            
        if (_n === 5) _n = 4;
       
        this.setState({ currentPos: _n});
        this.setCurrentSection(_n);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    setCurrentSection(_n){
        switch (_n) {
            case 0:
                this.setState({ currentSection: 'business' });
                break;
            case 1:
                this.setState({ currentSection: 'owners' });
                break;
            case 2:
                this.setState({ currentSection: 'processing' });
                break;
            case 3:
                this.setState({ currentSection: 'services' });
                break;
            case 4:
            default:
                this.setState({ currentSection: 'banking' });
                break;
        }
    }

    setTab(event) {
        let cp = 0;
        if (event === 'business') {
            cp = 0;
        }
        if(event === 'owners') {
        cp = 1;
        }
        if (event === 'processing') {
            cp = 2;
        }
        if (event === 'services') {
            cp = 3;
        }
        if (event === 'banking') {
            cp = 4;
        }
        
        let validTabs = this.state.validTabs;
        if (this.props.boarding.isValidTab(this.state.currentPos, this.state.additionalOwners, this.state.additionalContacts) ) {
            validTabs[this.state.currentPos]=true;
        }else{
            validTabs[this.state.currentPos]=false;
        }
        this.setState({validTabs: validTabs});

        this.setState({ currentSection: event, currentPos:cp });
    }

    saveToComplete() {
        let percentage = 0
        for (var j = 0; j <= this.state.additionalOwners.length; j++) {
            if(this.props.boarding.template_data['ownerpercent_' + j])
                percentage += parseInt(this.props.boarding.template_data['ownerpercent_' + j]?.value)
        }
        let tmp_data = this.props.boarding.template_data
        let percentage_proc = 0;
        if(tmp_data['binperson'])
            percentage_proc += parseInt(tmp_data['binperson']?.value) 
        if(tmp_data['binphone'])
            percentage_proc += parseInt(tmp_data['binphone']?.value)
        if(tmp_data['binweb'])
            percentage_proc += parseInt(tmp_data['binweb']?.value)                
        if (percentage_proc > 100) {
            toast.error("Combined total payments percent must be equal to or less than 100%!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else if (percentage > 100) {
            toast.error("Total ownership must be equal to or less than 100%!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else{
            this.props.global.setLoading(true);
            this.props.boarding.saveApplication(this.state.orgId, this.state.linkId, this.state.additionalOwners, this.state.additionalContacts, this.state.update, this.state.appId).then(res => {
                var addc = this.state.additionalContacts;
                var naddc = [];
                if (addc.length > 0) {
                    for (var ci = 0; ci < addc.length; ci++) {
                        let cx = addc[ci];
                        if (cx !== 0) {
                            naddc.push(cx);
                        }
                    }
                }
                var addo = this.state.additionalOwners;
                var naddo = [];
                if (addo.length > 0) {
                    for (var oi = 0; oi < addo.length; oi++) {
                        let ox = addo[oi];
                        if (ox !== 0) {
                            naddo.push(ox);
                        }
                    }
                }
                this.setState({ appId: res.data.responseData, additionalContacts: naddc, additionalOwners: naddo, update:true });
                this.props.global.setLoading(false);
                toast.success("Application saved successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
            }).catch(err => {
                var addc = this.state.additionalContacts;
                var naddc = [];
                if (addc.length > 0) {
                    for (var ci = 0; ci < addc.length; ci++) {
                        let cx = addc[ci];
                        if (cx !== 0) {
                            naddc.push(cx);
                        }
                    }
                }
                var addo = this.state.additionalOwners;
                var naddo = [];
                if (addo.length > 0) {
                    for (var oi = 0; oi < addo.length; oi++) {
                        let ox = addo[oi];
                        if (ox !== 0) {
                            naddo.push(ox);
                        }
                    }
                }
                this.setState({ additionalContacts: naddc, additionalOwners: naddo });
                this.props.global.setLoading(false);
                toast.error("Oops! something went wrong, please review and try again.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    submitComplete() {
        let percentage = 0
        let checkAccepted = this.state.termsAccepted;
        let allchecked = arr => arr.every(v => v === true);
        let allValueSigner = signers => signers.every(s => s && s?.value !== "");
        const {signer_acceptance, signer_address, signer_city, signer_country, signer_email, signer_name, signer_phone, signer_ssn, signer_state, signer_zip} = Object(this.props.boarding.template_data)
        for (var j = 0; j <= this.state.additionalOwners.length; j++) {
            if(this.props.boarding.template_data['ownerpercent_' + j])
                percentage += parseInt(this.props.boarding.template_data['ownerpercent_' + j]?.value)
        }
        let tmp_data = this.props.boarding.template_data
        let percentage_proc = 0;
        if(tmp_data['binperson'])
            percentage_proc += parseInt(tmp_data['binperson']?.value) 
        if(tmp_data['binphone'])
            percentage_proc += parseInt(tmp_data['binphone']?.value)
        if(tmp_data['binweb'])
            percentage_proc += parseInt(tmp_data['binweb']?.value)           
        if (!allValueSigner([signer_address, signer_city, signer_country, signer_email, signer_name, signer_phone, signer_ssn, signer_state, signer_zip])) {
            toast.error("The signer informations is incomplete.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else if(this.state.baseTemplate.documentsData.termsConditions && this.state.baseTemplate.documentsData.termsConditions?.visible && !signer_acceptance){
            toast.error("The signer has not accepted the terms.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else if (percentage_proc > 100) {
            toast.error("Combined total payments percent must be equal to or less than 100%!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else if (percentage > 100) {
            toast.error("Total ownership must be equal to or less than 100%!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else if (this.props.boarding.errors()) {
            this.setState({ complete: false });
            toast.error("Please review the information required or with errors and try again.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else if(checkAccepted.length === 0 || !allchecked(checkAccepted)){
            toast.error("You must click and review link(s) above before agreeing.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }
        else {
            //submit app
            this.props.global.setLoading(true);
            this.props.boarding.saveApplication(this.state.orgId, this.state.linkId, this.state.additionalOwners, this.state.additionalContacts, true, this.state.appId).then(res => {
                var addc = this.state.additionalContacts;
                var naddc = [];
                if (addc.length > 0) {
                    for (var ci = 0; ci < addc.length; ci++) {
                        let cx = addc[ci];
                        if (cx !== 0) {
                            naddc.push(cx);
                        }
                    }
                }
                var addo = this.state.additionalOwners;
                var naddo = [];
                if (addo.length > 0) {
                    for (var oi = 0; oi < addo.length; oi++) {
                        let ox = addo[oi];
                        if (ox !== 0) {
                            naddo.push(ox);
                        }
                    }
                }
                toast.success("Application saved successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
                this.setState({ appId: res.data.responseData, additionalContacts: naddc, additionalOwners: naddo });
                this.props.boarding.updateApplicationStatus(res.data.responseData, 1).then(res => {
                    this.props.global.setLoading(false);
                    this.props.history.push("/"+PayabliStorageManager.getEntryName()+"/boarding/applications");
                }).catch(() => {
                    this.props.global.setLoading(false);
                    this.props.history.push("/"+PayabliStorageManager.getEntryName()+"/boarding/applications");
                });
            }).catch(err => {
                var addc = this.state.additionalContacts;
                var naddc = [];
                if (addc.length > 0) {
                    for (var ci = 0; ci < addc.length; ci++) {
                        let cx = addc[ci];
                        if (cx !== 0) {
                            naddc.push(cx);
                        }
                    }
                }
                var addo = this.state.additionalOwners;
                var naddo = [];
                if (addo.length > 0) {
                    for (var oi = 0; oi < addo.length; oi++) {
                        let ox = addo[oi];
                        if (ox !== 0) {
                            naddo.push(ox);
                        }
                    }
                }
                this.setState({ additionalContacts: naddc, additionalOwners: naddo });
                this.props.global.setLoading(false);
                toast.error("Oops! something went wrong, please review and try again.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error-container',
                });
            });
        }
    }

    closeEndLinkModal() {
        if (this.state.endLinkModalIsOpen) {
            window.location.reload();
        }
    }

    addOwnerContacts() {

    }
    onCopySigner2Owner() {
		const ownerNonProfit = {
			"ownername": this.props.boarding.template_data?.signer_name?.value || '',
			"ownerssn": this.props.boarding.template_data?.signer_ssn?.value || '',
			"ownerdob": this.props.boarding.template_data?.signer_dob?.value || '',
			"ownerphone1": this.props.boarding.template_data?.signer_phone?.value || '',
			"owneremail": this.props.boarding.template_data?.signer_email?.value || '',
			"oaddress": this.props.boarding.template_data?.signer_address?.value || '',
			"oaddress1": this.props.boarding.template_data?.signer_address1?.value || '',
			"ostate": this.props.boarding.template_data?.signer_state?.value || '',
			"ocountry": this.props.boarding.template_data?.signer_country?.value || '',
			"ocity": this.props.boarding.template_data?.signer_city?.value || '',
			"ozip": this.props.boarding.template_data?.signer_zip?.value || '',
		}
		Object.entries(ownerNonProfit).map(e => this.props.boarding.setTemplateData(`${e[0]}_0`, e[1]))
	}

    removeOwner(k) {
        if (this.state.additionalOwners.length === 0) return;
        let f = this.state.additionalOwners;
        let idx = -1;
        let g = this.state.lastowner - 1;
        for (var i = 0; i < this.state.additionalOwners.length; i++) {
            if (this.state.additionalOwners[i] === k) {
                idx = i;
            }
        }
        if (idx >= 0) {
            f.splice(idx, 1);
            this.setState({ additionalOwners: f, lastowner: g });
        }
    }

    removeDoc(k) {
        if (this.state.additionalFiles.length === 0) return;
        let f = this.state.additionalFiles;
        let idx = -1;
        for (var i = 0; i < this.state.additionalFiles.length; i++) {
            if (this.state.additionalFiles[i] === k) {
                idx = i;
            }
        }
        if (idx >= 0) {
            this.props.boarding.setErrorData("doc_" + this.state.additionalFiles[idx], false);
            this.props.boarding.setTemplateData("doc_" + this.state.additionalFiles[idx], null);
            f.splice(idx, 1);
            this.setState({ additionalFiles: f });
        }
    }

    addOwner() {
        if (!this.state.baseTemplate) return;
        let f = this.state.additionalOwners;
        let g = this.state.lastowner + 1;
        f.push(g);
        this.setState({ additionalOwners: f, lastowner: g });
    }

    addContact() {
        if (!this.state.baseTemplate) return;
        let f = this.state.additionalContacts;
        let g = this.state.lastcontact + 1;
        f.push(g);
        this.setState({ additionalContacts: f, lastcontact: g });
    }

    addDoc() {
        if (!this.state.baseTemplate) return;
        let f = this.state.additionalFiles;
        let g = this.state.lastfile + 1;
        f.push(g);
        this.setState({ additionalFiles: f, lastfile: g });
    }

    removeContact(k) {
        if (this.state.additionalContacts.length === 0) return;
        let f = this.state.additionalContacts;
        let idx = -1;
        for (var i = 0; i < this.state.additionalContacts.length; i++) {
            if (this.state.additionalContacts[i] === k) {
                idx = i;
            }
        }
        if (idx >= 0) {
            f.splice(idx, 1);
            this.setState({ additionalContacts: f });
        }
    }
    onCopyLegalName(e){
        this.setState({
            taxfillname: this.props.boarding.template_data.legalname.value
        })
    }
    getSignerBlock(){
        let signers = [];
            if(this.props.boarding.template_data['ownername_0']){
                signers.push({
                    contactName: this.props.boarding.template_data['ownername_0'] ? this.props.boarding.template_data['ownername_0']?.value : null,
                    contactEmail: this.props.boarding.template_data['owneremail_0'] ? this.props.boarding.template_data['owneremail_0']?.value : null,
                    contactPhone: this.props.boarding.template_data['ownerphone1_0'] ? this.props.boarding.template_data['ownerphone1_0']?.value : null,
                    contactDOB: this.props.boarding.template_data['ownerdob_0'] ? this.props.boarding.template_data['ownerdob_0']?.value : null,
                    contactCountry: this.props.boarding.template_data['ocountry_0'] ? this.props.boarding.template_data['ocountry_0']?.value : null,
                    contactSSN: this.props.boarding.template_data['ownerssn_0'] ? this.props.boarding.template_data['ownerssn_0']?.value : '',
                    contactAddress: this.props.boarding.template_data['oaddress_0'] ? this.props.boarding.template_data['oaddress_0']?.value : null,
                    contactAddress2: this.props.boarding.template_data['oaddress2_0'] ? this.props.boarding.template_data['oaddress2_0']?.value : '',
                    contactCity: this.props.boarding.template_data['ocity_0'] ? this.props.boarding.template_data['ocity_0']?.value : null,
                    contactState: this.props.boarding.template_data['ostate_0'] ? this.props.boarding.template_data['ostate_0']?.value : null,
                    contactZip: this.props.boarding.template_data['ozip_0'] ? this.props.boarding.template_data['ozip_0']?.value : null,
                });
            }

            this.state.additionalOwners.forEach(element => {
                if(this.props.boarding.template_data['ownername_'+element]){
                    signers.push({
                        contactName: this.props.boarding.template_data['ownername_'+element] ? this.props.boarding.template_data['ownername_'+element]?.value : null,
                        contactEmail: this.props.boarding.template_data['owneremail_'+element] ? this.props.boarding.template_data['owneremail_'+element]?.value : null,
                        contactPhone: this.props.boarding.template_data['ownerphone1_'+element] ? this.props.boarding.template_data['ownerphone1_'+element]?.value : null,
                        contactDOB: this.props.boarding.template_data['ownerdob_'+element] ? this.props.boarding.template_data['ownerdob_'+element]?.value : null,
                        contactCountry: this.props.boarding.template_data['ocountry_'+element] ? this.props.boarding.template_data['ocountry_'+element]?.value : null,
                        contactAddress: this.props.boarding.template_data['oaddress_'+element] ? this.props.boarding.template_data['oaddress_'+element]?.value : null,
                        contactAddress2: this.props.boarding.template_data['oaddress2_'+element] ? this.props.boarding.template_data['oaddress2_'+element]?.value : '',
                        contactCity: this.props.boarding.template_data['ocity_'+element] ? this.props.boarding.template_data['ocity_'+element]?.value : null,
                        contactSSN: this.props.boarding.template_data['ownerssn_'+element] ? this.props.boarding.template_data['ownerssn_'+element]?.value : '',
                        contactState: this.props.boarding.template_data['ostate_'+element] ? this.props.boarding.template_data['ostate_'+element]?.value : null,
                        contactZip: this.props.boarding.template_data['ozip_'+element] ? this.props.boarding.template_data['ozip_'+element]?.value : null,
                    });
                }
            });

            if(this.props.boarding.template_data['contactName_0']){
                signers.push({
                    contactName: this.props.boarding.template_data['contactName_0'] ? this.props.boarding.template_data['contactName_0']?.value : null,
                    contactEmail: this.props.boarding.template_data['contactEmail_0'] ? this.props.boarding.template_data['contactEmail_0']?.value : null,
                    contactPhone: this.props.boarding.template_data['contactPhone_0'] ? this.props.boarding.template_data['contactPhone_0']?.value : null,
                });
            }

            this.state.additionalContacts.forEach(element => {
                if(this.props.boarding.template_data['contactName_'+element]){
                    signers.push({
                        contactName: this.props.boarding.template_data['contactName_'+element] ? this.props.boarding.template_data['contactName_'+element]?.value : null,
                        contactEmail: this.props.boarding.template_data['contactEmail_'+element] ? this.props.boarding.template_data['contactEmail_'+element]?.value : null,
                        contactPhone: this.props.boarding.template_data['contactPhone_'+element] ? this.props.boarding.template_data['contactPhone_'+element]?.value : null,
                    });
                }
            });
        this.OwnerTypeChange(this.props.boarding.template_data?.btype?.value)
        return (
            <>
                <h5 className="mt-5">Signer</h5>
                <p className="small mb-4 dark-grey">Please select which of the Owners or Contacts will be signing the application.  You may also manually add a non-owner signer if desired.</p>
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="form-floating">
                            <select onChange={(e) => this.selectContactAsSigner(e)} className="form-select form-control" id="selectContactAsSigner">
                                <option value=""> Add Signer information manually... </option>
                                {
                                    signers.map((record, i) => (
                                        <option value={JSON.stringify(record)}>{record.contactName}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="selectContactAsSigner">Owner or Contact as signer</label>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <InputSimpleLink iTitle="Name" iName="signer_name" iValue={this.state.signer.name} iValidator={this.props.global.getValidator().text} full />
                    </div>
                    <div className="col-sm-6">
                        <InputSimpleLink iHide={true} iMask="ssn" iTitle="Social Security Number" iName="signer_ssn" iValue={this.state.signer.ssn} iValidator={this.props.global.getValidator().ssn} full />
                    </div>
                    
                    <InputCalendarLink iMax={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())} iTitle="Date of Birth" iName={"signer_dob"} iValue={this.state.signer.dob > 0 ? this.props.global.getDateFromValue(this.state.signer.dob) : new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())} full />
                 
                    <div className="col-sm-6">
                        <PhoneInput iMask="phone" iTitle="Phone" iName="signer_phone" iValue={this.state.signer.phone} iValidator={this.props.global.getValidator().phone} full />
                    </div>
                    <div className="col-sm-6">
                        <InputSimpleLink iMask="email" iTitle="Email" iName="signer_email" iValue={this.state.signer.email} iValidator={this.props.global.getValidator().email} full />
                    </div>
                    <LocationSearchInput
                        placeholder="Signer Address" 
                        customSize="col-md-6 mb-3"
                        withCountry="true"
                        withoutRow="true"
                        onChange={this.handleGoogleAutocompleteSignerAddress} 
                        handleTextChange={this.handleTextChangeMask} 
                        address={this.state.signer_address || this.state.signer.address }
                        address2={this.state.signer_address2 || this.state.signer.address1 }
                        city={this.state.signer_city || this.state.signer.city }
                        state={this.state.signer_state || this.state.signer.state }
                        zipcode={this.state.signer_zip || this.state.signer.zip }
                        country={this.state.signer_country || this.state.signer.country }
                        handleTextChangeKeys={["signer_address", "signer_address2", "signer_city", "signer_state", "signer_zip", "signer_country"]}
                    />
                </div>
                
            </>
        )
    }

    onCopyAddress2Business(e, k){
        this.setState(
            {
                ["oaddress_" + k]: this.props.boarding.template_data["baddress"]?.value,
                ["ocity_" + k]: this.props.boarding.template_data["bcity"]?.value,
                ["ozip_" + k]: this.props.boarding.template_data["bzip"]?.value,
                ["ostate_" + k]: this.props.boarding.template_data["bstate"]?.value,
                ["ocountry_" + k]: this.props.boarding.template_data["bcountry"]?.value,
            }
        )
    }

    getOwnerSubBlock(k) {
        this.OwnerTypeChange(this.props.boarding.template_data?.btype?.value)
        if (!this.state.baseTemplate.ownershipData || this.state.baseTemplate.ownershipData === null) return;
        var rows = [];

        if (this.state.baseTemplate.ownershipData.ownername && this.state.baseTemplate.ownershipData.ownername !== null) {
            if (this.state.baseTemplate.ownershipData.ownername?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownername.required) ? true : false} iTitle="Owner Name" iName={"ownername_" + k} iValue={this.state.template_data.ownershipData.ownername?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Owner Name" iName={"ownername_" + k} iValue={this.state.template_data.ownershipData.ownername?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.ownertitle && this.state.baseTemplate.ownershipData.ownertitle !== null) {
            if (this.state.baseTemplate.ownershipData.ownertitle?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownertitle.required) ? true : false} iTooltip="Business Title / Role of the Owner" iTitle="Owner Title" iName={"ownertitle_" + k} iValue={this.state.template_data.ownershipData.ownertitle?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTooltip="Business Title / Role of the Owner" iTitle="Owner Title" iName={"ownertitle_" + k} iValue={this.state.template_data.ownershipData.ownertitle?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.ownerpercent && this.state.baseTemplate.ownershipData.ownerpercent !== null) {
            if (this.state.baseTemplate.ownershipData.ownerpercent?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerpercent.required) ? true : false} iMask="percentage" iTitle="Ownership %" iName={"ownerpercent_" + k} iValue={this.state.template_data.ownershipData.ownerpercent?.value} iValidator={this.props.global.getValidator().float} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Ownership %" iName={"ownerpercent_" + k} iValue={this.state.template_data.ownershipData.ownerpercent?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.ownerssn && this.state.baseTemplate.ownershipData.ownerssn !== null) {
            if (this.state.baseTemplate.ownershipData.ownerssn?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerssn.required) ? true : false} iHide={true} iMask="ssn" iTooltip="Social Security Number of the Owner. Federal Law requires us to request this information"  iTitle="Owner SSN" iName={"ownerssn_" + k} iValue={this.state.template_data.ownershipData.ownerssn?.value} iValidator={this.props.global.getValidator().ssn} />);
            } else {
                rows.push(<ReadOnlySimpleLink iHide={true} iMask="ssn" iTooltip="Social Security Number of the Owner. Federal Law requires us to request this information" iTitle="Owner SSN" iName={"ownerssn_" + k} iValue={this.state.template_data.ownershipData.ownerssn?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.ownerdob && this.state.baseTemplate.ownershipData.ownerdob !== null) {
            if (this.state.baseTemplate.ownershipData.ownerdob?.readOnly === false) {
                rows.push(<InputCalendarLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerdob.required) ? true : false} iMax={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())} iTitle="Date of Birth" iName={"ownerdob_" + k} iValue={this.state.template_data.ownershipData.ownerdob?.value?.length > 0 && this.props.global.getDateFromValue(this.state.template_data.ownershipData.ownerdob?.value) <= new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay()) ? this.props.global.getDateFromValue(this.state.template_data.ownershipData.ownerdob?.value) : new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay())} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Date of Birth" iName={"ownerdob_" + k} iValue={this.state.template_data.ownershipData.ownerdob?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.ownerphone1 && this.state.baseTemplate.ownershipData.ownerphone1 !== null) {
            if (this.state.baseTemplate.ownershipData.ownerphone1?.readOnly === false) {
                rows.push(<PhoneInput iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerphone1.required) ? true : false} iMask="phone" iTooltip="Direct Phone Number of the owner" iTitle="Phone Number" iName={"ownerphone1_" + k} iValue={this.state.template_data.ownershipData.ownerphone1?.value} iValidator={this.props.global.getValidator().phone} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="phone" iTooltip="Direct Phone Number of the owner" iTitle="Phone Number" iName={"ownerphone1_" + k} iValue={this.state.template_data.ownershipData.ownerphone1?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.ownerphone2 && this.state.baseTemplate.ownershipData.ownerphone2 !== null) {
            if (this.state.baseTemplate.ownershipData.ownerphone2?.readOnly === false) {
                rows.push(<PhoneInput iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerphone2.required) ? true : false} iMask="phone" iTitle="Additional Phone Number" iName={"ownerphone2_" + k} iValue={this.state.template_data.ownershipData.ownerphone2?.value} iValidator={this.props.global.getValidator().phone} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="phone" iTitle="Additional Phone Number" iName={"ownerphone2_" + k} iValue={this.state.template_data.ownershipData.ownerphone2?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.owneremail && this.state.baseTemplate.ownershipData.owneremail !== null) {
            if (this.state.baseTemplate.ownershipData.owneremail?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.owneremail.required) ? true : false} iMask="email" iTooltip="Direct Email of the Owner" iTitle="Email Address" iName={"owneremail_" + k} iValue={this.state.template_data.ownershipData.owneremail?.value} iValidator={this.props.global.getValidator().email} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="email" iTooltip="Direct Email of the Owner" iTitle="Email Address" iName={"owneremail_" + k} iValue={this.state.template_data.ownershipData.owneremail?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.ownerdriver && this.state.baseTemplate.ownershipData.ownerdriver !== null) {
            if (this.state.baseTemplate.ownershipData.ownerdriver?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.ownerdriver.required) ? true : false} iTitle="Driver's License #" iName={"ownerdriver_" + k} iValue={this.state.template_data.ownershipData.ownerdriver?.value} iValidator={this.props.global.getValidator().alphanumeric} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Driver's License #" iName={"ownerdriver_" + k} iValue={this.state.template_data.ownershipData.ownerdriver?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.odriverstate && this.state.baseTemplate.ownershipData.odriverstate !== null) {
            if (this.state.baseTemplate.ownershipData.odriverstate?.readOnly === false) {
                rows.push(<InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.odriverstate.required) ? true : false} iType="us_states" iTitle="Driver's State License" iName={"odriverstate_" + k} iValue={this.state.template_data.ownershipData.odriverstate?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Driver's State License" iName={"odriverstate_" + k} iValue={this.state.template_data.ownershipData.odriverstate?.value} />);
            }
        }
        rows.push(
            <LocationSearchInput
                placeholder="Address" 
                customSize="col-md-6 mb-3"
                withCountry="true"
                withoutRow="true"
                onChange={this.handleGoogleAutocompleteOwnerAddress} 
                handleTextChange={this.handleTextChangeMask} 
                address={this.state["oaddress_" + k] ? this.state["oaddress_" + k] : this.state.template_data.ownershipData.oaddress?.value}
                city={this.state["ocity_" + k] ? this.state["ocity_" + k] : this.state.template_data.ownershipData.ocity?.value} 
                state={this.state["ostate_" + k] ? this.state["ostate_" + k] : this.state.template_data.ownershipData.ostate?.value}
                zipcode={this.state["ozip_" + k] ? this.state["ozip_" + k] : this.state.template_data.ownershipData.ozip?.value}
                country={this.state["ocountry_" + k] ? this.state["ocountry_" + k] : this.state.template_data.ownershipData.ocountry?.value} 
                oindex={k}
                iTooltip="Residential Street Address of Owner. Federal Law requires us to request this information"
                // iLink="true" 
                // iLinkClick={(e) => this.onCopyAddress2Business(e, k)} 
                // iLinkText="Copy address from business details"
                handleTextChangeKeys={["oaddress_" + k, "address2", "ocity_" + k, "ostate_" + k, "ozip_" + k, "ocountry_" + k]}
            />
        )
        return (
            <>
                {rows}
            </>
        );
    }

    getContactBlock(k) {
        if (!this.state.baseTemplate.ownershipData) return;
        if (this.state.baseTemplate.ownershipData === null) return;
        var rows = [];

        if (this.state.baseTemplate.ownershipData.contactName && this.state.baseTemplate.ownershipData.contactName !== null) {
            if (this.state.baseTemplate.ownershipData.contactName?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactName.required) ? true : false} iTitle="Contact Name" iName={"contactName_" + k} iValue={this.state.baseTemplate.ownershipData.contactName?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Contact Name" iName={"contactName_" + k} iValue={this.state.baseTemplate.ownershipData.contactName?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.contactTitle && this.state.baseTemplate.ownershipData.contactTitle !== null) {
            if (this.state.baseTemplate.ownershipData.contactTitle?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactTitle.required) ? true : false} iTitle="Contact Title" iName={"contactTitle_" + k} iValue={this.state.baseTemplate.ownershipData.contactTitle?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Contact Title" iName={"contactTitle_" + k} iValue={this.state.baseTemplate.ownershipData.contactTitle?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.contactEmail && this.state.baseTemplate.ownershipData.contactEmail !== null) {
            if (this.state.baseTemplate.ownershipData.contactEmail?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactEmail.required) ? true : false} iMask="email" iTitle="Contact Email" iName={"contactEmail_" + k} iValue={this.state.baseTemplate.ownershipData.contactEmail?.value} iValidator={this.props.global.getValidator().email} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="email" iTitle="Contact Email" iName={"contactEmail_" + k} iValue={this.state.baseTemplate.ownershipData.contactEmail?.value} />);
            }
        }
        if (this.state.baseTemplate.ownershipData.contactPhone && this.state.baseTemplate.ownershipData.contactPhone !== null) {
            if (this.state.baseTemplate.ownershipData.contactPhone?.readOnly === false) {
                rows.push(<PhoneInput iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactPhone.required) ? true : false} iMask="phone" iTitle="Contact Phone" iName={"contactPhone_" + k} iValue={this.state.baseTemplate.ownershipData.contactPhone?.value} iValidator={this.props.global.getValidator().phone} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="phone" iTitle="Contact Phone" iName={"contactPhone_" + k} iValue={this.state.baseTemplate.ownershipData.contactPhone?.value} />);
            }
        }

        return (
            <Row className="mt-4">
                {rows}
            </Row>
        );
    }

    getDocumentBlock() {
        var rows = [];
        var arows = [];
        var files = [];
        let zipfile = '';
        if (this.state.baseData.documentsRef && this.state.baseData.documentsRef !== null) {
            if (this.state.baseData.documentsRef.zipfile !== null) zipfile = <b>{"Documents stored in: " + this.state.baseData.documentsRef.zipfile}</b>;
            if (this.state.baseData.documentsRef.filelist !== null && this.state.baseData.documentsRef.filelist.length > 0) {
                for (var j = 0; j < this.state.baseData.documentsRef.filelist.length; j++) {
                    files.push(<><span className="text-success">{this.state.baseData.documentsRef.filelist[j].originalName}</span><br /></>);
                }
            }
        }
        let x = parseInt(this.state.baseTemplate.documentsData.minimumDocuments, 10);
        if (files.length < x) {
            x = x - files.length;
            for (let i = 0; i < x; i++) {
                rows.push(
                    <div className="col-md-6 mt-2" key={i}>
                        <input type="file" className="form-control input-error" id={"doc_" + i} name={"doc_" + i} onChange={this.onFileChange} accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain" />
                    </div>
                );
                if (!this.props.boarding.existsTemplateData("doc_" + i)) {
                    this.props.boarding.setErrorData("doc_" + i, true);
                    this.props.boarding.setTemplateData("doc_" + i, null);
                }
            }
        }
        else { x = 0; }
        if (this.state.additionalFiles.length > 0) {
            for (let j = 0; j < this.state.additionalFiles.length; j++) {
                let f = this.state.additionalFiles[j];
                rows.push(
                    <div className="col-md-6 mt-2" key={f}>
                        <InputGroup>
                            <input type="file" className="form-control input-error" id={"doc_" + f} name={"doc_" + f} onChange={this.onFileChange} accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain" />
                            <Button variant="danger" onClick={(e) => this.removeDoc(f)}><BiTrash fontSize="1.5em" /></Button>
                        </InputGroup>
                    </div>
                );
                if (!this.props.boarding.existsTemplateData("doc_" + f)) {
                    this.props.boarding.setErrorData("doc_" + f, true);
                    this.props.boarding.setTemplateData("doc_" + f, null);
                }
            }
        }


        return (
            <><Row className="mt-2">
                <div className="col-3">
                    <p>{zipfile}</p>
                </div>
                <div className="col-9">
                    {files}
                </div>
            </Row><Row className="mt-2">
                    {(x > 0) &&
                        <p className="small"> 
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>To help our Underwriting Team get your merchant account ready the more information the better. Some recommended docs are:  Current merchant services statements, Drivers License, Business license, Voided Check</Tooltip>}
                        >
                            <BiInfoCircle className="info-icon"/> 
                        </OverlayTrigger>
                        Please upload supporting documents below; at least 1 file is expected. Upload max is 30MB of any combination of .pdf .jpg or .png files.
                        </p>
                    }
                    {rows}
                    {arows}
                    <div className="col-md-6 mt-2" id="addFiles">
                        <button className="btn bordered mt-1" style={{margin: "0"}} onClick={(e) => this.addDoc()}><BiPlus/> Add more documents</button>
                    </div>
                </Row></>
        );
    }

    getBusinessBlock() {
        var rows = [];
        if (this.state.baseTemplate.businessData && this.state.baseTemplate.businessData !== null) {
            if (this.state.baseTemplate.businessData.legalname && this.state.baseTemplate.businessData.legalname !== null) {
                if (this.state.baseTemplate.businessData.legalname?.readOnly === false) {
                    rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.legalname.required) ? true : false} iTitle="Business Legal Name" iName="legalname" iValue={this.state.template_data.businessData.legalname?.value} iValidator={this.props.global.getValidator().text}  />)
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTitle="Business Legal Name" iName="legalname" iValue={this.state.template_data.businessData.legalname?.value} />)
                }
            }
            if (this.state.baseTemplate.businessData.dbaname && this.state.baseTemplate.businessData.dbaname !== null) {
                if (this.state.baseTemplate.businessData.dbaname?.readOnly === false) {
                    rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.dbaname.required) ? true : false} iTooltip='This is your fictitious "doing business as" name that you may have registered to distinguish from your legal name' iTitle="Business DBA Name" iName="dbaname" iValue={this.state.template_data.businessData.dbaname?.value} iValidator={this.props.global.getValidator().text} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTooltip='This is your fictitious "doing business as" name that you may have registered to distinguish from your legal name' iTitle="Business DBA Name" iName="dbaname" iValue={this.state.template_data.businessData.dbaname?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.btype && this.state.baseTemplate.businessData.btype !== null) {
                if (this.state.baseTemplate.businessData.btype?.readOnly === false) {
                    rows.push(<InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.btype.required) ? true : false} iType="btype" iTitle="Business Type" iName="btype" iValue={this.state.template_data.businessData.btype?.value} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTitle="Business Type" iName="btype" iValue={this.state.template_data.businessData.btype?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.ein && this.state.baseTemplate.businessData.ein !== null) {
                if (this.state.baseTemplate.businessData.ein?.readOnly === false) {
                    rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.ein.required) ? true : false} iTooltip="Tax ID Number or Social Security Number for Sole Proprietor" iMask="ein" iTitle="Business EIN" iName="ein" iValue={this.state.template_data.businessData.ein?.value} iValidator={this.props.global.getValidator().ein} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTooltip="Tax ID Number or Social Security Number for Sole Proprietor" iMask="ein" iTitle="Business EIN" iName="ein" iValue={this.state.template_data.businessData.ein?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.taxfillname && this.state.baseTemplate.businessData.taxfillname !== null) {
                if (this.state.baseTemplate.businessData.taxfillname.readOnly === false) {
                    rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.taxfillname.required) ? true : false} iTooltip="The combination of your Tax ID Number and your Tax Filing Name must match your IRS Documents" iTitle="Tax Filing Name" iName="taxfillname" iLink="true" iLinkClick={(e) => this.onCopyLegalName(e)} iLinkText="Tax Fill Name same as Business Legal Name" iValue={this.state.taxfillname || this.state.template_data.businessData.taxfillname.value} iValidator={this.props.global.getValidator().text} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTooltip="The combination of your Tax ID Number and your Tax Filing Name must match your IRS Documents" iTitle="Tax Filing Name" iName="taxfillname" iValue={this.state.template_data.businessData.taxfillname.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.license && this.state.baseTemplate.businessData.license !== null) {
                if (this.state.baseTemplate.businessData.license?.readOnly === false) {
                    rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.license.required) ? true : false} iTooltip="ID number for Government Issued Business License" iTitle="Business License" iName="license" iValue={this.state.template_data.businessData.license?.value} iValidator={this.props.global.getValidator().alphanumeric} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTooltip="ID number for Government Issued Business License" iTitle="Business License" iName="license" iValue={this.state.template_data.businessData.license?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.licstate && this.state.baseTemplate.businessData.licstate !== null) {
                if (this.state.baseTemplate.businessData.licstate?.readOnly === false) {
                    rows.push(<InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.licstate.required) ? true : false} iTitle="License State" iName="licstate" iValue={this.state.template_data.businessData.licstate?.value} iType="us_states" />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTitle="License State" iName="licstate" iValue={this.state.template_data.businessData.licstate?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.website && this.state.baseTemplate.businessData.website !== null) {
                if (this.state.baseTemplate.businessData.website?.readOnly === false) {
                    rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.website.required) ? true : false} iTitle="Website" iName="website" iValue={this.state.template_data.businessData.website?.value} iValidator={this.props.global.getValidator().url} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTitle="Website" iName="website" iValue={this.state.template_data.businessData.website?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.startdate && this.state.baseTemplate.businessData.startdate !== null) {
                if (this.state.baseTemplate.businessData.startdate?.readOnly === false) {
                    rows.push(<InputCalendarLink iRequired={[true, null].includes(this.state.baseTemplate.businessData.startdate.required) ? true : false} iTitle="Business Start Date" iName="startdate" iValue={(this.state.template_data.businessData.startdate && this.state.template_data.businessData.startdate?.value && this.state.template_data.businessData.startdate?.value.length > 0) ? this.props.global.getDateFromValue(this.state.template_data.businessData.startdate?.value) : new Date()} iMax={new Date()} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iTitle="Business Start Date" iName="startdate" iValue={this.state.template_data.businessData.startdate?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.phonenumber && this.state.baseTemplate.businessData.phonenumber !== null) {
                if (this.state.baseTemplate.businessData.phonenumber?.readOnly === false) {
                    rows.push(<PhoneInput iRequired={[true, null].includes(this.state.baseTemplate.businessData.phonenumber.required) ? true : false} iMask="phone" iTooltip="Direct Phone Number of the owner" iTitle="Phone Number" iName="phonenumber" iValue={this.state.template_data.businessData.phonenumber?.value} iValidator={this.props.global.getValidator().phone} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iMask="phone" iTooltip="Direct Phone Number of the owner" iTitle="Phone Number" iName="phonenumber" iValue={this.state.template_data.businessData.phonenumber?.value} />);
                }
            }
            if (this.state.baseTemplate.businessData.faxnumber && this.state.baseTemplate.businessData.faxnumber !== null) {
                if (this.state.baseTemplate.businessData.faxnumber?.readOnly === false) {
                    rows.push(<PhoneInput iRequired={[true, null].includes(this.state.baseTemplate.businessData.faxnumber.required) ? true : false} iMask="phone" iTitle="Fax Number" iName="faxnumber" iValue={this.state.template_data.businessData.faxnumber?.value} iValidator={this.props.global.getValidator().phone} />);
                }
                else {
                    rows.push(<ReadOnlySimpleLink iMask="phone" iTitle="Fax Number" iName="faxnumber" iValue={this.state.template_data.businessData.faxnumber?.value} />);
                }
            }
        }
        return (
            <Row className="mt-4">
                {rows}
            </Row>
        );
    }

    getBusinessAddressBlock() {
        var rows = [];
        var mrows = [];
        let required_fields = []
        let readonly_fields = []
        let invisibles_fields = []

        if (this.state.baseTemplate.businessData && this.state.baseTemplate.businessData !== null) {

            if (this.state.baseTemplate.businessData.baddress && this.state.baseTemplate.businessData.baddress !== null) {
                if (this.state.baseTemplate.businessData.baddress?.readOnly === true) {
                    readonly_fields.push("address")
                }
                else if([true, null].includes(this.state.baseTemplate.businessData.baddress.required) ? true : false){
                    required_fields.push("address")
                }
            }
            else{
                invisibles_fields.push("address")
            }
            if (this.state.baseTemplate.businessData.baddress1 && this.state.baseTemplate.businessData.baddress1 !== null) {
                if (this.state.baseTemplate.businessData.baddress1?.readOnly === true) {
                    readonly_fields.push("address2")
                }
                else if([true, null].includes(this.state.baseTemplate.businessData.baddress1.required) ? true : false){
                    required_fields.push("address2")
                }
            }
            else{
                invisibles_fields.push("address2")
            }
            if (this.state.baseTemplate.businessData.bcity && this.state.baseTemplate.businessData.bcity !== null) {
                if (this.state.baseTemplate.businessData.bcity?.readOnly === true) {
                    readonly_fields.push("city")
                }
                else if([true, null].includes(this.state.baseTemplate.businessData.bcity.required) ? true : false){
                    required_fields.push("city")
                }
            }
            else{
                invisibles_fields.push("city")
            }
            if (this.state.baseTemplate.businessData.bstate && this.state.baseTemplate.businessData.bstate !== null) {
                if (this.state.baseTemplate.businessData.bstate?.readOnly === true) {
                    readonly_fields.push("state")
                }
                else if([true, null].includes(this.state.baseTemplate.businessData.bstate.required) ? true : false){
                    required_fields.push("state")
                }
            }
            else{
                invisibles_fields.push("state")
            }
            if (this.state.baseTemplate.businessData.bzip && this.state.baseTemplate.businessData.bzip !== null) {
                if (this.state.baseTemplate.businessData.bzip?.readOnly === true) {
                    readonly_fields.push("zipcode")
                }
                else if([true, null].includes(this.state.baseTemplate.businessData.bzip.required) ? true : false){
                    required_fields.push("zipcode")
                }
            }
            else{
                invisibles_fields.push("zipcode")
            }
            if (this.state.baseTemplate.businessData.bcountry && this.state.baseTemplate.businessData.bcountry !== null) {
                if (this.state.baseTemplate.businessData.bcountry?.readOnly === true) {
                    readonly_fields.push("country")
                }
                else if([true, null].includes(this.state.baseTemplate.businessData.bcountry.required) ? true : false){
                    required_fields.push("country")
                }
            }
            else{
                invisibles_fields.push("country")
            }
            rows.push(
                <LocationSearchInput
                    placeholder="Business Address" 
                    customSize="col-md-6 mb-3"
                    withCountry="true"
                    withoutRow="true"
                    onChange={this.handleGoogleAutocompleteBusinessAddress} 
                    handleTextChange={this.handleTextChangeMask} 
                    address={this.state.template_data.businessData.baddress?.value || this.state.baddress}
                    address2={this.state.template_data.businessData.baddress1?.value || this.state.baddress1}
                    city={this.state.template_data.businessData.bcity?.value || this.state.bcity}
                    state={this.state.template_data.businessData.bstate?.value || this.state.bstate}
                    zipcode={this.state.template_data.businessData.bzip?.value || this.state.bzip}
                    country={this.state.template_data.businessData.bcountry?.value || this.state.bcountry}
                    iRequired={required_fields}
                    iReadonly={readonly_fields}
                    iInvisible={invisibles_fields}
                    iTooltip="Where the business is located"
                    handleTextChangeKeys={["baddress", "baddress1", "bcity", "bstate", "bzip", "bcountry"]}
                />
            )
            if ((this.state.baseTemplate.businessData.baddress !== null || this.state.baseTemplate.businessData.baddress1 !== null || this.state.baseTemplate.businessData.bcity !== null || this.state.baseTemplate.businessData.bstate !== null || this.state.baseTemplate.businessData.bzip !== null || this.state.baseTemplate.businessData.bcountry !== null) && (this.state.baseTemplate.businessData.maddress !== null || this.state.baseTemplate.businessData.maddress1 !== null || this.state.baseTemplate.businessData.mcity !== null || this.state.baseTemplate.businessData.mstate !== null || this.state.baseTemplate.businessData.mzip !== null || this.state.baseTemplate.businessData.mcountry !== null)) {
                mrows.push(<div className="col-12 text-left mb-3">
                    <div className="icheck-primary">
                        <input checked={this.state.bsameaddress} type="checkbox" name="b_sameaddress" id='b_sameaddress' onChange={this.bSameaddresshandleChange} />
                        <label htmlFor="b_sameaddress">Mailing Address same as Business Address</label>
                    </div>
                </div>);
            }
            if (!this.state.bsameaddress) {
                let required_fields = []
                let readonly_fields = []
                let invisibles_fields = []
                if (this.state.baseTemplate.businessData.maddress && this.state.baseTemplate.businessData.maddress !== null) {
                    if (this.state.baseTemplate.businessData.maddress?.readOnly === true) {
                        readonly_fields.push("address")
                    }
                    else if([true, null].includes(this.state.baseTemplate.businessData.maddress.required) ? true : false){
                        required_fields.push("address")
                    }
                }
                else{
                    invisibles_fields.push("address")
                }

                if (this.state.baseTemplate.businessData.maddress1 && this.state.baseTemplate.businessData.maddress1 !== null) {
                    if (this.state.baseTemplate.businessData.maddress1?.readOnly === true) {
                        readonly_fields.push("address2")
                    }
                    else if([true, null].includes(this.state.baseTemplate.businessData.maddress1.required) ? true : false){
                        required_fields.push("address2")
                    }
                }
                else{
                    invisibles_fields.push("address2")
                }
                if (this.state.baseTemplate.businessData.mcity && this.state.baseTemplate.businessData.mcity !== null) {
                    if (this.state.baseTemplate.businessData.mcity?.readOnly === true) {
                        readonly_fields.push("city")
                    }
                    else if([true, null].includes(this.state.baseTemplate.businessData.mcity.required) ? true : false){
                        required_fields.push("city")
                    }
                }
                else{
                    invisibles_fields.push("city")
                }
                if (this.state.baseTemplate.businessData.mstate && this.state.baseTemplate.businessData.mstate !== null) {
                    if (this.state.baseTemplate.businessData.mstate?.readOnly === true) {
                        readonly_fields.push("state")
                    }
                    else if([true, null].includes(this.state.baseTemplate.businessData.mstate.required) ? true : false){
                        required_fields.push("state")
                    }
                }
                else{
                    invisibles_fields.push("state")
                }
                if (this.state.baseTemplate.businessData.mzip && this.state.baseTemplate.businessData.mzip !== null) {
                    if (this.state.baseTemplate.businessData.mzip?.readOnly === true) {
                        readonly_fields.push("zipcode")
                    }
                    else if([true, null].includes(this.state.baseTemplate.businessData.mzip.required) ? true : false){
                        required_fields.push("zipcode")
                    }
                }
                else{
                    invisibles_fields.push("zipcode")
                }
                if (this.state.baseTemplate.businessData.mcountry && this.state.baseTemplate.businessData.mcountry !== null) {
                    if (this.state.baseTemplate.businessData.mcountry?.readOnly === true) {
                        readonly_fields.push("country")
                    }
                    else if([true, null].includes(this.state.baseTemplate.businessData.mcountry.required) ? true : false){
                        required_fields.push("country")
                    }
                }
                else{
                    invisibles_fields.push("country")
                }
                mrows.push(
                    <LocationSearchInput
                        placeholder="Mailing Address" 
                        customSize="col-md-6 mb-3"
                        withCountry="true"
                        withoutRow="true"
                        onChange={this.handleGoogleAutocompleteBusinessMAddress} 
                        handleTextChange={this.handleTextChangeMask} 
                        address={this.state.template_data.businessData.maddress?.value}
                        address2={this.state.template_data.businessData.maddress1?.value}
                        city={this.state.template_data.businessData.mcity?.value}
                        state={this.state.template_data.businessData.mstate?.value}
                        zipcode={this.state.template_data.businessData.mzip?.value}
                        country={this.state.template_data.businessData.mcountry?.value}
                        iRequired={required_fields}
                        iReadonly={readonly_fields}
                        iInvisible={invisibles_fields}
                        handleTextChangeKeys={["maddress", "maddress1", "mcity", "mstate", "mzip", "mcountry"]}
                    />
                )
                
            }
        }
        return (
            <><Row className="mt-2">
                {rows}
            </Row><hr /><Row className="mt-2">
                    {mrows}
                </Row></>
        );
    }

    getProcessingBlock() {
        if (this.state.baseTemplate.processingData === null) return;
        var rows = [];

        if (this.state.baseTemplate.processingData.mcc && this.state.baseTemplate.processingData.mcc !== null) {
            if (this.state.baseTemplate.processingData.mcc?.readOnly === false) {
                rows.push(
                <InputSelectSearchable iRequired={[true, null].includes(this.state.baseTemplate.processingData.mcc.required) ? true : false} iTitle="Industry (Merchant Category Code)" iName="mcc" iValue={this.state.template_data.processingData.mcc?.value} iValidator={this.props.global.getValidator().text}/>
                
                );
            } else {
                rows.push(<InputSelectSearchable iReadonly={true}  iTitle="Industry (Merchant Category Code)" iName="mcc" iValue={this.state.template_data.processingData.mcc?.value}/>);
            }
        }
        if (this.state.baseTemplate.processingData.bsummary && this.state.baseTemplate.processingData.bsummary !== null) {
            if (this.state.baseTemplate.processingData.bsummary?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.bsummary.required) ? true : false} iTooltip="Please provide some background on your Business"  iTitle="Tell us about your Business" iName="bsummary" iValue={this.state.template_data.processingData.bsummary?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTooltip="Please provide some background on your Business"  iTitle="Tell us about your Business" iName="bsummary" iValue={this.state.template_data.processingData.bsummary?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.binperson && this.state.baseTemplate.processingData.binperson !== null) {
            if (this.state.baseTemplate.processingData.binperson?.readOnly === false) {
                rows.push(<InputSimpleLink iMask="percentage" iRequired={[true, null].includes(this.state.baseTemplate.processingData.binperson.required) ? true : false} iType="s100x10" iTitle="Percent of payments in Person" iName="binperson" iValue={this.state.template_data.processingData.binperson?.value || "0"} iValidator={this.props.global.getValidator().float} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Percent of payments in Person" iName="binperson" iValue={this.state.template_data.processingData.binperson?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.binphone && this.state.baseTemplate.processingData.binphone !== null) {
            if (this.state.baseTemplate.processingData.binphone?.readOnly === false) {
                rows.push(<InputSimpleLink iMask="percentage" iRequired={[true, null].includes(this.state.baseTemplate.processingData.binphone.required) ? true : false} iType="s100x10" iTitle="Percent of payments by Phone" iName="binphone" iValue={this.state.template_data.processingData.binphone?.value || "0"} iValidator={this.props.global.getValidator().float} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Percent of payments by Phone" iName="binphone" iValue={this.state.template_data.processingData.binphone?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.binweb && this.state.baseTemplate.processingData.binweb !== null) {
            if (this.state.baseTemplate.processingData.binweb?.readOnly === false) {
                rows.push(<InputSimpleLink iMask="percentage" iRequired={[true, null].includes(this.state.baseTemplate.processingData.binweb.required) ? true : false} iType="s100x10" iTitle="Percent of payments online" iName="binweb" iValue={this.state.template_data.processingData.binweb?.value || "0"} iValidator={this.props.global.getValidator().float} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Percent of payments online" iName="binweb" iValue={this.state.template_data.processingData.binweb?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.avgmonthly && this.state.baseTemplate.processingData.avgmonthly !== null) {
            if (this.state.baseTemplate.processingData.avgmonthly?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.avgmonthly.required) ? true : false} iMask="money" iTooltip="How much credit card volume do you anticipate" iTitle="Average Monthly Volume" iName="avgmonthly" iValue={this.state.template_data.processingData.avgmonthly?.value} iValidator={this.props.global.getValidator().moneynonzero} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="money" iTooltip="How much credit card volume do you anticipate" iTitle="Average Monthly Volume" iName="avgmonthly" iValue={this.state.template_data.processingData.avgmonthly?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.ticketamt && this.state.baseTemplate.processingData.ticketamt !== null) {
            if (this.state.baseTemplate.processingData.ticketamt?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.ticketamt.required) ? true : false} iMask="money" iTooltip="What is the average transaction size that you charge your customers" iTitle="Average Ticket Amount" iName="ticketamt" iValue={this.state.template_data.processingData.ticketamt?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="money" iTooltip="What is the average transaction size that you charge your customers" iTitle="Average Ticket Amount" iName="ticketamt" iValue={this.state.template_data.processingData.ticketamt?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.highticketamt && this.state.baseTemplate.processingData.highticketamt !== null) {
            if (this.state.baseTemplate.processingData.highticketamt?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.highticketamt.required) ? true : false} iMask="money" iTooltip="What is the maximum transaction size you charge your customers?" iTitle="High Ticket Amount" iName="highticketamt" iValue={this.state.template_data.processingData.highticketamt?.value} iValidator={this.props.global.getValidator().moneynonzero} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="money" iTooltip="What is the maximum transaction size you charge your customers?" iTitle="High Ticket Amount" iName="highticketamt" iValue={this.state.template_data.processingData.highticketamt?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.annualRevenue && this.state.baseTemplate.processingData.annualRevenue !== null) {
            if (this.state.baseTemplate.processingData.annualRevenue?.readOnly === false) {
                rows.push(<InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.annualRevenue.required) ? true : false} iMask="money" iTooltip="What is the annual revenue volumen?" iTitle="Annual Revenue Volume" iName="annualRevenue" iValue={this.state.template_data.processingData.annualRevenue?.value} iValidator={this.props.global.getValidator().moneynonzero} />);
            } else {
                rows.push(<ReadOnlySimpleLink iMask="money" iTooltip="What is the annual revenue volumen?" iTitle="High Ticket Amount" iName="annualRevenue" iValue={this.state.template_data.processingData.annualRevenue?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.whenCharged && this.state.baseTemplate.processingData.whenCharged !== null) {
            if (this.state.baseTemplate.processingData.whenCharged?.readOnly === false) {
                rows.push(<InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenCharged.required) ? true : false} iType="whencharged" iTitle="When is Payment Charged" iName="whenCharged" iValue={this.state.template_data.processingData.whenCharged?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="When is Payment Charged" iName="whenCharged" iValue={this.state.template_data.processingData.whenCharged?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.whenProvided && this.state.baseTemplate.processingData.whenProvided !== null) {
            if (this.state.baseTemplate.processingData.whenProvided?.readOnly === false) {
                rows.push(<InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenProvided.required) ? true : false} iType="whenprovided" iTitle="Service Generally Provided In" iName="whenProvided" iValue={this.state.template_data.processingData.whenProvided?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Service Generally Provided In" iName="whenProvided" iValue={this.state.template_data.processingData.whenProvided?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.whenDelivered && this.state.baseTemplate.processingData.whenDelivered !== null) {
            if (this.state.baseTemplate.processingData.whenDelivered?.readOnly === false) {
                rows.push(<InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenDelivered.required) ? true : false} iType="whendelivered" iTitle="Products/Services Delivered In" iName="whenDelivered" iValue={this.state.template_data.processingData.whenDelivered?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Products/Services Delivered In" iName="whenDelivered" iValue={this.state.template_data.processingData.whenDelivered?.value} />);
            }
        }
        if (this.state.baseTemplate.processingData.whenRefunded && this.state.baseTemplate.processingData.whenRefunded !== null) {
            if (this.state.baseTemplate.processingData.whenRefunded?.readOnly === false) {
                rows.push(<InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.processingData.whenRefunded.required) ? true : false} iType="whenrefunded" iTitle="Refund Policy" iName="whenRefunded" iValue={this.state.template_data.processingData.whenRefunded?.value} iValidator={this.props.global.getValidator().text} />);
            } else {
                rows.push(<ReadOnlySimpleLink iTitle="Refund Policy" iName="whenRefunded" iValue={this.state.template_data.processingData.whenRefunded?.value} />);
            }
        }
        return (
            <Row className="mt-4">
                {rows}
            </Row>
        );
    }

    getCardAcceptance() {
        if (this.state.baseTemplate.servicesData.card?.cardAcceptance === null || this.state.baseTemplate.servicesData.card?.cardAcceptance.types === null) return;
        var rows = [];
        if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.visa && this.state.baseTemplate.servicesData.card?.cardAcceptance.types.visa !== null) {
            if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.visa?.readOnly === false) {
                rows.push(<CheckLink iInvisible={true} iTitle="Visa" iName="card_visa" iValue={this.state.template_data.servicesData.card?.cardAcceptance.types.visa?.visible} />);
            }
            else {
                rows.push(<ReadOnlyCheckLink iInvisible={true} iTitle="Visa" iName="card_visa" iValue={this.state.template_data.servicesData.card?.cardAcceptance.types.visa?.visible} />);
            }
        }
        if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.masterCard && this.state.baseTemplate.servicesData.card?.cardAcceptance.types.masterCard !== null) {
            if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.masterCard?.readOnly === false) {
                rows.push(<CheckLink iInvisible={true} iTitle="Mastercard" iName="card_mastercard" iValue={this.state.template_data.servicesData.card?.cardAcceptance.types.masterCard?.visible} />);
            }
            else {
                rows.push(<ReadOnlyCheckLink iInvisible={true} iTitle="Mastercard" iName="card_mastercard" iValue={this.state.template_data.servicesData.card?.cardAcceptance.types.masterCard?.visible} />);
            }
        }
        if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.discover && this.state.baseTemplate.servicesData.card?.cardAcceptance.types.discover !== null) {
            if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.discover?.readOnly === false) {
                rows.push(<CheckLink iInvisible={true} iTitle="Discover" iName="card_discover" iValue={this.state.template_data.servicesData.card?.cardAcceptance.types.discover?.visible} />);
            }
            else {
                rows.push(<ReadOnlyCheckLink iInvisible={true} iTitle="Discover" iName="card_discover" iValue={this.state.template_data.servicesData.card?.cardAcceptance.types.discover?.visible} />);
            }
        }
        if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.amex && this.state.baseTemplate.servicesData.card?.cardAcceptance.types.amex !== null) {
            if (this.state.baseTemplate.servicesData.card?.cardAcceptance.types.amex?.readOnly === false) {
                rows.push(<CheckLink iInvisible={true} iTitle="Amex" iName="card_amex" iValue={this.state.template_data.servicesData.card?.cardAcceptance.types.amex?.visible} />);
            }
            else {
                rows.push(<ReadOnlyCheckLink iInvisible={true} iTitle="Amex" iName="card_amex" iValue={this.state.baseTemplate.servicesData.card?.cardAcceptance.types.amex?.visible} />);
            }
        }
        return (
            <Row className="mt-2 mb-3">
                <h6 className="mb-2">Card Acceptance</h6><br />
                <label className="mb-2 small">Integrated per transaction fee for card acceptance.</label><br />
                {rows}
            </Row>
        );
    }

    capitalize([first, ...rest]) {
        return first.toUpperCase() + rest.join('').toLowerCase();
    }

    getCardPricing() {
        if (this.state.template_data.servicesData.card?.pricingType === null || !this.state.template_data.servicesData.card?.pricingType?.value) return;
        if(this.state.template_data.servicesData.card?.pricingType.value === "1"){
            return (
                <>
                    {/* <strong>IC+ Card Price</strong> */}
                    {this.getCardPricingICPlus()}
                </>
            )
        }
        else if(this.state.template_data.servicesData.card?.pricingType.value === "2"){
            return (
                <>
                    {/* <strong>Flat Card Price</strong> */}
                    {this.getCardPricingFlat()}
                </>
                )
        }
        else if(this.state.template_data.servicesData.card?.pricingType.value === "3"){
            return (
                <>
                    {/* <strong>Pass-Through Card Price</strong> */}
                    {this.getCardPricingPassThrought()}
                </>
            )
        }
        else if(this.state.template_data.servicesData.card?.pricingType.value === "4"){
            return (
                <>
                {/* <strong>Flat Card Price</strong> */}
                {this.getCardPricingFlat()}
                {/* <strong className="mt-3">Pass-Through Card Price</strong> */}
                {this.getCardPricingPassThrought()}
                </>
            )
        }
    }

    getAchPricing() {
        if (this.state.template_data.servicesData.ach?.pricingType === null || !this.state.template_data.servicesData.ach?.pricingType?.value) return;
        if(this.state.template_data.servicesData.ach?.pricingType.value === "5"){
            return (
                <>
                    {/* <strong>Absorb ACH Price</strong> */}
                    {this.getAchPricingAbsorb()}
                </>
            )
        }
        else if(this.state.template_data.servicesData.ach?.pricingType.value === "3"){
            return (
                <>
                    {/* <strong>Pass-Through ACH Price</strong> */}
                    {this.getAchPricingPassThrough()}
                </>
                )
        }
        else if(this.state.template_data.servicesData.ach?.pricingType.value === "6"){
            return (
                <>
                {/* <strong>Absorb ACH Price</strong> */}
                {this.getAchPricingAbsorb()}
                <strong className="mt-3">Pass-Through ACH Price</strong>
                {this.getAchPricingPassThrough()}
                </>
            )
        }
    }

    getFeeName(code) {
        const names = {
            "transactionCardFee": "per Transaction",	
            "chargebackCardFee": "per Chargeback",	
            "achBatchCardFee": "ACH Batch",	
            "avsCardFee": "per AVS",	
            "montlyPlatformCardFee": "Monthly per Platform",	
            "annualCardFee": "Annual Fee",	
            "minimumProcessingCardFee": "Minimum Processing",	
            "ddaRejectsCardFee": "per DDA Reject",	
            "retrievalCardFee": "Retrieval Fee",	
            "monthlyPCICardFee": "Monthly PCI Fee",	
            "earlyTerminationCardFee": "Early Termination Fe",
            "returnedAchFee": "per Returned",	
            "chargebackAchFee": "per Chargeback",	
            "monthlyAchFee": "Verify Bank",	
            "annualAchFee": "Verify Negative",	
            "verifyBankAchFee": "Verify Fund",	
            "verifyNegativeAchFee": "Monthly per Platform",	
            "verifyFundAchFee": "Annual Fee",	
            "sundayOriginationAchFee"	: "Sunday Origination",
            "sameDayAchFee": "Same Day",	
            "advancedSettlementAchFee": "Advanced Settlement",	
            "quarterlyPCIAchFee": "Quarterly PCI Fee",	
            "earlyTerminationAchFee": "Early Termination Fee",
        }
        return names[code]
    }

    getTable(tiers, type, cardOrAch){
        const acceptance = cardOrAch === "Card" ? this.state.template_data.servicesData.card?.cardAcceptance.types : this.state.template_data.servicesData.ach?.achAcceptance.types
        let acceptanceVisible = Object.entries(acceptance).filter(e => e[1]?.visible === true).map(e => e[0])
        let visibleRanges = false
        if(cardOrAch === "ACH"){
            acceptanceVisible = ["web"]
        }
        return (
            <Table className="table-striped">
                    <thead>
                        {
                            type === "through" && 
                            <>
                                <tr key={1}>
                                    <th key={0}>{cardOrAch}</th>
                                    <th key={1}>% OneTime</th>
                                    <th key={2}>$ OneTime</th>
                                    <th key={3}>% Recurring</th>
                                    <th key={4}>$ Recurring</th>
                                    {visibleRanges &&
                                        <>
                                            <th key={5}>Low pay range</th>
                                            <th key={6}>High pay range</th>
                                        </>
                                    }
                                </tr>
                            </>
                        }
                        {
                            type !== "through" && 
                            <>
                                <tr key={1}>
                                    <th key={0}>{cardOrAch}</th>
                                    <th key={1}>% per Auth</th>
                                    <th key={2}>$ per Auth</th>
                                    {visibleRanges &&
                                        <>
                                            <th key={5}>Low pay range</th>
                                            <th key={6}>High pay range</th>
                                        </>
                                    }
                                </tr>
                            </>
                        }
                    </thead>                
                
                <tbody>
                    {tiers.map((e, i) => {
                        return (<>
                            {Object.entries(e).filter(e => acceptanceVisible.includes(e[0])).map((k, j) => {
                                    return (
                                        <tr key={j}>
                                            <td key={0}>
                                            <div className="card-brands ">
                                                <div>
                                                    <img alt="" style={k[0] === "web" ? {width: '44px', height: "100%"} : k[0] === "amex" || k[0] === "masterCard" ? {height: "28px"} : null} className={k[0] === "web" ? "grey-icon-v2" : k[0] === "masterCard" ? "mastercard" : k[0] === "discover" ? "discover" : "amex" } src={k[0] === "web" ? achIcon : k[0] === "visa" ? visa : k[0] === "masterCard" ? mastercard : k[0] === "discover" ? discover : amex } />

                                                </div>
                                            </div>
                                            </td>
                                            {type === 'through' &&
                                                <>
                                                    <td key={1}>
                                                        {k[1].percentFeeOneTime}%
                                                    </td>
                                                    <td key={2}>
                                                        $ {parseFloat(k[1].amountFeeOneTime).toFixed(2)}
                                                    </td>
                                                    <td key={3}>
                                                        {k[1].percentFeeRecurring}%
                                                    </td>
                                                    <td key={4}>
                                                        $ {parseFloat(k[1].amountFeeRecurring).toFixed(2)}
                                                    </td>
                                                    {visibleRanges &&
                                                        <>
                                                            <td key={5}>
                                                                {k[1].lowPayRange}
                                                            </td>
                                                            <td key={6}>
                                                                {k[1].highPayRange}
                                                            </td>
                                                        </>
                                                    }
                                            
                                                </>
                                            }
                                            {type !== 'through' &&
                                                <>
                                                    <td key={1}>
                                                        {parseFloat(k[1].percentxAuth).toFixed(2)}%
                                                    </td>
                                                    <td key={2}>
                                                        $ {parseFloat(k[1].amountxAuth).toFixed(2)}
                                                    </td>
                                                    {visibleRanges &&
                                                        <>
                                                            <td key={5}>
                                                                {k[1].lowPayRange}
                                                            </td>
                                                            <td key={6}>
                                                                {k[1].highPayRange}
                                                            </td>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </tr>
                                    )
                            })}
                        </>)
                    })}
                </tbody>
            </Table>
        )

    }

    getAchPricingAbsorb() {
        const {tiers} = this.state.template_data.servicesData.ach?.achAbsorb;
        return this.getTable(tiers, "absorb", "ACH")
    }

    getAchPricingPassThrough() {
        const {tiers} = this.state.template_data.servicesData.ach?.achPassThrough;
        return this.getTable(tiers, "through", "ACH")
    }

    getCardPricingICPlus() {
        const {tiers} = this.state.template_data.servicesData.card?.cardICP;
        return this.getTable(tiers, "icp", "Card")
    }

    getCardPricingFlat() {
        const {tiers} = this.state.template_data.servicesData.card?.cardFlat;
        return this.getTable(tiers, "flat", "Card")
    }

    getCardPricingPassThrought() {
        const {tiers} = this.state.template_data.servicesData.card?.cardPassThrough;
        return this.getTable(tiers, "through", "Card")
    }

    getCardFee() {
        if (this.state.baseTemplate.servicesData.card?.cardFees === null || this.state.baseTemplate.servicesData.card?.cardFees?.visible === false) return;
        const cardFees = this.state.template_data.servicesData.card?.cardFees;
        if(cardFees && cardFees?.visible === false) return
        return <Table className="table-striped">
            {
                <thead>
                    <tr key={1}>
                        <th key={0}>Fee</th>
                        <th key={1}>Amount</th>
                    </tr>
                </thead>}
            {
                <tbody>
                    {Object.entries(cardFees).map((k, j) => {
                        if(k[1]?.visible){
                        return (
                            <>
                                <tr key={j}>
                                    <td key={0} style={{width: '79%'}}>
                                        {this.getFeeName(k[0])}
                                    </td>
                                    <td key={1}>
                                        $ {parseFloat(k[1]?.value || 0).toFixed(2)}
                                    </td>
                                </tr>
                            </>
                        )}
                        else{
                            return <></>
                        }
                    })}
                </tbody>
            }
        </Table>
    }

    getAchFee() {
        if (this.state.baseTemplate.servicesData.ach?.achFees === null) return;
        const achFees = this.state.template_data.servicesData.ach?.achFees;
        if(achFees && achFees?.visible === false) return
        return <Table className="table-striped">
            {
                <thead>
                    <tr key={1}>
                        <th key={0}>Fee</th>
                        <th key={1}>Amount</th>
                    </tr>
                </thead>}
            {
                <tbody>
                    {Object.entries(achFees).map((k, j) => {
                        if(k[1]?.visible){
                        return (
                            <>
                                <tr key={j}>
                                    <td key={0} style={{width: '65%'}}>
                                        {this.getFeeName(k[0])}
                                    </td>
                                    <td key={1}>
                                        $ {parseFloat(k[1]?.value || 0).toFixed(2)}
                                    </td>
                                </tr>
                            </>
                        )}
                        else{
                            return <></>
                        }
                    })}
                </tbody>
            }
        </Table>
    }

    getAchAcceptance() {
        if (this.state.baseTemplate.servicesData.ach === null || this.state.baseTemplate.servicesData.ach?.achAcceptance.types === null) return;
        var rows = [];
        if (this.state.baseTemplate.servicesData.ach?.achAcceptance.types.web) {
            if (this.state.baseTemplate.servicesData.ach?.achAcceptance.types.web?.readOnly === false) {
                rows.push(<CheckLink iInvisible={true} iTitle="Web" iName="ach_web" iValue={this.state.template_data.servicesData.ach?.achAcceptance.types.web?.visible} />);
            }
            else {
                rows.push(<ReadOnlyCheckLink iInvisible={true} iTitle="Web" iName="ach_web" iValue={this.state.template_data.servicesData.ach?.achAcceptance.types.web?.visible} />);
            }
        }
        if (this.state.baseTemplate.servicesData.ach?.achAcceptance.types.ppd) {
            if (this.state.baseTemplate.servicesData.ach?.achAcceptance.types.ppd?.readOnly === false) {
                rows.push(<CheckLink iInvisible={true} iTitle="PPD" iName="ach_ppd" iValue={this.state.template_data.servicesData.ach?.achAcceptance.types.ppd?.visible} />);
            }
            else {
                rows.push(<ReadOnlyCheckLink iInvisible={true} iTitle="PPD" iName="ach_ppd" iValue={this.state.template_data.servicesData.ach?.achAcceptance.types.ppd?.visible} />);
            }
        }
        if (this.state.baseTemplate.servicesData.ach?.achAcceptance.types.ccd) {
            if (this.state.baseTemplate.servicesData.ach?.achAcceptance.types.ccd?.readOnly === false) {
                rows.push(<CheckLink iInvisible={true} iTitle="CCD" iName="ach_ccd" iValue={this.state.template_data.servicesData.ach?.achAcceptance.types.ccd?.visible} />);
            }
            else {
                rows.push(<ReadOnlyCheckLink iInvisible={true} iTitle="CCD" iName="ach_ccd" iValue={this.state.template_data.servicesData.ach?.achAcceptance.types.ccd?.visible} />);
            }
        }
        return (
            <Row className="mt-2 mb-3">
                <h6 className="mb-2">ACH Acceptance</h6><br />
                <label className="mb-2 small">Integrated per transaction fee for ACH debits and credits.</label><br />
                {rows}
            </Row>
        );
    }

    selectContactAsSigner(event){
        let signer = this.state.signer;
        if(event.target?.value!==''){
            let obj = JSON.parse(event.target?.value);
            signer.name = obj.contactName;
            signer.email = obj.contactEmail;
            signer.phone = obj.contactPhone;
            signer.ssn = obj.contactSSN ? obj.contactSSN : '';
            signer.dob = obj.contactDOB ? obj.contactDOB : '';
            signer.country = obj.contactCountry ? obj.contactCountry : '';
            signer.city = obj.contactCity ? obj.contactCity : '';
            signer.state = obj.contactState ? obj.contactState : '';
            signer.address = obj.contactAddress ? obj.contactAddress : '';
            signer.address1 = obj.contactAddress2 ? obj.contactAddress2 : '';
            signer.zip = obj.contactZip ? obj.contactZip : '';
            
        }
        else{
            signer.name = ''
            signer.email = ''
            signer.phone = ''
            signer.ssn = null
            signer.dob = ''
            signer.country = ''
            signer.city = ''
            signer.state = ''
            signer.address = ''
            signer.address1 = ''
            signer.zip = ''
        }
        this.setState({
            signer:signer,
            signer_dob: signer.dob,
            signer_address: signer.address,
            signer_address2: signer.address,
            signer_city: signer.city,
            signer_state: signer.state,
            signer_zip: signer.zip,
            signer_country: signer.country,
       });
    }
    onCopyBankInfo(e){
        this.setState({
            bankName: this.props.boarding.template_data.deposit_bankName?.value,
            routingAccount: this.props.boarding.template_data.deposit_routingAccount?.value,
            accountNumber: this.props.boarding.template_data.deposit_accountNumber?.value,
            accountType: this.props.boarding.template_data.deposit_typeAccount?.value,
        })
    }

    closefileExceededSizel() {
        this.setState({ fileExceededSize: false });
    }

    render() {
        const infoTheme = {
            fontSize: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        };
        return (
            <Layout {...this.props}>
                <Modal style={{ textAlign: "center" }} show={this.state.fileExceededSize} onHide={this.closefileExceededSizel} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiX className="icon-modal" />
                        <h5>File limit exceeded!</h5>
                        <p>It seems like you’ve exceeded the maximum file size allowed. Upload max is 30MB of any combination of .pdf .jpg. or .png files. We apologize for any inconvenience.</p>
                        
                        <p>If you need to submit larger files, please email us.</p>
                        <Button className="btn mt-2" variant="outline-dark" onClick={(e) => this.closefileExceededSizel()}>
                            Ok
                        </Button>
                    </Modal.Body>
                </Modal>
                    <div>
                        <TopBar>
                            <MainTopBarMenu />
                            <div className="top-bar-sub">
                                <BoardingLinks gobackText="Application list" selected="applications" goback="true" gobackHref={this.state.linkId > 0 ? ("/" + this.props.global.getURLEntry() + "/boarding/templates") : ("/" + this.props.global.getURLEntry() + "/boarding/applications")} />
                            </div>
                        </TopBar>
                        <div className="mt-body4">

                                <div className="application-form" style={{marginTop: 0}}>
                                    <div className="application-tabs" style={{padding: 0}}>

                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 className="mt-2" data-qaid="newApplicationPage">New Application</h5>
                                                <p className="small-grey">Manage Organizations Applications</p>
                                            </div>
                                           
                                        </div>
                                        
                                    </div>


                                        <div className="progress mb-2">
                                            <div className="progress-bar" role="progressbar" style={{width:(this.getProgressBarPercent())+"%", backgroundColor:this.getProgressBarColor()}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.getProgressBarPercent()}%</div>
                                        </div>
                                        {this.state.baseTemplate &&
                                        <Tabs activeKey={this.state.currentSection} onSelect={this.setTab}>
                                        {(this.state.baseTemplate && this.state.baseTemplate.businessData !== null) &&
                                            <Tab eventKey="business" title={
                                            <div className={(this.state.validTabs[0] === false) ? "":"green-text"} >
                                                <BiDetail className="icon" /><br /><span>Business Details</span>
                                            </div>
                                            }>
                                                <h5>Business Details</h5>
                                                <p className="small mb-4 dark-grey">You have an awesome business, tell us a little bit about it! </p>
                                                {this.getBusinessBlock()}
                                                {this.getBusinessAddressBlock()}
                                            </Tab>
                                        }
                                        {(this.state.baseTemplate && this.state.baseTemplate.ownershipData !== null) &&
                                            <Tab eventKey="owners" title={
                                                <div className={(this.state.validTabs[1] === false) ? "":"green-text"} >
                                                    <BiUserCheck className="icon" /><br /><span>Ownership and Contacts</span>
                                                </div>
                                                }>
                                                <h5>Ownership and Contacts</h5>
                                                <p className="small mb-4 dark-grey">Federal Law requires us to collect some information on the owners, the business, and whoever will be signing the Merchant Agreement.</p>
                                                <Row className="mt-4">
                                                    {this.getOwnerSubBlock(0)}
                                                </Row>
                                                {this.state.additionalOwners.length > 0 &&
                                                    this.state.additionalOwners.map((k) => (
                                                        <Row className="mt-2" key={k}>
                                                            {k === this.state.additionalOwners.length && 
                                                            <>
                                                                <div className="col-12 text-right">
                                                                    <button className="btn btn-danger mb-1" onClick={(e) => this.removeOwner(k)}><BiTrash style={{ fontSize: '14px' }} /></button>
                                                                </div>
                                                                <br/>
                                                            </>
                                                            }
                                                            {this.getOwnerSubBlock(k)}
                                                        </Row>
                                                    ))}
                                                {this.state.baseTemplate.ownershipData.multipleOwners !== false &&
                                                    <div id="addOwner">
                                                        <button className="btn full-w bordered no-margin mb-4" onClick={(e) => this.addOwner()}>Add additional Owner with 25% or more of Ownership</button>
                                                    </div>
                                                }
                                                {this.getContactBlock(0)}
                                                {this.state.additionalContacts.length > 0 &&
                                                    this.state.additionalContacts.map((k) => (
                                                        <Row key={k}>
                                                            <div className="col-12 text-right">
                                                                <button className="btn btn-danger mb-1" onClick={(e) => this.removeContact(k)}><BiTrash style={{ fontSize: '14px' }} /></button>
                                                            </div>
                                                            {this.state.baseTemplate.ownershipData.contactName &&
                                                                this.state.baseTemplate.ownershipData.contactName?.readOnly === false ?
                                                                <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactName.required) ? true : false} iTitle="Contact Name" iName={"contactName_" + k} iValue={this.state.template_data.ownershipData.contactName?.value} iValidator={this.props.global.getValidator().text} />
                                                                :
                                                                <ReadOnlySimpleLink iTitle="Contact Name" iName={"contactName_" + k} iValue={this.state.template_data.ownershipData.contactName?.value} />}
                                                            {this.state.baseTemplate.ownershipData.contactTitle &&
                                                                this.state.baseTemplate.ownershipData.contactTitle?.readOnly === false ?
                                                                <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactTitle.required) ? true : false} iTitle="Contact Title" iName={"contactTitle_" + k} iValue={this.state.template_data.ownershipData.contactTitle?.value} iValidator={this.props.global.getValidator().text} />
                                                                :
                                                                <ReadOnlySimpleLink iTitle="Contact Title" iName={"contactTitle_" + k} iValue={this.state.template_data.ownershipData.contactTitle?.value} />}
                                                            {this.state.baseTemplate.ownershipData.contactEmail &&
                                                                this.state.baseTemplate.ownershipData.contactEmail?.readOnly === false ?
                                                                <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactEmail.required) ? true : false} iTitle="Contact Email" iName={"contactEmail_" + k} iValue={this.state.template_data.ownershipData.contactEmail?.value} iValidator={this.props.global.getValidator().email} />
                                                                :
                                                                <ReadOnlySimpleLink iTitle="Contact Email" iName={"contactEmail_" + k} iValue={this.state.template_data.ownershipData.contactEmail?.value} />}
                                                            {this.state.baseTemplate.ownershipData.contactPhone &&
                                                                this.state.baseTemplate.ownershipData.contactPhone?.readOnly === false ?
                                                                <PhoneInput iRequired={[true, null].includes(this.state.baseTemplate.ownershipData.contactPhone.required) ? true : false} iMask="phone" iTitle="Contact Phone" iName={"contactPhone_" + k} iValue={this.state.template_data.ownershipData.contactPhone?.value} iValidator={this.props.global.getValidator().phone} />
                                                                :
                                                                <ReadOnlySimpleLink iMask="phone" iTitle="Contact Phone" iName={"contactPhone_" + k} iValue={this.state.template_data.ownershipData.contactPhone?.value} />}
                                                        </Row>
                                                    ))}
                                                {this.state.baseTemplate.ownershipData.multipleContacts !== false &&
                                                    <div id="addContact">
                                                        <button className="btn full-w bordered no-margin mb-4" onClick={(e) => this.addContact()}>Add Additional Contact</button>
                                                    </div>}
                                            </Tab>
                                        }
                                        {(this.state.baseTemplate && this.state.baseTemplate.processingData !== null) &&
                                            <Tab eventKey="processing" title={
                                                <div className={(this.state.validTabs[2] === false) ? "":"green-text"} >
                                                    <BiCog className="icon" /><br /><span>Processing Information</span>
                                                </div>
                                            }>
                                                <h5>Processing Information</h5>
                                                <p className="small mb-4 dark-grey">We are so proud to power your payment processing, share with us your needs.</p>
                                                {this.getProcessingBlock()}
                                            </Tab>
                                        }
                                        {(this.state.baseTemplate && this.state.baseTemplate.servicesData !== null) &&
                                            <Tab eventKey="services" title={
                                                <div className={(this.state.validTabs[3] === false) ? "":"green-text"} >
                                                    <BiDollarCircle className="icon" /><br /><span>Services and Pricing</span>
                                                </div>
                                                }>
                                                <h5>Services and Pricing</h5>
                                                <p className="small mb-4 dark-grey">Below you will find the rates associated with your Payment Processing Account.</p>
                                                {this.getCardAcceptance()}
                                                <h6 className="mb-4">Setup</h6>
                                                <Row className="mt-4">
                                                        <div className="col-md-6">
                                                            <InputSelectTemplate iDisabled="true" iNoToggle={true} iType="gateway_card" iTitle="Gateway" iVisible={true} iReadOnly={true} iName="card_gateway" iValue={this.state.template_data.servicesData.card.gateway?.value} />
                                                            <InputSelectTemplate iDisabled="true" iNoToggle={true} iType="processor_card" iTitle="Processor" iVisible={true} iReadOnly={true} iName="card_processor" iPlaceholder="" iValue={this.state.template_data.servicesData.card.processor?.value} />
                                                            <InputSelectTemplate iDisabled="true" iNoToggle={true} iType="provider" iTitle="Provider" iVisible={true} iReadOnly={true} iName="card_provider" iPlaceholder="" iValue={this.state.template_data.servicesData.card.provider?.value} />
                                                            <ReadOnlySimpleLink iTitle="Tier Name" iVisible={true} iReadOnly={true} iName="card_tierName" iPlaceholder="" iValue={this.state.template_data.servicesData.card.tierName?.value} />
                                                            <ReadOnlySimpleLink iTitle="PDF Template ID" iVisible={true} iReadOnly={true} iName="card_pdfTemplateId" iPlaceholder="" iValue={this.state.template_data.servicesData.card.pdfTemplateId?.value} />
                                                        </div>
                                                        <div className="col-md-6">
                                                                <InputSelectLink iDisabled="true" iType="discount-freq" iTitle="Discount Frequency" iVisible={this.state.baseTemplate.servicesData.card?.discountFrequency?.visible} iReadOnly={this.state.baseTemplate.servicesData.card?.discountFrequency?.readOnly} iName="card_discountFrequency" iPlaceholder="" iValue={this.state.template_data.servicesData.card?.discountFrequency?.value} />
                                                                <InputSelectLink iDisabled="true" iType="funding-type" iTitle="Funding RollUp" iVisible={this.state.baseTemplate.servicesData.card?.fundingRollup?.visible} iReadOnly={this.state.baseTemplate.servicesData.card?.fundingRollup?.readOnly} iName="card_fundingRollup" iValue={this.state.template_data.servicesData.card?.fundingRollup?.value} />
                                                                {/* <ReadOnlySimpleLink iTitle="Pass-Through Cost" iRequired={[true, null].includes(this.state.baseTemplate.servicesData.card?.passThroughCost.required) ? true : false} iVisible={this.state.baseTemplate.servicesData.card?.passThroughCost?.visible} iReadOnly={this.state.baseTemplate.servicesData.card?.passThroughCost?.readOnly} iName="card_passThroughCost" iValue={this.state.template_data.servicesData.card?.passThroughCost?.value} /> */}
                                                        </div>
                                                </Row>
                                                {this.state.baseTemplate.servicesData.card?.cardAcceptance !== null &&
                                                    <Row className="mt-4">
                                                        <CheckLink iInvisible={true} iTitle="Accept Card Processing Services" iName="card" iValue={true} />
                                                    </Row>}
                                                <h6 className=" mb-4">Pricing</h6>
                                                <Row className="mt-4">
                                                        <div className="col-md-6">
                                                        <InputSelectLink iDisabled="true" iType="card-pricing" iTitle="Pricing Type" iVisible={this.state.baseTemplate.servicesData.card?.pricingType?.visible} iReadOnly={this.state.baseTemplate.servicesData.card?.pricingType?.readOnly} iName="card_pricingType" iValue={this.state.template_data.servicesData.card?.pricingType?.value} />
                                                        </div>
                                                </Row>
                                                {this.getCardPricing()}
                                                {this.getCardFee()}
                                                <br/>
                                                {this.getAchAcceptance()}
                                                <h6 className="mb-4">Setup</h6>
                                                <Row className="mt-4">
                                                        <div className="col-md-6">
                                                            <InputSelectTemplate iDisabled="true" iNoToggle={true} iType="gateway_ach" iTitle="Gateway" iVisible={true} iReadOnly={true} iName="ach_gateway" iValue={this.state.template_data.servicesData.ach.gateway?.value} />
                                                            <InputSelectTemplate iDisabled="true" iNoToggle={true} iType="processor_ach" iTitle="Processor" iVisible={true} iReadOnly={true} iName="ach_processor" iPlaceholder="" iValue={this.state.template_data.servicesData.ach.processor?.value} />
                                                            <InputSelectTemplate iDisabled="true" iNoToggle={true} iType="provider" iTitle="Provider" iVisible={true} iReadOnly={true} iName="ach_provider" iPlaceholder="" iValue={this.state.template_data.servicesData.ach.provider?.value} />
                                                            <ReadOnlySimpleLink iTitle="Tier Name" iVisible={true} iReadOnly={true} iName="ach_tierName" iPlaceholder="" iValue={this.state.template_data.servicesData.ach.tierName?.value} />
                                                            <ReadOnlySimpleLink iTitle="PDF Template ID" iVisible={true} iReadOnly={true} iName="ach_pdfTemplateId" iPlaceholder="" iValue={this.state.template_data.servicesData.ach.pdfTemplateId?.value} />
                                                        </div>
                                                        <div className="col-md-6">
                                                                <InputSelectLink iDisabled="true" iType="discount-freq" iTitle="Discount Frequency" iVisible={this.state.baseTemplate.servicesData.ach?.discountFrequency?.visible} iReadOnly={this.state.baseTemplate.servicesData.ach?.discountFrequency?.readOnly} iName="ach_discountFrequency" iPlaceholder="" iValue={this.state.template_data.servicesData.ach?.discountFrequency?.value} />
                                                                <InputSelectLink iDisabled="true" iType="funding-type" iTitle="Funding RollUp" iVisible={this.state.baseTemplate.servicesData.ach?.fundingRollup?.visible} iReadOnly={this.state.baseTemplate.servicesData.ach?.fundingRollup?.readOnly} iName="ach_fundingRollup" iValue={this.state.template_data.servicesData.ach?.fundingRollup?.value} />
                                                        </div>
                                                </Row>
                                                {this.state.baseTemplate.servicesData.ach?.achAcceptance !== null &&
                                                    <Row className="mt-4">
                                                        <CheckLink iInvisible={true} iTitle="Accept ACH Processing Services" iName="ach" iValue={true} />
                                                    </Row>
                                                } 
                                                <h6 className=" mb-4">Pricing</h6>
                                                <Row className="mt-4">
                                                    <div className="col-md-6">
                                                            <InputSelectLink iDisabled="true" iType="ach-pricing" iTitle="Pricing Type" iVisible={this.state.baseTemplate.servicesData.ach?.pricingType?.visible} iReadOnly={this.state.baseTemplate.servicesData.ach?.pricingType?.readOnly} iName="ach_pricingType" iValue={this.state.template_data.servicesData.ach?.pricingType?.value} />
                                                    </div>
                                                </Row>
                                                {this.getAchPricing()}
                                                {this.getAchFee()}

                                            </Tab>
                                        }
                                                <Tab eventKey="banking" title={
                                                    <div className={(this.state.validTabs[4] === false) ? "":"green-text"} >
                                                        <RiBankLine className="icon"/><br/><span>Banking and Documents</span>
                                                    </div>
                                                    }>
                                                    <h5>Banking and Documents</h5>
                                                    <p className="small mb-4 dark-grey">We are almost done! Please provide us with some important supporting documents and where you want us to send your funds. </p>
                                                    {(this.state.baseTemplate.documentsData !== null && (this.state.baseTemplate.documentsData.depositBank !== null || this.state.baseTemplate.documentsData.withdrawalBank !== null)) &&
                                                        <Row className="mt-4">
                                                            {this.state.baseTemplate.documentsData.depositBank !== null &&
                                                                <div className="col-6">
                                                                    <p className="mb-3"><b>Deposit Account</b></p>
                                                                    {this.state.baseTemplate.documentsData.depositBank.bankName &&
                                                                        this.state.baseTemplate.documentsData.depositBank.bankName?.readOnly === false ?
                                                                        <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.bankName.required) ? true : false} iLink="true" iLinkInvisible="true" iTooltip="Bank Account you'd like us to send funds to"  iTitle="Bank Name" iName="deposit_bankName" iValue={this.state.template_data.documentsData.depositBank.bankName?.value} iValidator={this.props.global.getValidator().text} full />
                                                                        :
                                                                        <ReadOnlySimpleLink iTooltip="Bank Account you'd like us to send funds to"  iTitle="Bank Name" iName="deposit_bankName" iValue={this.state.template_data.documentsData.depositBank.bankName?.value} />}
                                                                    {this.state.baseTemplate.documentsData.depositBank.routingNumber &&
                                                                        this.state.baseTemplate.documentsData.depositBank.routingNumber?.readOnly === false ?
                                                                        <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.routingNumber.required) ? true : false} iMask="routing" iTitle="Routing Number" iName="deposit_routingAccount" iValue={this.state.template_data.documentsData.depositBank.routingNumber?.value} iValidator={this.props.global.getValidator().routing} full />
                                                                        :
                                                                        <ReadOnlySimpleLink iMask="routing" iTitle="Routing Number" iName="deposit_routingAccount" iValue={this.state.template_data.documentsData.depositBank.routingNumber?.value} />}
                                                                    {this.state.baseTemplate.documentsData.depositBank.accountNumber &&
                                                                        this.state.baseTemplate.documentsData.depositBank.accountNumber?.readOnly === false ?
                                                                        <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.accountNumber.required) ? true : false} iMask="accountnumber" iTitle="Account Number" iName="deposit_accountNumber" iValue={this.state.template_data.documentsData.depositBank.accountNumber?.value} iValidator={this.props.global.getValidator().numbers} full />
                                                                        :
                                                                        <ReadOnlySimpleLink iMask="accountnumber" iTitle="Account Number" iName="deposit_accountNumber" iValue={this.state.template_data.documentsData.depositBank.accountNumber?.value} />}
                                                                    {this.state.baseTemplate.documentsData.depositBank.accountType &&
                                                                        this.state.baseTemplate.documentsData.depositBank.accountType?.readOnly === false ?
                                                                        <InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.depositBank.accountType.required) ? true : false} iTitle="Account Type" iName="deposit_typeAccount" iValue={this.state.template_data.documentsData.depositBank.accountType?.value} iValidator={this.props.global.getValidator().text} iType="bank-type" full />
                                                                        :
                                                                        <ReadOnlySimpleLink iTitle="Account Type" iName="deposit_accountType" iValue={this.state.template_data.documentsData.depositBank.accountType?.value} />}
                                                                </div>}

                                                            {this.state.baseTemplate.documentsData.withdrawalBank !== null &&
                                                            
                                                                <div className="col-6">
                                                                     <p className="mb-3"><b>Withdrawal Account</b></p>
                                                                    {this.state.baseTemplate.documentsData.withdrawalBank.bankName &&
                                                                        this.state.baseTemplate.documentsData.withdrawalBank.bankName?.readOnly === false ?
                                                                        <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.bankName.required) ? true : false} iLink="true" iLinkClick={(e) => this.onCopyBankInfo(e)} iLinkText="Copy deposit account details" iTooltip="Bank Account you'd like us to take our fees from" iTitle="Bank Name" iName="withdrawal_bankName" iValue={this.state.bankName ? this.state.bankName : this.state.template_data.documentsData.withdrawalBank.bankName?.value} iValidator={this.props.global.getValidator().text} full />
                                                                        :
                                                                        <ReadOnlySimpleLink iTooltip="Bank Account you'd like us to take our fees from" iTitle="Bank Name" iName="withdrawal_bankName" iValue={this.state.template_data.documentsData.withdrawalBank.bankName?.value} />}
                                                                    {this.state.baseTemplate.documentsData.withdrawalBank.routingNumber &&
                                                                        this.state.baseTemplate.documentsData.withdrawalBank.routingNumber?.readOnly === false ?
                                                                        <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.routingNumber.required) ? true : false} iMask="routing" iTitle="Routing Number" iName="withdrawal_routingAccount" iValue={this.state.routingAccount ? this.state.routingAccount : this.state.template_data.documentsData.withdrawalBank.routingNumber?.value} iValidator={this.props.global.getValidator().routing} full />
                                                                        :
                                                                        <ReadOnlySimpleLink iMask="routing" iTitle="Routing Number" iName="withdrawal_routingAccount" iValue={this.state.template_data.documentsData.withdrawalBank.routingNumber?.value} />}
                                                                    {this.state.baseTemplate.documentsData.withdrawalBank.accountNumber &&
                                                                        this.state.baseTemplate.documentsData.withdrawalBank.accountNumber?.readOnly === false ?
                                                                        <InputSimpleLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.accountNumber.required) ? true : false} iMask="numbers" iTitle="Account Number" iName="withdrawal_accountNumber" iValue={this.state.accountNumber ? this.state.accountNumber : this.state.template_data.documentsData.withdrawalBank.accountNumber?.value} iValidator={this.props.global.getValidator().numbers} full />
                                                                        :
                                                                        <ReadOnlySimpleLink iMask="numbers" iTitle="Account Number" iName="withdrawal_accountNumber" iValue={this.state.template_data.documentsData.withdrawalBank.accountNumber?.value} />}
                                                                    {this.state.baseTemplate.documentsData.withdrawalBank.accountType &&
                                                                        this.state.baseTemplate.documentsData.withdrawalBank.accountType?.readOnly === false ?
                                                                        <InputSelectLink iRequired={[true, null].includes(this.state.baseTemplate.documentsData.withdrawalBank.accountType.required) ? true : false} iTitle="Account Type" iName="withdrawal_typeAccount" iValue={this.state.accountType ? this.state.accountType : this.state.template_data.documentsData.withdrawalBank.accountType?.value} iValidator={this.props.global.getValidator().text} iType="bank-type" full />
                                                                        :
                                                                        <ReadOnlySimpleLink iTitle="Account Type" iName="withdrawal_accountType" iValue={this.state.template_data.documentsData.withdrawalBank.accountType?.value} />}
                                                                </div>}
                                                        </Row>}
                                                    <br />
                                                    {(this.state.baseTemplate.documentsData.minimumDocuments !== null && parseInt(this.state.baseTemplate.documentsData.minimumDocuments, 10) > 0) &&
                                                        this.getDocumentBlock()}

                                                    {this.getSignerBlock()}
                                                    {this.state.template_data.documentsData.termsAndConditions && this.state.template_data.documentsData.termsAndConditions?.visible && this.state.template_data.documentsData.termsAndConditions.tcLinks.length &&
                                                        <>
                                                            <h6 className="mt-2 text-center">Confirmation & Attestation</h6>
                                                            <p className="small mb-4 dark-grey text-center">Please Review, and Print, or Save the documents provided in links below.</p>
                                                        </>
                                                    }
                                                    {this.state.template_data.documentsData.termsAndConditions && this.state.template_data.documentsData.termsAndConditions?.visible && this.state.template_data.documentsData.termsAndConditions.tcLinks.length &&
                                                        
                                                        <>
                                                            <Card className="card card-in mb-3">
                                                                <Card.Header>
                                                                    <div className="row">
                                                                        <div className="col-1" style={infoTheme}>
                                                                        {(this.state.signer.acceptance === true &&
                                                                    <BsFillExclamationCircleFill color="limegreen"/>) || <BsFillExclamationCircleFill color="red"/>}
                                                                        </div>
                                                                        <div className="col-11">
                                                                        <p>
                                                                            By clicking below I agree and hereby confirm to have Read, Agreed and Consented to the 
                                                                            {this.state.template_data.documentsData.termsAndConditions.tcLinks && this.state.template_data.documentsData.termsAndConditions.tcLinks.map((k, i) => (
                                                                                <>
                                                                            <span className="text-info"> {k['label']}</span> <a style={{textDecoration: 'none'}} className="text-info" rel="noreferrer" term={i} onClick={(e) => this.handleAcceptClick(e)} target="_blank" href={k['value']}>(CLICK HERE)</a>
                                                                            {i === this.state.template_data.documentsData.termsAndConditions.tcLinks.length - 2 ? ' and ' : ', '}
                                                                            </>
                                                                            ))}
                                                                            <span> provided. Please Review and Save these documents provided in links above.</span>
                                                                        </p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <div className='col-1' style={infoTheme}>
                                                                            <input style={{backgroundColor: '#10A0E3', borderColor: '#10A0E3', minHeight: '22px', marginTop: '6px!important', marginBottom: '6px!important', paddingLeft: 0, width: '22px', height: '22px'}} checked={this.state.signer.acceptance} type="checkbox" name="acceptance" id="acceptance" onClick={this.handleAcceptCheckClick} />
                                                                        </div>
                                                                        <div className='col-11'>
                                                                            <label style={{ fontSize: '12px' }} htmlFor="acceptance">I Agree to the above information & I attest that the information provided in this application is correct to the best of my knowledge as an authorized signer for this business.</label>
                                                                        </div>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </>
                                                    }
                                                </Tab>
                                            </Tabs>}
                                    </div>
                                    <hr className="mt-3 mb-4" />
                                    <div>
                                        <Row>
                                            <div className="col-4 text-left">
                                                {this.state.currentPos > 0 &&
                                                    <Button variant="btn btn-primary" onClick={(e) => this.gotoPrevTab(this.state.currentPos - 1)}><BiChevronLeft/> Previous</Button>}
                                            </div>
                                            <div className="col-4 text-center">
                                               
                                                <Button variant="btn btn-light" onClick={(e) => this.saveToComplete()}>Save to complete later</Button>
                                            
                                            </div>
                                           
                                            <div className="col-4 text-right">
                                                {this.state.currentPos < 4 ? 
                                                    <Button variant="btn btn-primary" onClick={(e) => this.gotoNextTab(this.state.currentPos + 1)}>Next <BiChevronRight/></Button>
                                                    :
                                                    <Button variant="btn btn-primary" onClick={(e) => this.gotoNextTabAndSubmit(this.state.currentPos + 1)}>Submit <BiChevronRight/></Button>
                                                }
                                            </div>
                                        </Row>
                                    </div>
                                    <ToastContainer transition={Bounce} />

                                </div>
                            </div>
                        </div>

            </Layout>
        );
    }
}
export { NewAppFromTpl };
