import React from 'react';
import { inject, observer } from 'mobx-react';
import visaImg from '../../../../../assets/images/visa.svg';
import mastercardImg from '../../../../../assets/images/mastercard.svg';
import amexImg from '../../../../../assets/images/amex.svg';
import discoverImg from '../../../../../assets/images/discover.svg';
import achIcon from '../../../../../assets/images/ach.svg';
import cardIcon from '../../../../../assets/images/card.svg';

@inject('store', 'global', 'invoice')
@observer
class PaymentMethod extends React.Component {
  componentDidMount() {}

  render() {
    const { paymentMethods } = this.props.invoice.advancedOptions;
    const { visa, mastercard, discover, amex, eCheck } = paymentMethods;

    const creditCardAllow = visa || mastercard || discover || amex;

    return (
      <div className="payment-method full-w">
        <h6 className="sub-header mb-3">Payment Methods</h6>

        {eCheck && (
          <div className="card card-in mb-3 card-hover card">
            <div className="card-body card-header">
              <div className="row">
                <div className="col-2 text-center">
                  <img alt="" style={{width: '36px', marginTop: '10px'}} className="grey-icon-v2" src={achIcon}/>
                </div>
                <div className="col-10">
                  Bank account / E-Check<p className="small small-grey-m0">Pay from your Bank Account</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {creditCardAllow && (
          <div className="card card-in mb-3 card-hover card">
            <div className="card-body card-header">
              <div className="row">
                <div className="col-2 text-center">
                  <img alt="" style={{width: '38px', marginTop: '8px'}} className="grey-icon-v2" src={cardIcon}/>
                </div>
                <div className="col-10">
                  Credit or Debit Card
                  <p className="small small-grey-m0">Use your Credit or Debit Card</p>
                  <div className="card-brands accordion-right-corner" style={{position: 'absolute'}}>
                    <div>
                      {visa && <img alt="" src={visaImg}/>}
                      {mastercard && <img alt="" className="mastercard" src={mastercardImg}/>}
                      {discover && <img alt="" className="discover" src={discoverImg} />}
                      {amex && <img alt="" className="amex" src={amexImg} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PaymentMethod;
