import React from 'react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager';
import {BiMessageError } from 'react-icons/bi';
import * as Sentry from "@sentry/react";

@inject('global','user')
@observer
class Sso extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            entry: null,
            uToken:null,
            error: null
        };
        this.validateEntryPoint = this.validateEntryPoint.bind(this);
    }

    validateEntryPoint(entry,encryptStorage){
        if (typeof entry === 'object') {
            Sentry.captureEvent(new Error(`validateEntryPoint SSO entry is ${JSON.stringify(entry)}`));
        }

        return axios.get(process.env.REACT_APP_URL_API+ 'organization/basic/'+entry,
        {
            headers: {'requestToken':process.env.REACT_APP_TOKEN}
        })
        .then(res => {
            let entryImg = res.data.responseData ? res.data.responseData.OrgLogo : '';
            encryptStorage.setItem(`${PayabliStorageManager.getEntryName()}_pImg`, entryImg);
            if(res.data.responseData)
            {
                encryptStorage.setItem(`${PayabliStorageManager.getEntryName()}_pEntry`, { pEntry: entry, legalName: res.data.responseData.OrgName, orgId: res.data.responseData.idOrg, isRoot: res.data.responseData.isRoot, OrgTimezone: res.data.responseData.orgTimezone });
            }
            return res;
        })
        .catch(error => {
            throw error;
        });
    }

    componentDidMount() {
        let entry = this.props.match.params.entryUrl;
        try{
            let token = PayabliCookieManager.readCookie(PayabliStorageManager.getEntryName()+'_pToken');
            PayabliCookieManager.eraseCookie(PayabliStorageManager.getEntryName()+'_pToken');
            let connectionErrorMessage = "Connection error, try again later.";


            if(entry && token){

                token = token.replace(/_/g, '/');
                PayabliStorageManager.setEncryptedLocalStorageKey(token);
                let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    
                encryptStorage.setItem('pToken', token);

                this.props.global.setLoading(true);
                this.validateEntryPoint(entry, encryptStorage).then(res => {
                    this.props.global.setLoading(false);
                    
                    axios.get(process.env.REACT_APP_URL_API+ 'User/0',
                    {
                        headers: {'requestToken':token}
                    })
                    .then(res => {
                        if(res.data.userId){
                            this.props.user.getUserFromApi(res.data.userId).then(pres => {
                    
                                if(pres && pres.Access){
                                    let permissionsArray = [];
                                    pres.Access.forEach(function (item) {
                                        if(item.roleValue === true){
                                            permissionsArray.push(item.roleLabel);
                                        }
                                    });
                                    
                                    // To refresh token 10 minutes before token expire
                                    let remaining = (new Date().getTime()) + ((120 - 10)*60000);
                                    
                                    encryptStorage.setItem('pUser', { 
                                        name: res.data.Name,
                                        id: res.data.userId, 
                                        email: res.data.email,
                                        remaining: remaining,
                                        permissions: permissionsArray,
                                        timeZone: res.data.TimeZone ? res.data.TimeZone : 0
                                    });
                                    this.props.global.setLoading(false);
                                    window.location.href = "/"+entry+"/dashboard";
                                }else{
                                    this.setState({error: "Connection error, try again later."});
                                }
                            })
                            .catch(error => {
                                this.setState({ error : "Your entry point is not correct"});
                                this.props.global.setLoading(false);
                                throw error;
                            });
                        }
                    })
                    .catch(error => {
                
                    });
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    console.log(error)
                    this.setState({error: connectionErrorMessage});
                });

            }
            else{
                console.log(connectionErrorMessage)
                this.props.global.setLoading(false);
                this.setState({error: connectionErrorMessage});
            }
        

           

            

        }catch(error){
            console.log(error)
            this.setState({error: error.message});
        }
        
    }


    render() {
        return (
            <>
            {this.state.error ?
                <div className="root-page">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "100%", position: "absolute", width: "100%"}}>
                    <div className="text-center" style={{width: '20em', backgroundColor: "#ffffff", padding: "30px 15px"}}>
                        <BiMessageError className='icon-modal'/>
                        <p className="small">
                            <b>Something is Wrong</b> 
                            <br/>
                            <br/>
                            <span className="small-small">{this.state.error ? this.state.error  : ""}.  <br/> Please try again later.</span>
                           
                        </p>
                    </div>
                </div>
                </div>
            :
            <>
            {this.props.global.isLoading &&
                    <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                       <div className="spinner-border" role="status">
                        </div>
                    </div>
                    }
                <div className="root-page text-center">
                        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100%", position: "absolute", width: "100%" }}>
                            <div></div>
                    <p className="small-small">Powered by  <a href="/" className="small no-underline">Payabli</a></p>
                </div>
                </div>
                </>
            }
            </>
            )
    }
}

export { Sso };