import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportPaginator } from '../../components/ReportPaginator';
import { Modal, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { Layout } from '../../components/Layout'
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import {BiNotepad} from '@react-icons/all-files/bi/BiNotepad';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiDollarCircle, BiTrash, BiDotsVerticalRounded, BiSortAlt2, BiQuestionMark } from 'react-icons/bi';
import { NameAvatar } from '../../components/NameAvatar';
import '../../assets/css/react-select-search.css';
import Fuse from 'fuse.js';
import { MoneyOutLinks } from '../../components/MoneyOutLinks';
import { AiOutlineCheckCircle, AiOutlineCloseCircle} from 'react-icons/ai';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { MainBar } from '../../components/MainBar';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'groupName', 'items.name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        return fuse.search(value);
    };
}

@inject('reports', 'global', 'vendor','user', 'bill')
@observer
class ApprovalsReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            head: "",
            billIdToDelete: null,
            sortDirection: "desc",
            billToPay: null,
            billIdToApprove: null,
            deleteModalIsOpen: false,
            payBillModalIsOpen: false,
            usersForApproval: [],
            approvalEmails: [],
            approveModalIsOpen: false,
            flagItemAllColumn: true,
            // 1 to approve, 0 to disaprove
            approveAction: null,
            localReportSummary: null,
            approvalModalIsOpen: false,
            approvalAction: '',
            approvalRecordId: null
        };
        this.getReportFromApi = this.getReportFromApi.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.deleteBill = this.deleteBill.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.deleteBillAction = this.deleteBillAction.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.exportFile = this.exportFile.bind(this);        
        this.filter = this.filter.bind(this);        
        this.handleQuickFilter = this.handleQuickFilter.bind(this);
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.viewVendor = this.viewVendor.bind(this);        
        this.payBill = this.payBill.bind(this);
        this.openPayBillModal = this.openPayBillModal.bind(this);
        this.closePayBillModal = this.closePayBillModal.bind(this);
        this.payBillAction = this.payBillAction.bind(this);        
        this.handleDeleteForApproval = this.handleDeleteForApproval.bind(this);
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.approveBill = this.approveBill.bind(this);
        this.disapproveBill = this.disapproveBill.bind(this);
        this.approveBillAction = this.approveBillAction.bind(this);        
        this.closeApproveModal = this.closeApproveModal.bind(this);
        this.openApproveModal = this.openApproveModal.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.getMainSummary = this.getMainSummary.bind(this);
        this.approval = this.approval.bind(this);
        this.openApprovalModal = this.openApprovalModal.bind(this);
        this.closeApprovalModal = this.closeApprovalModal.bind(this);
        this.approvalAction = this.approvalAction.bind(this);
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.props.reports.setHeaders(
            {
                Vendor                  : { label:'Vendor Name', class: '', display: true },
                VendorId                : { label:'Vendor ID', class: '', display: false},
                ParentOrgName           : { label: 'Organization', class: '', display: false },
                PaypointLegalname       : { label: 'Paypoint', class: '', display: true },
                PaypointDbaname         : { label:'DBA Name', class: '', display: false},
                CustomerVendorAccount   : { label:'Account #', class: '', display: true },
                VendorNumber            : { label:'Vendor #', class: '', display: false },
                Ein                     : { label:'Tax ID', class: '', display: false },
                Email                   : { label:'Email', class: '', display: false},
                Phone                   : { label:'Phone', class: '', display: false},
                BillingAddress          : { label:'Billing Address', class: '', display: false},
                RemitAddress            : { label:'Remit Address', class: '', display: false},
                BillNumber              : { label:'Bill #', class: '', display: true },
                LotNumber               : { label:'Lot #', class: '', display: true },
                PaymentGroup            : { label:'Payment Group', class: '', display: true},
                Time                    : { label:'Time (' +this.props.global.getTimeZone('v1')+")", class: '', display: false }, 
                Type                    : { label:'Type', class: '', display: true }, 
                Status                  : { label:'Bill Status', class: '', display: true },
                PaymentStatus           : { label:'Payment Status', class: '', display: true},
                TotalAmount             : { label:'Total Amount', class: 'text-right', display: true },
                TransId                 : { label:'Trans. ID', class: '', display: false },
                Source                  : { label:'Source', class: '', display: false},
                Notes                   : { label:'Notes', class: 'text-center', display: true},
                CreatedOn               : { label:'Created On', class: '', display: false},
                LastUpdatedOn           : { label:'Last Updated On', class: '', display: false},
                DueOn                   : { label:'Due On', class: '', display: true },
                CustomField1            : { label:'Accounting Field 1', class: '', display: false},
                CustomField2            : { label:'Accounting Field 2', class: '', display: false},
                //QuickActions            : { label:'Quick Actions', class: 'text-center', display: true},
            }
        )
        this.props.reports.setRecords([]);
        this.clearFilters();
        this.getMainSummary();
    }

    approval(status, IdBill){
        this.setState({
            approvalAction: status===true ? 1 : 0 ,
            approvalRecordId: IdBill ,
        }, function(){
            this.openApprovalModal();
        });
        
    }
    
    approvalAction(){
        this.props.global.setLoading(true);
        let approvalAction = this.state.approvalAction;
        this.props.bill.approveBill(this.state.approvalRecordId,approvalAction).then(res => {
            this.closeApprovalModal();
            this.props.global.setLoading(false);
            this.getReportFromApi();
            toast.success('Record '+ (approvalAction === 1 ? "approved" : "rejected") + ' successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
            });
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    openApprovalModal() {
        this.setState({ approvalModalIsOpen: true });
    }
    
    closeApprovalModal() {
        this.setState({ approvalModalIsOpen: false });
    }

    getMainSummary(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        this.props.reports.getReportSummaryFromApi('bills',encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId + ("?approvalUserId(eq)="+ encryptStorage.getItem('pUser').id)).then(res => {
            this.setState({localReportSummary : res});
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is wrong when trying to load summary!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    handleDeleteForApproval(e){
        let emailsArray = this.state.approvalEmails;
        emailsArray.forEach(function (item, index) {
            if(item === e){
                emailsArray.splice(index,1);
            }
        });
        this.setState({approvalEmails: emailsArray});
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'bills').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    viewVendor(id, object){
        object.preventDefault();
        this.props.history.push("/"+PayabliStorageManager.getEntryName()+"/vendor/"+id);
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    deleteBill(billIdToDelete) {
        this.setState({ billIdToDelete: billIdToDelete });
        this.openDeleteModal();
    }

    openDeleteModal() {
        this.setState({ deleteModalIsOpen: true });
    }
    
    closeDeleteModal() {
        this.setState({ deleteModalIsOpen: false });
    }

    closeApproveModal() {
        this.setState({ approveModalIsOpen: false });
    }
    
    openApproveModal() {
        this.setState({ approveModalIsOpen: true });
    }

    deleteBillAction() {
        let billIdToDelete = this.state.billIdToDelete;

        if (billIdToDelete) {
            this.props.global.setLoading(true);
            this.props.reports.deleteBill(billIdToDelete)
            .then((result) => {
                this.closeDeleteModal();
                this.props.global.setLoading(false);
                toast.success('Data deleted successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
            })
            .catch((error) => {
                this.props.global.setLoading(false);
                let errorMessage =
                error.response && error.response.data.responseText
                    ? error.response.data.responseText
                    : 'Something is Wrong!';
                toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
                });
            });
        }
    }

    payBill(bill){
        this.setState({billToPay:bill});
        this.openPayBillModal();
    }

    payBillAction(){
        this.props.global.setLoading(true);
        this.props.reports.payBillMoneyOut(this.state.billToPay).then(res => {
            this.getReportFromApi();
            toast.success("Bill paid successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
            this.closePayBillModal();
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
            this.props.global.setLoading(false);
        });
    }

    openPayBillModal() {
        this.setState({ payBillModalIsOpen: true });
    }
    
    closePayBillModal() {
        this.setState({ payBillModalIsOpen: false });
    }

    handleShowColumn(event) {
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }
   
    clearFilters(){
        this.setState({ head: ""}, () => {
            this.props.reports.clearFilters();
            this.getReportFromApi();
        });
    }

    clearFilter(e, filter, filterType){
        e.preventDefault();
        this.setState({ [filterType]: {} });
        this.filter(filter,"");
    }

    exportFile(format){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let idOrg = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId;
        if(idOrg && format){
            this.props.reports.exportOrgFile('bills',format,idOrg);
        }
    }

    viewRecordDetails(IdBill, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.props.history.push("/" + this.props.global.getURLEntry() + "/bills/view/"+IdBill + "?backTo=approvals");
        }
    }

    approveBill(e, IdBill){
        e.preventDefault();
        this.setState({
            billIdToApprove: IdBill,
            approveAction: 1,
            approveModalIsOpen: true
        })
    }

    disapproveBill(e, IdBill){
        e.preventDefault();
        this.setState({
            billIdToApprove: IdBill,
            approveAction: 0,
            approveModalIsOpen: true
        })
    }

    approveBillAction(){
        let billIdToApprove = this.state.billIdToApprove;
        let approveAction = this.state.approveAction;

        if (billIdToApprove && approveAction !== null) {
            this.props.global.setLoading(true);
            this.props.bill.approveBill(billIdToApprove, approveAction)
            .then((result) => {
                this.closeApproveModal();
                this.props.global.setLoading(false);
                this.getReportFromApi();
                toast.success('Data '+ (approveAction ? "approved":"disapproved") + ' successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success-container',
                });
            })
            .catch((error) => {
                this.props.global.setLoading(false);
                let errorMessage =
                error.response && error.response.data.responseText
                    ? error.response.data.responseText
                    : 'Something is Wrong!';
                toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
                });
            });
        }
    }
    getMappingFields(){
        return {
            'Vendor': 'vendor',
            'VendorId': 'vendorid',
            'ParentOrgName': 'orgname',
            'PaypointLegalname': 'paypointlegal',
            'ExternalPaypointID': 'externalpaypointid',
            'PaypointDbaname': 'paypointdba',
            'CustomerVendorAccount': 'vendoraccount',
            'VendorNumber': 'vendornumber',
            'Ein': 'ein',
            'Email': 'email',
            'Phone': 'phone',
            'BillingAddress': 'billaddress',
            'RemitAddress': 'remitaddress',
            'BillNumber': 'billnumber',
            'LotNumber': 'lotnumber',
            'PaymentGroup': 'paymentgroup',
            'Time': 'billdate',
            'Type': 'paymentmethod',
            'Status': 'status',
            // 'PaymentStatus': 'paymentstatus',
            'TotalAmount': 'netamount',
            'TransId': 'paymentid',
            // 'Source': 'source',
            'CreatedOn': 'createat',
            'LastUpdatedOn': 'lastupdated',
            'DueOn': 'billduedate',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.props.global.setLoading(true);
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }

        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        tempFilterValue['approvalUserId(eq)'] = encryptStorage.getItem('pUser').id;
        tempFilterValue['status(in)'] = '2|5';

        this.filter(tempFilterValue);
    }

    handleQuickFilter(value, comparrison){
        let tempFilterValue = {};
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        tempFilterValue['approvalUserId(eq)'] = encryptStorage.getItem('pUser').id;

        this.props.reports.clearFilters();
        if(comparrison){
            tempFilterValue[comparrison] = value;
        }else{
            tempFilterValue['status(eq)'] = value;
        }
        this.filter(tempFilterValue)
        this.setState({ head: value });
    }

    render() {
        const summary = this.state.localReportSummary;
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];
        const approvalUserId = encryptStorage.getItem('pUser').id;
        return (
            <Layout {...this.props}>

            <div>

            <Modal
                style={{ textAlign: 'center' }}
                show={this.state.approvalModalIsOpen}
                onHide={this.closeApprovalModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Body>
                    <BiQuestionMark className="icon-modal" />
                    <h5>{this.state.approvalAction === 1 ? "Approval" : "Reject"}</h5>
                    <p className="small">
                    Are you sure you want to {this.state.approvalAction === 1 ? "approve" : "reject"} this record?
                    </p>
                    <Button
                    className="btn cancel-btn"
                    variant="default"
                    onClick={(e) => this.closeApprovalModal()}
                    >
                    Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                    className="btn"
                    variant="primary"
                    onClick={this.approvalAction}
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                </Modal.Body>
            </Modal>

            <Modal
                style={{ textAlign: 'center' }}
                show={this.state.deleteModalIsOpen}
                onHide={this.closeDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <BiTrash className="icon-modal" />
                  <h5>Delete</h5>
                  <p className="small">
                    Are you sure you want to delete this Bill?
                  </p>
                  <Button
                    className="btn cancel-btn"
                    variant="default"
                    onClick={(e) => this.closeDeleteModal()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn"
                    variant="danger"
                    onClick={this.deleteBillAction}
                  >
                    Delete
                  </Button>
                </Modal.Body>
              </Modal>

              <Modal
                style={{ textAlign: 'center' }}
                show={this.state.payBillModalIsOpen}
                onHide={this.closePayBillModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <BiDollarCircle className="icon-modal" />
                  <h5>Pay Bill</h5>
                  <p className="small">
                    Are you sure you want to pay this Bill?
                  </p>
                  <Button
                    className="btn cancel-btn"
                    variant="default"
                    onClick={(e) => this.closePayBillModal()}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn"
                    variant="primary"
                    onClick={this.payBillAction}
                  >
                    &nbsp;&nbsp;&nbsp;Pay&nbsp;&nbsp;&nbsp;
                  </Button>
                </Modal.Body>
              </Modal>

              <Modal style={{textAlign: "center"}} show={this.state.approveModalIsOpen} onHide={this.closeApproveModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        {this.state.approveAction === 1 ? 
                            <AiOutlineCheckCircle className="icon-modal"/>
                        : 
                            <AiOutlineCloseCircle className="icon-modal"/>
                        }
                       
                        <h5>{this.state.approveAction === 1 ? "Approve" : "Disapprove"}</h5>
                        <p className="small">Are you sure you want to {this.state.approveAction === 1 ? "approve" : "disapprove"} this bill?</p>
                        <Button className="btn cancel-btn" variant="default" onClick={(e) => this.closeApproveModal()}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        {this.state.approveAction === 1 ? 
                        <Button className="btn" variant="primary" onClick={(e) => this.approveBillAction()}>
                            Approve
                        </Button>
                        : 
                        <Button className="btn" variant="danger" onClick={(e) => this.approveBillAction()}>
                            Disapprove
                        </Button>
                        }
                        
                    </Modal.Body>
                </Modal>
                <ParentFilterPanel report={'bills'} approvals={true} approvalUserId={approvalUserId} />
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <MoneyOutLinks gobackText="Go back" history={this.props.history} goback={false} selected="approvals" />
                </div>
                </TopBar>
                
                <div className="mt-body4">
                    <MainBar
                        btnNewName="Add Bill" 
                        newLink={"/" + this.props.global.getURLEntry() +"/bills/new"} 
                        reportName="Approvals"
                        dataQAName="ApprovalsMoneyOut"
                        reportTitle={this.state.head ? this.props.reports.getBillStatus(this.state.head, true) : "All Approvals"}
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        withTotal={true}
                        reportOut={true}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                    /> 
                <div className="statistic-reports">                  
                    <div className='item' onClick={(e) => this.handleQuickFilter('2')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                            <p>Sent to Approval</p>
                            ${ summary && summary.totalamountsent2approval ? this.props.global.numberWithCommas(summary.totalamountsent2approval.toFixed(2)) : "0.00" }<br/>
                            </div>
                            <div className='col-4'>
                                <span className="bg-secondary column">{ summary && summary.totalsent2approval ? summary.totalsent2approval : "0" }</span>
                            </div>
                        </div>
                    </div>
                   
                    <div className='item' onClick={(e) => this.handleQuickFilter('5')}>
                        <div className='row'>
                            <div className='col-8 text-right column'>
                                <p>Pending Approval</p>
                                ${ summary && summary.totalamount2approval ? this.props.global.numberWithCommas(summary.totalamount2approval.toFixed(2)) : "0.00" }<br/>
                            </div>
                            <div className='col-4 column'>
                                <span className="bg-secondary">{ summary && summary.total2approval ? summary.total2approval : "0" }</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container">  
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                    {
                        this.props.reports.getHeaders.map((record, i) => (
                            (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                            record[1].display && 
                            (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                            </th> ))
                            ||
                            (record[1].display && 
                            (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                            </th> ))
                        ))
                    }
                    <th className='text-center sticky-row' scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                
                { this.props.reports.records.map((record, i) => (
                        <tr key={i} className="cursorPointer" onClick={(e) => this.viewRecordDetails(record.IdBill,e)} onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} data-qaid={"approvalsMoneyOutRow-"+(i)}>
                            {/*<td>
                                <div className="icheck-primary">
                                    <input
                                        type="checkbox"
                                        id={"checkRow"+i}
                                    />
                                    <label htmlFor={"checkRow"+i}></label>
                                </div>
                            </td>*/}
                            { (this.props.reports.headers.Vendor && this.props.reports.headers.Vendor.display) && 
                            <td className='avatarTd'> <NameAvatar text={record.Vendor && record.Vendor.Name1 ? record.Vendor.Name1 : '-'}/> {record.Vendor && record.Vendor.Name1 ? this.props.global.capitalizeFirstLetterOfEachWord(record.Vendor.Name1) : '-'} {record.Vendor && record.Vendor.Name2 ? this.props.global.capitalizeFirstLetterOfEachWord(record.Vendor.Name2) : ''}</td>
                            }                            
                            { (this.props.reports.headers.VendorId && this.props.reports.headers.VendorId.display) && 
                            <td>{record.PaymentId && record.Vendor.VendorId ? record.Vendor.VendorId : '-'}</td>
                            }
                            { (this.props.reports.headers.ParentOrgName && this.props.reports.headers.ParentOrgName.display) &&
                            <td>{record && record.ParentOrgName ? this.props.global.capitalizeFirstLetterOfEachWord(record.ParentOrgName) : '-'}</td>
                            }
                            { (this.props.reports.headers.PaypointLegalname && this.props.reports.headers.PaypointLegalname.display) &&
                            <td>{record && record.PaypointLegalname ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointLegalname) : '-'}</td>
                            }
                            { (this.props.reports.headers.CustomerVendorAccount && this.props.reports.headers.CustomerVendorAccount.display) && 
                            <td>{record.Vendor && record.Vendor.customerVendorAccount ? record.Vendor.customerVendorAccount : '-'}</td>
                            }
                            { (this.props.reports.headers.VendorNumber && this.props.reports.headers.VendorNumber.display) && 
                            <td>{record.Vendor && record.Vendor.VendorNumber ? record.Vendor.VendorNumber : '-'}</td>
                            }
                            { (this.props.reports.headers.Ein && this.props.reports.headers.Ein.display) && 
                                <td>{record.Vendor && record.Vendor.EIN ? record.Vendor.EIN : '-'}</td>
                            }                            
                            { (this.props.reports.headers.Email && this.props.reports.headers.Email.display) && 
                            <td>{record.Vendor && record.Vendor.Email ? record.Vendor.Email : '-'}</td>
                            }                            
                            { (this.props.reports.headers.Phone && this.props.reports.headers.Phone.display) && 
                            <td>{record.Vendor && record.Vendor.Phone ? record.Vendor.Phone : '-'}</td>
                            }                            
                            {(this.props.reports.headers.BillingAddress && this.props.reports.headers.BillingAddress.display) &&
                            <td>{record.Vendor && record.Vendor.Address1 ? ((record.Vendor.Address1?record.Vendor.Address1+" ":"")+ (record.Vendor.Address2?record.Vendor.Address2+" ":"")+ (record.Vendor.City?record.Vendor.City+" ":"")+ (record.Vendor.State?record.Vendor.State  +" ":"")+ (record.Vendor.Zip?record.Vendor.Zip+" ":'')+ (record.Vendor.Country?record.Vendor.Country:'')) : '-'} </td>
                            }
                            {(this.props.reports.headers.RemitAddress && this.props.reports.headers.RemitAddress.display) &&
                            <td>{record.Vendor && record.Vendor.remitAddress1 ? ((record.Vendor.remitAddress1?record.Vendor.remitAddress1+" ":"")+ (record.Vendor.remitAddress2?record.Vendor.remitAddress2+" ":"")+ (record.Vendor.remitCity?record.Vendor.remitCity+" ":"")+ (record.Vendor.remitState?record.Vendor.remitState  +" ":"")+ (record.Vendor.remitZip?record.Vendor.remitZip+" ":'')+ (record.Vendor.remitCountry?record.Vendor.remitCountry:'')) : '-'} </td>
                            }
                            { (this.props.reports.headers.BillNumber && this.props.reports.headers.BillNumber.display) && 
                            <td>{record && record.BillNumber ? record.BillNumber : '-'}</td>
                            }                            
                            { (this.props.reports.headers.LotNumber && this.props.reports.headers.LotNumber.display) && 
                            <td>{record && record.LotNumber ? record.LotNumber : '-'}</td>
                            }
                            { (this.props.reports.headers.PaymentGroup && this.props.reports.headers.PaymentGroup.display) && 
                            <td>{record && record.PaymentGroup ? record.PaymentGroup : '-'}</td>
                            }                            
                            { (this.props.reports.headers.Time && this.props.reports.headers.Time.display) && 
                            <td>{record && record.BillDate ? (this.props.global.stringTimeFormat(record.BillDate)) : '-'}</td>
                            }         
                            { (this.props.reports.headers.Type && this.props.reports.headers.Type.display) && 
                            <td>{record && record.PaymentMethod ? this.props.global.getPaymethodImg(record.PaymentMethod) : this.props.global.getPaymethodImg("timer")}</td>
                            }
                            { (this.props.reports.headers.Status && this.props.reports.headers.Status.display) && 
                            <td>{this.props.reports.getBillStatus(record.Status)}</td>
                            }
                            { (this.props.reports.headers.PaymentStatus && this.props.reports.headers.PaymentStatus.display) && 
                            <td>{this.props.global.getMoneyOutTransStatus(record?.Transaction?.Status)}</td>
                            }                            
                            { (this.props.reports.headers.TotalAmount && this.props.reports.headers.TotalAmount.display) && 
                            <td className='text-right'>${record && record.NetAmount ? this.props.global.numberWithCommas(record.NetAmount.toFixed(2)) : '0.00'}</td>
                            }                            
                            { (this.props.reports.headers.TransId && this.props.reports.headers.TransId.display) && 
                            <td>{record && record.Transaction ? record.Transaction.PaymentId : '-'}</td>
                            }
                            { (this.props.reports.headers.Source && this.props.reports.headers.Source.display) && 
                            <td>{record.Transaction && record.Transaction.Source ? this.props.global.capitalizeFirstLetter(record.Transaction.Source) : '-'}</td>
                            }
                            { (this.props.reports.headers.Notes && this.props.reports.headers.Notes.display) && 
                                <td className='text-center'>
                                    {record && record.Comments ? 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{record.Comments}</Tooltip>}
                                        >
                                        <BiNotepad className="label-info-icon in-input"/>
                                        </OverlayTrigger>
                                    : '-'}
                                </td>
                            }
                            { (this.props.reports.headers.CreatedOn && this.props.reports.headers.CreatedOn.display) && 
                            <td>{record && record.CreatedAt ? (this.props.global.stringDateFormatV3(record.CreatedAt)) : '-'}</td>
                            }                            
                            { (this.props.reports.headers.LastUpdatedOn && this.props.reports.headers.LastUpdatedOn.display) && 
                            <td>{record && record.LastUpdated ? (this.props.global.stringDateFormatV3(record.LastUpdated)) : '-'}</td>
                            }                            
                            { (this.props.reports.headers.DueOn && this.props.reports.headers.DueOn.display) && 
                            <td>{record && record.DueDate ? (this.props.global.stringDateFormatV3(record.DueDate)) : '-'}</td>
                            }
                            { (this.props.reports.headers.CustomField1 && this.props.reports.headers.CustomField1.display) && 
                            <td>{record && record.AccountingField1 ? record.AccountingField1 : '-'}</td>
                            }                            
                            { (this.props.reports.headers.CustomField2 && this.props.reports.headers.CustomField2.display) && 
                            <td>{record && record.AccountingField2 ? record.AccountingField2 : '-'}</td>
                            } 
                            {/* (this.props.reports.headers.QuickActions && this.props.reports.headers.QuickActions.display) && 
                            <td style={{width: "104px"}} className="text-center approvalActions">
                                {(((record && record.Status === 2) || (record && record.Status === 5)) && this.props.bill.isUserAllowedToApprove(record.billApprovals)) ?
                                    <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip><div className='text-center'>Click to approve bill <br/>for payment</div></Tooltip>}
                                        >
                                       <a onClick={(e) => this.approveBill(e, record.IdBill)}><AiFillCheckCircle className='approve' /></a>
                                    </OverlayTrigger>
                                    
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip><div className='text-center'>Click to disapprove bill <br/>for payment</div></Tooltip>}
                                        >
                                      <a onClick={(e) => this.disapproveBill(e, record.IdBill)}><AiFillCloseCircle className='disapprove'/></a>
                                    </OverlayTrigger>                                    
                                    </>
                                :
                                    ((record && record.Status === 20) && this.props.bill.isUserAllowedToApprove(record.billApprovals)) ?
                                        <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip><div className='text-center'>This bill has already <br/>been approved</div></Tooltip>}
                                            >
                                            <a><AiFillCheckCircle className='approve disabled' /></a>
                                        </OverlayTrigger>
                                        
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip><div className='text-center'>Click to disapprove bill <br/>for payment</div></Tooltip>}
                                            >
                                        <a onClick={(e) => this.disapproveBill(e, record.IdBill)}><AiFillCloseCircle className='disapprove'/></a>
                                        </OverlayTrigger>                                  
                                        </>
                                        :
                                        "-"
                                    }
                            </td>
                                */}
                            <td className='text-center sticky-row'>
                                <DropdownButton
                                    menuAlign="right"
                                    title={<BiDotsVerticalRounded/>}
                                    id="actionsMenuButton"
                                    size="sm"
                                    variant="default"
                                >
                                <Dropdown.Item as={Link} to={"/" + this.props.global.getURLEntry() + "/bills/view/"+record.IdBill + "?backTo=approvals"}>View Bill</Dropdown.Item>
                                {(record.Status === 1 || record.Status === 20) &&
                                <Dropdown.Item onClick={()=>this.payBill(record)}>Pay Bill</Dropdown.Item>
                                }
                                <Dropdown.Item onClick={(e)=>this.viewVendor(record.Vendor.VendorId,e)}>View Vendor</Dropdown.Item>
                                {record.BillNumber &&
                                    <Dropdown.Item as={Link} to={"/" + this.props.global.getURLEntry() + '/report/transaction/out/'+ encodeURIComponent(record.BillNumber)}>Tracking Status</Dropdown.Item>
                                }

                                {userPermissions.indexOf("Bills") !== -1 && 
                                    <>
                                    <Dropdown.Item onClick={(e)=>this.approval(true, record?.IdBill)}>Approval</Dropdown.Item>
                                    <Dropdown.Item onClick={(e)=>this.approval(false,  record?.IdBill)}>Reject</Dropdown.Item>
                                    </>
                                }

                                 {(record.Status >= 0 && record.Status < 50 ) &&
                                 <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  style={{ color: '#c00000' }}
                                  onClick={(e) =>
                                    this.deleteBill(record.IdBill)
                                  }
                                >
                                  Delete Bill
                                </Dropdown.Item>
                                </>
                                }
                                </DropdownButton>
                            </td>
                        </tr>
                    ))
                }                
                </tbody>
                </table>
                {this.props.reports.records.length < 1 &&
                    <RecordsNotFound message="No bills yet" description={<span>You can track bills details here.</span>}/>
                }
                </div>
                <ReportPaginator report="bills"/>
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
       
    }
}

export { ApprovalsReport };