import React from 'react'
import { inject, observer } from 'mobx-react'
import { Tab, Tabs, Row, Button, Table, InputGroup, DropdownButton, Dropdown, Modal, Col } from 'react-bootstrap'
import { TopBar } from '../../components/TopBar'
import { MainTopBarMenu } from '../../components/MainTopBarMenu'
import { Layout } from '../../components/Layout'
import axios from 'axios'
import { ReadOnlySimpleLink } from '../../components/ReadOnlySimpleLink'
import { ReadOnlyCheckLink } from '../../components/ReadOnlyCheckLink'
import { InputSelectLink } from '../../components/InputSelectLink'
import { InputSelectTemplate } from '../../components/InputSelectTemplate'
import { ApplicationStatus } from '../../components/ApplicationStatus'
import { ToastContainer, toast, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BiNotepad, BiTrash, BiDetail, BiUserCheck, BiCog, BiDollarCircle, BiSend, BiCopyAlt } from 'react-icons/bi'
import { RiBankLine } from 'react-icons/ri'
import { AiOutlineWarning } from 'react-icons/ai'
import { IMaskInput } from 'react-imask'
import Moment from 'moment'
import visa from '../../assets/images/visa.svg'
import mastercard from '../../assets/images/mastercard.svg'
import amex from '../../assets/images/amex.png'
import discover from '../../assets/images/discover.svg'
import achIcon from '../../assets/images/ach.svg'
import { SessionManager } from '../../api/sessionManager'
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager'
import md5 from 'crypto-js/md5'
import NotesEventTabContaier from '../../components/Applications/NotesEventTabContaier'
import ManagementApplication from '../../store/Applications/ManagementApplication'
import SendEmailModal from './Applications/SendEmailModal'
import * as Sentry from '@sentry/react'

const FileDownload = require('js-file-download')

@inject('boarding', 'store', 'global', 'reports', 'entry')
@observer
class ViewApplication extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			baseTemplate: null,
			baseData: null,
			boardingStatus: null,
			showSend2Processor: false,
			showDocSend2Processor: false,
			wasSendingToProcessor: false,
			appId: 0,
			currentSection: 'business',
			offOwn: true,
			offPrc: true,
			offSrv: true,
			offDoc: true,
			complete: false,
			bsameaddress: true,
			endLinkModalIsOpen: false,
			additionalOwners: [],
			lastowner: 0,
			additionalContacts: [],
			lastcontact: 0,
			additionalFiles: [],
			lastfile: 0,
			currentPos: 0,
			payPointId: null,
			createDatePayPoint: '',
			activePos: [],
			isLoading: true,
			cancelTplModalIsOpen: false,
			saveTplModalIsOpen: false,
			isRoot: false,
			authData: null,
			appIdToModify: null,
			saveEmailModalIsOpen: false,
			appemail: '',
			applicationEmailModalIsOpen: false,
			applicationCopyLinkModalIsOpen: false,
			applicationLinkEmail: '',
			applicationLinkEmailError: false,
			idAppLink: null,
			appLink: '',
			referenceId: '',
			cancelAppModalIsOpen: false,
			errorAppModalIsOpen: false,
			sign_pdf_url: '',
			notesEvents: []
		}
		this.downloadFiles = this.downloadFiles.bind(this)
		this.handleEmailChange = this.handleEmailChange.bind(this)
		this.saveEmailModal = this.saveEmailModal.bind(this)
		this.closeSaveEmailModal = this.closeSaveEmailModal.bind(this)
		this.openApplicationEmailModal = this.openApplicationEmailModal.bind(this)
		this.closeApplicationEmailModal = this.closeApplicationEmailModal.bind(this)
		this.handleCopyApplicationEmail = this.handleCopyApplicationEmail.bind(this)
		this.getApplicationLink = this.getApplicationLink.bind(this)
		this.copyAppLink = this.copyAppLink.bind(this)
		this.copyToClipboard = this.copyToClipboard.bind(this)
		this.openCancelAppModal = this.openCancelAppModal.bind(this)
		this.closeCancelAppModal = this.closeCancelAppModal.bind(this)
		this.cancelAppAction = this.cancelAppAction.bind(this)
		this.onCreatePaypoint = this.onCreatePaypoint.bind(this)
		this.sendToProcessor = this.sendToProcessor.bind(this)
		this.sendDocumentToProcessor = this.sendDocumentToProcessor.bind(this)
		this.closeErrorAppModal = this.closeErrorAppModal.bind(this)
		this.changeStatus = this.changeStatus.bind(this)
		this.changeStatus = this.changeStatus.bind(this)
	}
	closeErrorAppModal() {
		this.setState({ errorAppModalIsOpen: false })
	}
	changeStatus(status) {
		const show2Processor = (this.state.baseTemplate?.services?.ach?.processor?.value === 'gp' || this.state.baseTemplate?.services?.card?.processor?.value === 'gp') && [3, 10].includes(parseInt(status))
		this.setState({
			boardingStatus: parseInt(status),
			showSend2Processor: show2Processor
		})
	}
	checkBuilderData(data) {
		const allElements = Object.entries(data).map((e) => e[1])
		return allElements.every((r) => r === null)
	}
	handlerGetBaseLink = async () => {
		this.props.global.setLoading(true)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let builderData = {}
		axios
			.get(process.env.REACT_APP_URL_API + `Boarding/read/${this.state.appId}?messageSkip=0&messageTake=20`, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((tdata) => {
				this.setState({ notesEvents: tdata.data.messages })
				if (!tdata.data.builderData || tdata.data.builderData === null) {
					this.props.global.setLoading(false)
					let errorMessage = 'Missing Template Data!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return
				}
				axios
					.get(process.env.REACT_APP_URL_API + 'Templates/get/' + tdata.data.templateId, {
						headers: {
							requestToken: encryptStorage.getItem('pToken')
						}
					})
					.then((template) => {
						if (!template.data || template.data === null) {
							this.props.global.setLoading(false)
							let errorMessage = 'Missing Template Data!'
							toast.error(errorMessage, {
								position: toast.POSITION.BOTTOM_RIGHT,
								className: 'toast-error-container'
							})
							this.props.history.push('/' + PayabliStorageManager.getEntryName() + '/boarding/applications')
							return
						}

						let orgId = encryptStorage.getItem(this.props.global.getURLEntry() + '_pEntry').orgId
						axios
							.get(process.env.REACT_APP_URL_API + 'Query/paypoints/' + orgId + '?limitRecord=1&boardingId(eq)=' + this.state.appId, {
								headers: {
									requestToken: encryptStorage.getItem('pToken')
								}
							})
							.then((paypoint) => {
								const show2Processor = (tdata.data.builderData?.services?.ach?.processor?.value === 'gp' || tdata.data.builderData?.services?.card?.processor?.value === 'gp') && [3, 10].includes(tdata.data.boardingStatus)
								const wasSendingToProcessor = tdata.data?.generalEvents?.some((el) => el.description === 'Application sent to processor')
								const showDocSend2Processor = show2Processor && !wasSendingToProcessor
								builderData = tdata.data.builderData
								if (this.checkBuilderData(tdata.data.builderData)) {
									builderData.owners = {
										own_list: template.data.templateContent?.ownershipData,
										contact_list: template.data.templateContent?.ownershipData
									}
									builderData.business = {
										details: {},
										address: {}
									}
									builderData.processing = template.data.templateContent?.processingData
									builderData.business.details = template.data.templateContent?.businessData
									builderData.business.address = template.data.templateContent?.businessData
									const cardAcceptance = template.data.templateContent?.servicesData?.card?.cardAcceptance?.types
									const achAcceptance = template.data.templateContent?.servicesData?.ach?.achAcceptance?.types
									builderData.services = { ...template.data.templateContent?.servicesData }
									builderData.services.card.acceptance = cardAcceptance
									builderData.services.ach.acceptance = achAcceptance
								}
								let valuesState = {
									baseTemplate: builderData,
									template_data: template.data.templateContent,
									baseData: tdata.data,
									boardingStatus: tdata.data.boardingStatus,
									showSend2Processor: show2Processor,
									showDocSend2Processor: showDocSend2Processor,
									isLoadingBase: false,
									update: true,
									signer: tdata.data.signer,
									wasSendingToProcessor: wasSendingToProcessor
								}
								let payPointData = paypoint.data.Records
								if (payPointData.length > 0) {
									valuesState.payPointId = payPointData[0].IdPaypoint
									valuesState.createDatePayPoint = payPointData[0].CreatedAt
									valuesState.entryPoint = payPointData[0]?.EntryPoints[0]
								}
								if (tdata.data.signer?.signedDocumentReference) {
									axios
										.put(
											`${process.env.REACT_APP_URL_API}User/onetime/1`,
											{},
											{
												headers: {
													requestToken: encryptStorage.getItem('pToken')
												}
											}
										)
										.then((response) => {
											const uniqueUserToken = response.data.responseData
											axios
												.get(`${process.env.REACT_APP_BACKEND_URL}/v1/document/${tdata.data.signer.signedDocumentReference}`, {
													headers: {
														requestToken: uniqueUserToken,
														'x-api-key': `${process.env.REACT_APP_XAPI_KEY}`,
														Authorization: `Basic ${process.env.REACT_APP_BACKEND_AUTH_TOKEN}`
													}
												})
												.then((response) => {
													valuesState['sign_pdf_url'] = response.data.fileUrl
													this.auxSetState(valuesState)
												})
												.catch((error) => {
													this.props.global.setLoading(false)
													this.auxSetState(valuesState)
												})
										})
								} else {
									this.auxSetState(valuesState)
								}
							})
					})
					.catch((error) => {
						this.props.global.setLoading(false)
						this.setState({ errorAppModalIsOpen: true })
					})
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}
	async createLinkTpl(templateId, ignoreEmpty) {
		this.props.global.setLoading(true)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		try {
			const res = await axios.get(`${process.env.REACT_APP_URL_API}Templates/getlink/${templateId}/${ignoreEmpty}`, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			this.props.global.setLoading(false)
			if (res.data.responseText === 'Success') {
				toast.success('Boarding link created successfully! - ' + res.data.responseData, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
				this.closeErrorAppModal()
				this.handlerGetBaseLink()
			} else {
				this.closeErrorAppModal()
			}
		} catch (error) {
			this.props.global.setLoading(false)
			let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			this.closeErrorAppModal()
		}
	}
	auxSetState(valuesState) {
		this.setState(valuesState, () => {
			if (this.state.baseTemplate) {
				if (this.state.baseTemplate.attributes && this.state.baseTemplate.attributes.minimumDocuments) {
					let x = parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10)
					this.setState({ lastfile: x })
				}
				if (this.state.baseData.contactData !== null && this.state.baseData.contactData.length > 1) {
					var cts = []
					for (var cc = 1; cc < this.state.baseData.contactData.length; cc++) cts.push(cc)
					this.setState({ additionalContacts: cts, lastcontact: this.state.baseData.contactData.length })
				}
				if (this.state.baseData.ownerData !== null && this.state.baseData.ownerData.length > 1) {
					var ots = []
					for (var oc = 1; oc < this.state.baseData.ownerData.length; oc++) ots.push(oc)
					this.setState({ additionalOwners: ots, lastowner: this.state.baseData.ownerData.length })
				}
				//init positions
				var atabs = []
				var cs = ''
				if (this.state.baseTemplate.business !== null) {
					atabs.push(0)
					cs = 'business'
				}
				if (this.state.baseTemplate.owners !== null) {
					atabs.push(1)
					if (cs === '') cs = 'owners'
				}
				if (this.state.baseTemplate.processing !== null) {
					atabs.push(2)
					if (cs === '') cs = 'processing'
				}
				if (this.state.baseTemplate.services !== null) {
					atabs.push(3)
					if (cs === '') cs = 'services'
				}
				if (this.state.baseTemplate.banking !== null) {
					atabs.push(4)
					if (cs === '') cs = 'banking'
				}
				atabs.push(5)
				this.setState({ currentSection: cs, activePos: atabs })
				this.props.global.setLoading(false)
			}
		})
	}

	componentDidMount() {
		this.props.global.protect(this.props.history)
		this.setState({ appId: this.props.match.params.id }, function () {
			this.handlerGetBaseLink()
		})
		this.props.boarding.clearTemplateData()
		this.props.boarding.clearErrorData()
		this.getAuth()
	}

	onCreatePaypoint(e) {
		this.props.global.setLoading(true)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		axios
			.get(process.env.REACT_APP_URL_API + 'Management/auth', {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((dt) => {
				if (!dt.data.responseData || dt.data.responseData === null) {
					this.props.global.setLoading(false)
					let errorMessage = 'Failed Authentication!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return
				}
				let signing = dt.data.responseData
				axios
					.get(process.env.REACT_APP_URL_API + 'Management/paypointFromApp/' + this.state.appId, {
						headers: {
							requestToken: encryptStorage.getItem('pToken'),
							signing: signing
						}
					})
					.then((paypoint) => {
						let paypointId = paypoint.data.responseData
						axios
							.get(process.env.REACT_APP_URL_API + 'Management/paypoint/' + paypointId, {
								headers: {
									requestToken: encryptStorage.getItem('pToken'),
									signing: signing
								}
							})
							.then((res) => {
								this.setState({ payPointId: res.data.idPaypoint, createDatePayPoint: res.data.createdAt }, () => {
									this.props.global.setLoading(false)
								})
							})
					})
					.catch((error) => {
						this.props.global.setLoading(false)
						toast.error(error.response.data, {
							position: toast.POSITION.BOTTOM_RIGHT,
							className: 'toast-error-container'
						})
					})
			})
	}

	downloadFiles(zip, e) {
		if (this.state.authData === null || this.state.appId === 0) {
			toast.error('Reading data error', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			})
			return
		}

		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		axios
			.get(process.env.REACT_APP_URL_API + 'Management/attachmentFromApp/' + this.state.appId, {
				responseType: 'blob',
				headers: {
					requestToken: encryptStorage.getItem('pToken'),
					signing: this.state.authData
				}
			})
			.then((res) => {
				FileDownload(res.data, zip.replace('.zip', '') + '.zip')
			})
			.catch((error) => {
				toast.error('Error downloading file', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}

	getPermissions() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let user = encryptStorage.getItem('pUser')
		return user.permissions.map((element) => element.toUpperCase()).includes('MANAGEMENT')
	}

	getAuth() {
		//auth for management
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		axios
			.get(process.env.REACT_APP_URL_API + 'Management/auth', {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((dt) => {
				let authData = dt.data.responseData
				if (!authData || authData === null) {
					toast.error('Failed Authentication!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return
				}

				this.setState({ authData: authData })
			})
			.catch((error) => {})
	}

	setTab(event) {
		this.setState({ currentSection: event })
	}

	getSignerBlock() {
		const years = []
		let year = new Date().getFullYear()
		for (let i = 1900; i <= year; i++) {
			years.push(i)
		}

		return (
			<>
				<h5 className="mt-5">Signer</h5>
				<p className="small mb-4 dark-grey">Signer information</p>
				<div className="row">
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iTitle="Name"
							iName="signer_name"
							iValue={this.state.signer && this.state.signer.name ? this.state.signer.name : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iHideShow={true}
							iTitle="Social Security Number"
							iName="signer_ssn"
							iValue={this.state.signer && this.state.signer.ssn ? this.state.signer.ssn : ''}
						/>
					</div>

					<div className="col-md-6">
						<ReadOnlySimpleLink
							iClass="input-calendar"
							iTitle="Date of Birth"
							iName="signer_dob"
							iValue={this.props.global.stringDateFormat(this.state.signer.dob)}
						/>
					</div>

					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iTitle="Phone"
							iName="signer_phone"
							iValue={this.state.signer && this.state.signer.phone ? this.state.signer.phone : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iTitle="Email"
							iName="signer_email"
							iValue={this.state.signer && this.state.signer.email ? this.state.signer.email : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iTitle="Address"
							iName="signer_address"
							iValue={this.state.signer && this.state.signer.address ? this.state.signer.address : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iTitle="Address2"
							iName="signer_address2"
							iValue={this.state.signer && this.state.signer.address1 ? this.state.signer.address1 : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iTitle="City"
							iName="signer_city"
							iValue={this.state.signer && this.state.signer.city ? this.state.signer.city : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iType="us_states"
							iTitle="State"
							iName="signer_state"
							iValue={this.state.signer && this.state.signer.state ? this.state.signer.state : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iType="countries"
							iTitle="Country"
							iName="signer_country"
							iValue={this.state.signer && this.state.signer.country ? this.state.signer.country : ''}
						/>
					</div>
					<div className="col-sm-6">
						<ReadOnlySimpleLink
							iTitle="Zip"
							iName="signer_zip"
							iValue={this.state.signer && this.state.signer.zip ? this.state.signer.zip : ''}
						/>
					</div>
				</div>
			</>
		)
	}

	getOwnerBlock(k) {
		if (!this.state.baseTemplate.owners || this.state.baseTemplate.owners === null) {
			return
		}
		let rows = []

		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownername && this.state.baseTemplate.owners.own_list.ownername !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Owner Name"
					iName={'ownername_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownername : this.state.baseTemplate.owners.own_list.ownername.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownertitle && this.state.baseTemplate.owners.own_list.ownertitle !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Owner Title"
					iName={'ownertitle_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownertitle : this.state.baseTemplate.owners.own_list.ownertitle.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerpercent && this.state.baseTemplate.owners.own_list.ownerpercent !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Ownership %"
					iName={'ownerpercent_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerpercent : this.state.baseTemplate.owners.own_list.ownerpercent.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerssn && this.state.baseTemplate.owners.own_list.ownerssn !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iHideShow={true}
					iTitle="Owner SSN"
					iName={'ownerssn_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerssn : this.state.baseTemplate.owners.own_list.ownerssn.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerdob && this.state.baseTemplate.owners.own_list.ownerdob !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Date of Birth"
					iClass="input-calendar"
					iName={'ownerdob_' + k}
					iValue={this.props.global.stringDateFormat(this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdob : this.state.baseTemplate.owners.own_list.ownerdob.value)}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerphone1 && this.state.baseTemplate.owners.own_list.ownerphone1 !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Phone Number"
					iName={'ownerphone1_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone1 : this.state.baseTemplate.owners.own_list.ownerphone1.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerphone2 && this.state.baseTemplate.owners.own_list.ownerphone2 !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Additional Phone Number"
					iName={'ownerphone2_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone2 : this.state.baseTemplate.owners.own_list.ownerphone2.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.owneremail && this.state.baseTemplate.owners.own_list.owneremail !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Email Address"
					iName={'owneremail_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].owneremail : this.state.baseTemplate.owners.own_list.owneremail.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ownerdriver && this.state.baseTemplate.owners.own_list.ownerdriver !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Driver's License #"
					iName={'ownerdriver_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdriver : this.state.baseTemplate.owners.own_list.ownerdriver.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.odriverstate && this.state.baseTemplate.owners.own_list.odriverstate !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Driver's State License"
					iName={'odriverstate_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].odriverstate : this.state.baseTemplate.owners.own_list.odriverstate.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.oaddress && this.state.baseTemplate.owners.own_list.oaddress !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Owner Address"
					iName={'oaddress_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].oaddress : this.state.baseTemplate.owners.own_list.oaddress.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ocity && this.state.baseTemplate.owners.own_list.ocity !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="City"
					iName={'ocity_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocity : this.state.baseTemplate.owners.own_list.ocity.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ostate && this.state.baseTemplate.owners.own_list.ostate !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="State"
					iName={'ostate_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ostate : this.state.baseTemplate.owners.own_list.ostate.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ozip && this.state.baseTemplate.owners.own_list.ozip !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Zip"
					iName={'ozip_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ozip : this.state.baseTemplate.owners.own_list.ozip.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.own_list && this.state.baseTemplate.owners.own_list.ocountry && this.state.baseTemplate.owners.own_list.ocountry !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Country"
					iName={'ocountry_' + k}
					iValue={this.state.baseData.ownerData !== null && this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocountry : this.state.baseTemplate.owners.own_list.ocountry.value}
				/>
			)
		}

		return <Row className="mt-4">{rows}</Row>
	}

	getContactBlock(k) {
		if (!this.state.baseTemplate.owners) return
		if (this.state.baseTemplate.owners.contact_list === null) return
		var rows = []

		if (this.state.baseTemplate.owners.contact_list.contactName && this.state.baseTemplate.owners.contact_list.contactName !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Contact Name"
					iName={'contactName_' + k}
					iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.contact_list.contactTitle && this.state.baseTemplate.owners.contact_list.contactTitle !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Contact Title"
					iName={'contactTitle_' + k}
					iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.contact_list.contactEmail && this.state.baseTemplate.owners.contact_list.contactEmail !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Contact Email"
					iName={'contactEmail_' + k}
					iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value}
				/>
			)
		}
		if (this.state.baseTemplate.owners.contact_list.contactPhone && this.state.baseTemplate.owners.contact_list.contactPhone !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Contact Phone"
					iName={'contactPhone_' + k}
					iValue={this.state.baseData.contactData !== null && this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value}
				/>
			)
		}

		return <Row className="mt-4">{rows}</Row>
	}

	getDocumentBlock() {
		var rows = []
		var files = []
		let zipfile = ''
		if (this.state.baseData.documentsRef && this.state.baseData.documentsRef !== null) {
			if (this.state.baseData.documentsRef.zipfile !== null && this.state.baseData.documentsRef?.filelist?.length > 0) {
				zipfile = <b>{'Documents stored in: ' + this.state.baseData.documentsRef.zipfile}</b>
			}
			if (this.state.baseData.documentsRef.filelist !== null && this.state.baseData.documentsRef.filelist.length > 0) {
				for (var j = 0; j < this.state.baseData.documentsRef.filelist.length; j++) {
					files.push(
						<div className="d-flex align-items-center justify-content-between flex-wrap flex-row w-100 gap-3">
							{this.getPermissions() ? (
								<span
									onClick={(e) => this.downloadFiles(this.state.baseData.documentsRef.zipfile, e)}
									className="text-success cursorPointer">
									{this.state.baseData.documentsRef.filelist[j].originalName}
								</span>
							) : (
								<span>{this.state.baseData.documentsRef.filelist[j].originalName}</span>
							)}
							{this.state.wasSendingToProcessor && (
								<>
									<span
										className="d-flex cursorPointer"
										style={{ color: '#10A0E3' }}
										onClick={(e) => this.sendDocumentToProcessor(this.state.appId, j)}>
										Send to processor{' '}
										<BiSend
											size={20}
											color="primary"
										/>
									</span>
								</>
							)}
							<br />
						</div>
					)
				}
			}
		}
		let x = parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10)
		if (files.length < x) {
			x = x - files.length
			for (var i = 0; i < x; i++) {
				rows.push(
					<div
						className="col-md-6 mt-2"
						key={i}>
						<input
							type="file"
							className="form-control input-error"
							id={'doc_' + i}
							name={'doc_' + i}
							onChange={this.onFileChange}
							accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain"
						/>
					</div>
				)
				if (!this.props.boarding.existsTemplateData('doc_' + i)) {
					this.props.boarding.setErrorData('doc_' + i, true)
					this.props.boarding.setTemplateData('doc_' + i, null)
				}
			}
		} else {
			x = 0
		}
		if (this.state.additionalFiles.length > 0) {
			for (let j = 0; j < this.state.additionalFiles.length; j++) {
				let f = this.state.additionalFiles[j]
				rows.push(
					<div
						className="col-md-6 mt-2"
						key={f}>
						<InputGroup>
							<input
								type="file"
								className="form-control input-error"
								id={'doc_' + f}
								name={'doc_' + f}
								onChange={this.onFileChange}
								accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.ms-excel,application/msword,text/plain"
							/>
							<Button
								variant="danger"
								onClick={(e) => this.removeDoc(f)}>
								<BiTrash fontSize="1.5em" />
							</Button>
						</InputGroup>
					</div>
				)
				if (!this.props.boarding.existsTemplateData('doc_' + f)) {
					this.props.boarding.setErrorData('doc_' + f, true)
					this.props.boarding.setTemplateData('doc_' + f, null)
				}
			}
		}

		return (
			<>
				<Row className="mt-2">
					<div className="col-3">
						<p>{zipfile}</p>
					</div>
					<div className="col-9">{files}</div>
				</Row>
				{this.state.sign_pdf_url && (
					<Col className="mt-2">
						<Row>
							<strong>Document signed PDF</strong>
						</Row>
						<Row>
							<a
								href={this.state.sign_pdf_url}
								target="_blank"
								rel="noopener noreferrer">
								Document Signed.pdf
							</a>
						</Row>
					</Col>
				)}
			</>
		)
	}

	getBusinessBlock() {
		var rows = []
		if (this.state.baseTemplate.business && this.state.baseTemplate.business.details && this.state.baseTemplate.business.details !== null) {
			if (this.state.baseTemplate.business.details.legalname && this.state.baseTemplate.business.details.legalname !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Business Legal Name"
						iName="legalname"
						iValue={this.state.baseData.legalName}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.dbaname && this.state.baseTemplate.business.details.dbaname !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Business DBA Name"
						iName="dbaname"
						iValue={this.state.baseData.dbaName}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.btype && this.state.baseTemplate.business.details.btype !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Business Type"
						iName="btype"
						iValue={this.state.baseData.ownType}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.ein && this.state.baseTemplate.business.details.ein !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iHideShow={true}
						iTitle="Business EIN"
						iName="ein"
						iValue={this.state.baseData.ein}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.taxfillname && this.state.baseTemplate.business.details.taxfillname !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Tax Filing Name"
						iName="taxfillname"
						iValue={this.state.baseData.taxfillname}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.license && this.state.baseTemplate.business.details.license !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Business License"
						iName="license"
						iValue={this.state.baseData.license}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.licstate && this.state.baseTemplate.business.details.licstate !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="License State"
						iName="licstate"
						iValue={this.state.baseData.licenseState}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.website && this.state.baseTemplate.business.details.website !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Website"
						iName="website"
						iValue={this.state.baseData.websiteAddress}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.startdate && this.state.baseTemplate.business.details.startdate !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iClass="input-calendar"
						iTitle="Business Start Date"
						iName="startdate"
						iValue={this.props.global.stringDateFormat(this.state.baseData.bStartdate)}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.phonenumber && this.state.baseTemplate.business.details.phonenumber !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Phone Number"
						iName="phonenumber"
						iValue={this.state.baseData.bPhone}
					/>
				)
			}
			if (this.state.baseTemplate.business.details.faxnumber && this.state.baseTemplate.business.details.faxnumber !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Fax Number"
						iName="faxnumber"
						iValue={this.state.baseData.bFax}
					/>
				)
			}
		}
		return <Row className="mt-4">{rows}</Row>
	}

	getBusinessAddressBlock() {
		var rows = []
		var mrows = []

		if (this.state.baseTemplate.business && this.state.baseTemplate.business.address && this.state.baseTemplate.business.address !== null) {
			if (this.state.baseTemplate.business.address.baddress && this.state.baseTemplate.business.address.baddress !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Address line 1"
						iName="baddress"
						iValue={this.state.baseData.bAddress1}
					/>
				)
			}

			if (this.state.baseTemplate.business.address.baddress1 && this.state.baseTemplate.business.address.baddress1 !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Address line 2"
						iName="baddress1"
						iValue={this.state.baseData.bAddress2}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.bcity && this.state.baseTemplate.business.address.bcity !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="City"
						iName="bcity"
						iValue={this.state.baseData.bCity}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.bstate && this.state.baseTemplate.business.address.bstate !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="State"
						iName="bstate"
						iValue={this.state.baseData.bState}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.bzip && this.state.baseTemplate.business.address.bzip !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Zip"
						iName="bzip"
						iValue={this.state.baseData.bZip}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.bcountry && this.state.baseTemplate.business.address.bcountry !== null) {
				rows.push(
					<ReadOnlySimpleLink
						iTitle="Country"
						iName="bcountry"
						iValue={this.state.baseData.bCountry}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.maddress && this.state.baseTemplate.business.address.maddress !== null) {
				mrows.push(
					<ReadOnlySimpleLink
						iTitle="Mailing Line Address 1"
						iName="maddress"
						iValue={this.state.baseData.mAddress1}
					/>
				)
			}

			if (this.state.baseTemplate.business.address.maddress1 && this.state.baseTemplate.business.address.maddress1 !== null) {
				mrows.push(
					<ReadOnlySimpleLink
						iTitle="Mailing Line Address 2"
						iName="maddress1"
						iValue={this.state.baseData.mAddress2}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.mcity && this.state.baseTemplate.business.address.mcity !== null) {
				mrows.push(
					<ReadOnlySimpleLink
						iTitle="Mailing City"
						iName="mcity"
						iValue={this.state.baseData.mCity}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.mstate && this.state.baseTemplate.business.address.mstate !== null) {
				mrows.push(
					<ReadOnlySimpleLink
						iTitle="Mailing State"
						iName="mstate"
						iValue={this.state.baseData.mState}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.mzip && this.state.baseTemplate.business.address.mzip !== null) {
				mrows.push(
					<ReadOnlySimpleLink
						iTitle="Mailing Zip"
						iName="mzip"
						iValue={this.state.baseData.mZip}
					/>
				)
			}
			if (this.state.baseTemplate.business.address.mcountry && this.state.baseTemplate.business.address.mcountry !== null) {
				mrows.push(
					<ReadOnlySimpleLink
						iTitle="Mailing Country"
						iName="mcountry"
						iValue={this.state.baseData.mCountry}
					/>
				)
			}
		}
		return (
			<>
				<Row className="mt-2">{rows}</Row>
				<hr />
				<Row className="mt-2">{mrows}</Row>
			</>
		)
	}

	getProcessingBlock() {
		if (this.state.baseTemplate.processing === null) return
		var rows = []

		if (this.state.baseTemplate.processing.mcc && this.state.baseTemplate.processing.mcc !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Industry (Merchant Category Code)"
					iName="mcc"
					iValue={this.props.global.getMccOptionName(this.state.baseData.mccid)}
				/>
			)
		}
		if (this.state.baseTemplate.processing.bsummary && this.state.baseTemplate.processing.bsummary !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Tell us about your Business"
					iName="bsummary"
					iValue={this.state.baseData.bSummary}
				/>
			)
		}
		if (this.state.baseTemplate.processing.binperson && this.state.baseTemplate.processing.binperson !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Percent of payments in Person"
					iName="binperson"
					iValue={this.state.baseData.binPerson}
				/>
			)
		}
		if (this.state.baseTemplate.processing.binphone && this.state.baseTemplate.processing.binphone !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Percent of payments by Phone"
					iName="binphone"
					iValue={this.state.baseData.binPhone}
				/>
			)
		}
		if (this.state.baseTemplate.processing.binweb && this.state.baseTemplate.processing.binweb !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Percent of payments online"
					iName="binweb"
					iValue={this.state.baseData.binWeb}
				/>
			)
		}
		if (this.state.baseTemplate.processing.avgmonthly && this.state.baseTemplate.processing.avgmonthly !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iMask="money"
					iTitle="Average Monthly Volume"
					iName="avgmonthly"
					iValue={this.state.baseData.averageMonthlyVolume}
				/>
			)
		}
		if (this.state.baseTemplate.processing.ticketamt && this.state.baseTemplate.processing.ticketamt !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iMask="money"
					iTitle="Average Ticket Amount"
					iName="ticketamt"
					iValue={this.state.baseData.averageTicketAmount}
				/>
			)
		}
		if (this.state.baseTemplate.processing.highticketamt && this.state.baseTemplate.processing.highticketamt !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iMask="money"
					iTitle="High Ticket Amount"
					iName="highticketamt"
					iValue={this.state.baseData.highTicketAmount}
				/>
			)
		}
		if (this.state.baseTemplate.processing.annualRevenue && this.state.baseTemplate.processing.annualRevenue !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iMask="money"
					iTitle="Annual Revenue Volume"
					iName="annualRevenue"
					iValue={this.state.baseData.annualRevenue}
				/>
			)
		}
		if (this.state.baseTemplate.processing.whenCharged && this.state.baseTemplate.processing.whenCharged !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="When is Payment Charged"
					iName="whenCharged"
					iValue={this.state.baseData.whencharged}
				/>
			)
		}
		if (this.state.baseTemplate.processing.whenProvided && this.state.baseTemplate.processing.whenProvided !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Service Generally Provided In"
					iName="whenProvided"
					iValue={this.state.baseData.whenprovided}
				/>
			)
		}
		if (this.state.baseTemplate.processing.whenDelivered && this.state.baseTemplate.processing.whenDelivered !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Products/Services Delivered In"
					iName="whenDelivered"
					iValue={this.state.baseData.whendelivered}
				/>
			)
		}
		if (this.state.baseTemplate.processing.whenRefunded && this.state.baseTemplate.processing.whenRefunded !== null) {
			rows.push(
				<ReadOnlySimpleLink
					iTitle="Refund Policy"
					iName="whenRefunded"
					iValue={this.state.baseData.whenrefund}
				/>
			)
		}
		return <Row className="mt-4">{rows}</Row>
	}

	getFeeName(code) {
		const names = {
			transactionCardFee: 'per Transaction',
			chargebackCardFee: 'per Chargeback',
			achBatchCardFee: 'ACH Batch',
			avsCardFee: 'per AVS',
			montlyPlatformCardFee: 'Monthly per Platform',
			annualCardFee: 'Annual Fee',
			minimumProcessingCardFee: 'Minimum Processing',
			ddaRejectsCardFee: 'per DDA Reject',
			retrievalCardFee: 'Retrieval Fee',
			monthlyPCICardFee: 'Monthly PCI Fee',
			earlyTerminationCardFee: 'Early Termination Fe',
			returnedAchFee: 'per Returned',
			chargebackAchFee: 'per Chargeback',
			monthlyAchFee: 'Verify Bank',
			annualAchFee: 'Verify Negative',
			verifyBankAchFee: 'Verify Fund',
			verifyNegativeAchFee: 'Monthly per Platform',
			verifyFundAchFee: 'Annual Fee',
			sundayOriginationAchFee: 'Sunday Origination',
			sameDayAchFee: 'Same Day',
			advancedSettlementAchFee: 'Advanced Settlement',
			quarterlyPCIAchFee: 'Quarterly PCI Fee',
			earlyTerminationAchFee: 'Early Termination Fee'
		}
		return names[code]
	}

	getTable(tiers, type, cardOrAch) {
		const acceptance = cardOrAch === 'Card' ? this.state.template_data?.servicesData?.card.cardAcceptance.types : this.state.template_data?.servicesData?.ach?.achAcceptance.types
		let acceptanceVisible = Object.entries(acceptance)
			.filter((e) => e[1].visible === true)
			.map((e) => e[0])
		let visibleRanges = false
		if (cardOrAch === 'ACH') {
			acceptanceVisible = ['web']
		}
		return (
			<Table className="table-striped">
				<thead>
					{type === 'through' && (
						<>
							<tr key={1}>
								<th key={0}>{cardOrAch}</th>
								<th key={1}>% OneTime</th>
								<th key={2}>$ OneTime</th>
								<th key={3}>% Recurring</th>
								<th key={4}>$ Recurring</th>
								{visibleRanges && (
									<>
										<th key={5}>Low pay range</th>
										<th key={6}>High pay range</th>
									</>
								)}
							</tr>
						</>
					)}
					{type !== 'through' && (
						<>
							<tr key={1}>
								<th key={0}>{cardOrAch}</th>
								<th key={1}>% per Auth</th>
								<th key={2}>$ per Auth</th>
								{visibleRanges && (
									<>
										<th key={5}>Low pay range</th>
										<th key={6}>High pay range</th>
									</>
								)}
							</tr>
						</>
					)}
				</thead>

				<tbody>
					{tiers.map((e, i) =>
						Object.entries(e)
							.filter((e) => acceptanceVisible.includes(e[0]))
							.map((k, j) => (
								<tr key={j}>
									<td key={0}>
										<div className="card-brands ">
											<div>
												<img
													alt=""
													style={k[0] === 'web' ? { width: '44px', height: '100%' } : k[0] === 'amex' || k[0] === 'masterCard' ? { height: '28px' } : null}
													className={k[0] === 'web' ? 'grey-icon-v2' : k[0] === 'masterCard' ? 'mastercard' : k[0] === 'discover' ? 'discover' : 'amex'}
													src={k[0] === 'web' ? achIcon : k[0] === 'visa' ? visa : k[0] === 'masterCard' ? mastercard : k[0] === 'discover' ? discover : amex}
												/>
											</div>
										</div>
									</td>
									{type === 'through' && (
										<>
											<td key={1}>{k[1].percentFeeOneTime}%</td>
											<td key={2}>$ {parseFloat(k[1].amountFeeOneTime || 0).toFixed(2)}</td>
											<td key={3}>{k[1].percentFeeRecurring}%</td>
											<td key={4}>$ {parseFloat(k[1].amountFeeRecurring || 0).toFixed(2)}</td>
											{visibleRanges && (
												<>
													<td key={5}>{k[1].lowPayRange}</td>
													<td key={6}>{k[1].highPayRange}</td>
												</>
											)}
										</>
									)}
									{type !== 'through' && (
										<>
											<td key={1}>{parseFloat(k[1].percentxAuth || 0).toFixed(2)}%</td>
											<td key={2}>$ {parseFloat(k[1].amountxAuth || 0).toFixed(2)}</td>
											{visibleRanges && (
												<>
													<td key={5}>{k[1].lowPayRange}</td>
													<td key={6}>{k[1].highPayRange}</td>
												</>
											)}
										</>
									)}
								</tr>
							))
					)}
				</tbody>
			</Table>
		)
	}

	getCardAcceptance() {
		if ((this.state.baseTemplate.services && this.state?.baseTemplate?.services?.card === null) || (this.state.baseTemplate.services && this.state?.baseTemplate?.services?.card.acceptance === null)) return
		var rows = []
		if (this.state.baseTemplate.services && this.state?.baseTemplate?.services?.card.acceptance.visa && this.state?.baseTemplate?.services?.card.acceptance.visa !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Visa"
					iName="card_visa"
					iValue={this.state?.baseTemplate?.services?.card.acceptance.visa.value}
				/>
			)
		}
		if (this.state.baseTemplate.services && this.state?.baseTemplate?.services?.card.acceptance.mastercard && this.state?.baseTemplate?.services?.card.acceptance.mastercard !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Mastercard"
					iName="card_mastercard"
					iValue={this.state?.baseTemplate?.services?.card.acceptance.mastercard.value}
				/>
			)
		}
		if (this.state.baseTemplate.services && this.state?.baseTemplate?.services?.card.acceptance.discover && this.state?.baseTemplate?.services?.card.acceptance.discover !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Discover"
					iName="card_discover"
					iValue={this.state?.baseTemplate?.services?.card.acceptance.discover.value}
				/>
			)
		}
		if (this.state.baseTemplate.services && this.state?.baseTemplate?.services?.card.acceptance.amex && this.state?.baseTemplate?.services?.card.acceptance.amex !== null) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Amex"
					iName="card_amex"
					iValue={this.state?.baseTemplate?.services?.card.acceptance.amex.value}
				/>
			)
		}
		return (
			<Row className="mt-2 mb-2">
				<h6 className="mb-2">Card Acceptance</h6>
				<br />
				<label className="mb-2 small">Integrated per transaction fee for card acceptance.</label>
				<br />
				{rows}
			</Row>
		)
	}

	getCardPricing() {
		if (this.state.template_data?.servicesData?.card.pricingType === null || !this.state.template_data?.servicesData?.card.pricingType.value) return
		if (this.state.template_data?.servicesData?.card.pricingType.value === '1') {
			return (
				<>
					<strong>IC+ Card Price</strong>
					{this.getCardPricingICPlus()}
				</>
			)
		} else if (this.state.template_data?.servicesData?.card.pricingType.value === '2') {
			return (
				<>
					<strong>Flat Card Price</strong>
					{this.getCardPricingFlat()}
				</>
			)
		} else if (this.state.template_data?.servicesData?.card.pricingType.value === '3') {
			return (
				<>
					<strong>Pass-Through Card Price</strong>
					{this.getCardPricingPassThrought()}
				</>
			)
		} else if (this.state.template_data?.servicesData?.card.pricingType.value === '4') {
			return (
				<>
					<strong>Flat Card Price</strong>
					{this.getCardPricingFlat()}
					<strong className="mt-3">Pass-Through Card Price</strong>
					{this.getCardPricingPassThrought()}
				</>
			)
		}
	}

	getCardPricingICPlus() {
		const { tiers } = this.state.template_data?.servicesData?.card.cardICP
		return this.getTable(tiers, 'icp', 'Card')
	}

	getCardPricingFlat() {
		const { tiers } = this.state.template_data?.servicesData?.card.cardFlat
		return this.getTable(tiers, 'flat', 'Card')
	}

	getCardPricingPassThrought() {
		const { tiers } = this.state.template_data?.servicesData?.card.cardPassThrough
		return this.getTable(tiers, 'through', 'Card')
	}

	getCardFee() {
		if (this.state.template_data?.servicesData?.card.cardFees === null) return
		const cardFees = this.state.template_data?.servicesData?.card.cardFees
		return (
			<Table className="table-striped">
				{
					<thead>
						<tr key={1}>
							<th key={0}>Fee</th>
							<th key={1}>Amount</th>
						</tr>
					</thead>
				}
				{
					<tbody>
						{cardFees &&
							Object.entries(cardFees).map((k, j) =>
								k[1].visible ? (
									<tr key={j}>
										<td
											key={0}
											style={{ width: '68%' }}>
											{this.getFeeName(k[0])}
										</td>
										<td key={1}>$ {parseFloat(k[1].value || 0).toFixed(2)}</td>
									</tr>
								) : (
									<></>
								)
							)}
					</tbody>
				}
			</Table>
		)
	}
	getAchPricingAbsorb() {
		const { tiers } = this.state.template_data?.servicesData?.ach?.achAbsorb
		return this.getTable(tiers, 'absorb', 'ACH')
	}

	getAchPricingPassThrough() {
		const { tiers } = this.state.template_data?.servicesData?.ach?.achPassThrough
		return this.getTable(tiers, 'through', 'ACH')
	}

	getAchAcceptance() {
		if ((this.state.baseTemplate.services && this.state?.baseTemplate?.services?.ach === null) || (this.state.baseTemplate.services && this.state?.baseTemplate?.services?.ach.acceptance === null)) return
		var rows = []
		if (this.state.baseTemplate.services && this.state?.baseTemplate?.services?.ach.acceptance.web) {
			rows.push(
				<ReadOnlyCheckLink
					iInvisible={true}
					iTitle="Web"
					iName="ach_web"
					iValue={this.state?.baseTemplate?.services?.ach.acceptance.web.value}
				/>
			)
		}
		return (
			<Row className="mt-2 mb-2">
				<h6 className="mb-2">ACH Acceptance</h6>
				<br />
				<label className="mb-2 small">Integrated per transaction fee for ACH debits and credits.</label>
				<br />
				{rows}
			</Row>
		)
	}
	getAchPricing() {
		if (this.state.template_data?.servicesData?.ach?.pricingType === null || !this.state.template_data?.servicesData?.ach?.pricingType.value) return
		if (this.state.template_data?.servicesData?.ach?.pricingType.value === '5') {
			return (
				<>
					{/* <strong>Absorb ACH Price</strong> */}
					{this.getAchPricingAbsorb()}
				</>
			)
		} else if (this.state.template_data?.servicesData?.ach?.pricingType.value === '3') {
			return (
				<>
					{/* <strong>Pass-Through ACH Price</strong> */}
					{this.getAchPricingPassThrough()}
				</>
			)
		} else if (this.state.template_data?.servicesData?.ach?.pricingType.value === '6') {
			return (
				<>
					{/* <strong>Absorb ACH Price</strong> */}
					{this.getAchPricingAbsorb()}
					{/* <strong className="mt-3">Pass-Through ACH Price</strong> */}
					{this.getAchPricingPassThrough()}
				</>
			)
		}
	}

	getAchFee() {
		if (this.state.template_data?.servicesData?.ach?.achFees === null) return
		const achFees = this.state.template_data?.servicesData?.ach?.achFees
		if (achFees && achFees.visible === false) return
		return (
			<Table className="table-striped">
				{
					<thead>
						<tr key={1}>
							<th key={0}>Fee</th>
							<th key={1}>Amount</th>
						</tr>
					</thead>
				}
				{
					<tbody>
						{achFees &&
							Object.entries(achFees).map((k, j) =>
								k[1].visible ? (
									<tr key={j}>
										<td
											key={0}
											style={{ width: '65%' }}>
											{this.getFeeName(k[0])}
										</td>
										<td key={1}>$ {parseFloat(k[1].value || 0).toFixed(2)}</td>
									</tr>
								) : (
									<></>
								)
							)}
					</tbody>
				}
			</Table>
		)
	}

	sendLinkToApp(idApp) {
		this.setState({ appIdToModify: idApp, saveEmailModalIsOpen: true })
	}

	copyAppLink(idApp) {
		this.setState({ idAppLink: idApp, applicationLinkEmail: '' })
		this.openApplicationEmailModal()
	}
	updateStatus(idApp, appStatus) {
		this.props.boarding
			.updateApplicationStatus(idApp, appStatus)
			.then((res) => {
				// this.props.global.setLoading(false);
				toast.success('Status changed successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}
	sendDocumentToProcessor(idApp, filenumber) {
		const filename = this.state.baseData.documentsRef.filelist[filenumber - 1]?.zipName
		this.props.boarding
			.sendDocToProcessor(idApp, filename)
			.then((res) => {
				if (!res) {
					toast.error('Something is Wrong!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return
				}
				this.props.global.setLoading(false)
				toast.success('Document was sending to processor successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}
	sendToProcessor(idApp) {
		this.props.global.setLoading(true)
		this.props.boarding
			.sendToProcessor(idApp)
			.then((res) => {
				const { data } = res
				if (!data.isSuccess) {
					this.props.global.setLoading(false)
					toast.error(data?.responseText, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				} else {
					this.props.global.setLoading(false)
					toast.success('Application was sending to processor successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
					window.location.reload(false)
				}
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
				window.location.reload(false)
			})
	}
	openApplicationEmailModal() {
		this.setState({ applicationEmailModalIsOpen: true })
	}
	closeApplicationEmailModal() {
		this.setState({ applicationEmailModalIsOpen: false })
	}
	handleCopyApplicationEmail(value) {
		this.setState({ applicationLinkEmail: value })
		if (!this.props.global.validators.isEmpty(value) && !this.props.global.validators.stringValidator('email', value)) {
			this.setState({ applicationLinkEmailError: false })
		} else {
			this.setState({ applicationLinkEmailError: true })
		}
	}
	copyToClipboard(key) {
		navigator.clipboard.writeText(key)
		toast.success('Text copied successfully!', {
			position: toast.POSITION.BOTTOM_RIGHT,
			className: 'toast-success-container'
		})
	}
	openApplicationCopyLinkModal() {
		this.setState({ applicationCopyLinkModalIsOpen: true })
	}
	closeApplicationCopyLinkModal() {
		this.setState({ applicationCopyLinkModalIsOpen: false })
	}
	getApplicationLink() {
		if (this.state.applicationLinkEmailError || !this.state.applicationLinkEmail || this.state.applicationLinkEmail === '') {
			return
		}

		this.props.global.setLoading(true)
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		return axios
			.put(process.env.REACT_APP_URL_API + 'Boarding/applink/' + this.state.idAppLink + '/' + this.state.applicationLinkEmail, null, {
				headers: {
					requestToken: encryptStorage.getItem('pToken')
				}
			})
			.then((res) => {
				if (res && res.data.responseData) {
					let data = res.data.responseData
					this.setState(
						{
							appLink: data.appLink,
							referenceId: data.referenceId
						},
						function () {
							this.closeApplicationEmailModal()
							this.openApplicationCopyLinkModal()
						}
					)
				}
				this.props.global.setLoading(false)
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
				toast.error(errorMessage, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
	}
	cancelApp(idApp) {
		this.setState({ appIdToModify: idApp })
		this.openCancelAppModal()
	}
	openCancelAppModal() {
		this.setState({ cancelAppModalIsOpen: true })
	}
	closeCancelAppModal() {
		this.setState({ cancelAppModalIsOpen: false })
	}
	cancelAppAction() {
		let appIdToDelete = this.state.appIdToModify
		if (appIdToDelete) {
			this.props.global.setLoading(true)
			this.props.reports
				.cancelApp(appIdToDelete)
				.then((result) => {
					this.setState({ cancelAppModalIsOpen: false })
					this.props.global.setLoading(false)
					toast.success('Application deleted successfully!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-success-container'
					})
					this.props.history.push({
						pathname: '/' + PayabliStorageManager.getEntryName() + '/boarding/applications'
					})
				})
				.catch((error) => {
					this.props.global.setLoading(false)
					let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
				})
		}
	}
	handleEmailChange(value) {
		this.setState({ appemail: value })
	}
	redirectSso(pEntry) {
		let objThis = this
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
		let user = encryptStorage && encryptStorage.getItem('pUser') ? encryptStorage.getItem('pUser') : null
		let entryLogo = null

		if (user && user.id && pEntry.EntryName) {
			this.props.global.setLoading(true)

			this.props.entry
				.getEntryFromApi(pEntry.EntryName)
				.then((res) => {
					entryLogo = res?.responseData?.EntryLogo
					if (entryLogo === null) {
						entryLogo = PayabliCookieManager.readCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`) || 'payabli.png'
					}
					setTimeout(function () {
						SessionManager.getNewToken()
							.then((res) => {
								if (res) {
									PayabliCookieManager.createCookie(pEntry.EntryName + '_pToken', res, 1)
									PayabliCookieManager.createCookie(`${pEntry.EntryName}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`, objThis.props.global.normalizeFileUrl(entryLogo), 1)

									window.open(`${process.env.REACT_APP_URL_PAYPOINT}${pEntry.EntryName}/sso`, '_blank')
								} else {
									let errorMessage = "Something is Wrong. Can't open a PayPoint."
									toast.error(errorMessage, {
										position: toast.POSITION.BOTTOM_RIGHT,
										className: 'toast-error-container'
									})
								}
								objThis.props.global.setLoading(false)
							})
							.catch((error) => {
								objThis.props.global.setLoading(false)
								toast.error('Something is Wrong. ' + error, {
									position: toast.POSITION.BOTTOM_RIGHT,
									className: 'toast-error-container'
								})
							})
					}, 500)
				})
				.catch((error) => {
					Sentry.captureException(error)
					this.props.global.setLoading(false)
					let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : 'Something is Wrong!'
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					})
					return
				})
		}
	}

	saveEmailModal() {
		if (this.state.appemail === null || this.state.appemail === '' || this.state.appemail.length <= 0) return
		if (!this.props.global.emailValidation(this.state.appemail)) return
		this.saveToComplete()
	}
	closeSaveEmailModal() {
		this.setState({ saveEmailModalIsOpen: false, appemail: '' })
	}

	saveToComplete() {
		this.props.global.setLoading(true)
		this.props.boarding
			.sendLinkApplication(this.state.appIdToModify, this.state.appemail)
			.then((res) => {
				this.props.global.setLoading(false)
				toast.success('Application Link sent!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-success-container'
				})
			})
			.catch((error) => {
				this.props.global.setLoading(false)
				toast.error('Oops! Something went wrong sending email!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				})
			})
		this.setState({ saveEmailModalIsOpen: false, appemail: '' })
	}

	viewRecordDetails(url) {
		this.props.history.push(url)
	}

	render() {
		const rowBar = {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'row',
			gap: '1em'
		}
		const cellBar = {
			flex: '1 0 50px',
			boxSizing: 'border-box',
			maxWidth: '250px'
		}

		return (
			<Layout {...this.props}>
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.errorAppModalIsOpen}
					onHide={this.closeErrorAppModal}
					size="md"
					centered>
					<Modal.Body>
						<AiOutlineWarning
							color="orange"
							className="icon-modal"
						/>
						<h5>Notice!</h5>
						<p className="small">
							This application does not have Boarding Link.
							<br />
							<small className="grey">YOU MUST CONFIGURE IT TO CONTINUE</small>
						</p>
						<div className="row">
							<div className="col-sm-12 col-md-12">
								<button
									className="btn full-w btn-light"
									type="button"
									onClick={(e) => this.closeErrorAppModal()}>
									GO BACK
								</button>
							</div>
							{/* <div className="col-sm-12 col-md-6">
                                <button
                                className="btn full-w btn-primary"
                                onClick={(e) => this.createLinkTpl(this.state.templateId || -1, true)}
                                >
                                CONFIGURE BOARDING LINK
                                </button>
                            </div> */}
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.cancelAppModalIsOpen}
					onHide={this.closeCancelAppModal}
					size="sm"
					centered>
					<Modal.Body>
						<BiTrash className="icon-modal" />
						<h5>Delete</h5>
						<p className="small">Are you sure you want to delete this application?</p>
						<Button
							className="btn cancel-btn"
							variant="default"
							onClick={(e) => this.closeCancelAppModal()}>
							Cancel
						</Button>
						&nbsp;&nbsp;
						<Button
							className="btn"
							variant="danger"
							onClick={(e) => this.cancelAppAction()}>
							Delete
						</Button>
					</Modal.Body>
				</Modal>
				{this.state.saveEmailModalIsOpen && (
					<SendEmailModal
						saveEmailModalIsOpen={this.state.saveEmailModalIsOpen}
						closeSaveEmailModal={this.closeSaveEmailModal}
						saveEmailModal={this.saveEmailModal}
						appemail={this.state.appemail}
						handleEmailChange={(value) => this.handleEmailChange(value)}
					/>
				)}
				<Modal
					style={{ textAlign: 'center' }}
					show={this.state.applicationEmailModalIsOpen}
					onHide={this.closeApplicationEmailModal}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Body>
						<BiCopyAlt className="icon-modal" />
						<h5>Copy Application Link</h5>
						<p className="small">Enter an email to create a link for this application. Proceed to next step to get a shareable link.</p>
						<p className="small-grey">{this.state.transIdToSendReceipt}</p>

						<IMaskInput
							mask={this.props.global.maskValidator('email')}
							value={this.state.applicationLinkEmail}
							unmask={true}
							placeholder="name@example.com"
							className={this.state.applicationLinkEmailError ? 'form-control mb-3 input-error' : 'form-control mb-3'}
							onAccept={(value, mask) => this.handleCopyApplicationEmail(value)}
						/>
						<div className="row">
							<div className="col">
								<Button
									className="btn full-w  cancel-btn"
									variant="default"
									onClick={this.closeApplicationEmailModal}>
									Cancel
								</Button>
							</div>
							<div className="col">
								<Button
									className="btn full-w"
									variant="primary"
									onClick={this.getApplicationLink}>
									Next
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<Modal
					style={{ textAlign: 'left' }}
					show={this.state.applicationCopyLinkModalIsOpen}
					onHide={this.closeCancelAppModal}
					size="md"
					centered>
					<Modal.Body>
						<h5>Copy Application Link & Reference ID</h5>
						<p className="small">You can click on the icon to copy the Application Link and reference ID; both are needed for your user to gain access.</p>

						<p className="small">
							<BiCopyAlt
								onClick={() => this.copyToClipboard(this.state.appLink)}
								className="cursor-pointer"
								style={{ fontSize: '16px' }}
							/>
							&nbsp;&nbsp;&nbsp;{this.state.appLink}
						</p>
						<p className="small">
							<BiCopyAlt
								onClick={() => this.copyToClipboard(this.state.referenceId)}
								className="cursor-pointer"
								style={{ fontSize: '16px' }}
							/>
							&nbsp;&nbsp;&nbsp;{this.state.referenceId}
						</p>

						<Button
							className="btn cancel-btn full-w mt-2"
							variant="default"
							onClick={(e) => this.closeApplicationCopyLinkModal()}>
							Close Window
						</Button>
					</Modal.Body>
				</Modal>
				{this.props.global.isLoading ? (
					<div
						id="main-loading-layer"
						className="d-flex justify-content-center align-items-center"></div>
				) : (
					<div>
						<TopBar>
							<MainTopBarMenu />
							<div className="top-bar-sub">
								<button
									className="cursorPointer btn btn-light text-transform-normal"
									onClick={(e) => this.viewRecordDetails('/' + this.props.global.getURLEntry() + '/boarding/applications/')}>
									{'< Application list'}
								</button>
								{/* <BoardingLinks gobackText="Application list" selected="applications" goback="true" gobackHref="/boarding/applications" /> */}
							</div>
						</TopBar>
						<div className="mt-body4">
							<div style={rowBar}>
								<div style={cellBar}>
									{this.state.baseData && this.state.baseData.dbaName ? (
										<>
											<h5
												className="mt-2 mb-0"
												data-qaid="viewApplicationPage">
												{this.state.baseData.dbaName}
											</h5>
											<p
												className="small-grey mt-0"
												data-qaid="viewAppPage">
												View Application
											</p>
										</>
									) : (
										<h5 className="mt-2">View Application</h5>
									)}
								</div>
								<div style={{ flex: '1 0 50px', boxSizing: 'border-box', maxWidth: '370px' }}>
									{this.state.boardingStatus !== 100 && this.state.boardingStatus !== 0 && (
										<ApplicationStatus
											idApp={this.state.appId}
											changeStatus={this.changeStatus}
											status={this.props.boarding.template_data.boardingStatus ? this.props.boarding.template_data.boardingStatus.value : this.state.baseData ? this.state.baseData.boardingStatus : 0}
										/>
									)}
								</div>
								<div>
									{this.state.showSend2Processor && (
										<button
											onClick={(e) => this.sendToProcessor(this.state.appId)}
											className="btn btn-outline-primary full-w">
											{!this.state.wasSendingToProcessor ? 'Submit to Processor' : 'Resubmit to Processor'}
										</button>
									)}
								</div>
								<div style={cellBar}>
									{this.state.boardingStatus < 1 && (
										<>
											<DropdownButton
												menuAlign="left"
												title="Actions"
												id="actionsMenuButton"
												size="sm"
												variant="primary"
												className="btn btn-bordered-success p-0">
												{this.state.boardingStatus !== 10 && this.state.boardingStatus !== 99 && this.state.boardingStatus !== 100 && this.state.boardingStatus !== 0 && (
													<Dropdown.Item
														style={{ fontSize: '12px' }}
														onClick={(e) => this.viewRecordDetails('/' + PayabliStorageManager.getEntryName() + '/boarding/applicationedit/' + this.state.appId)}>
														Edit
													</Dropdown.Item>
												)}
												<Dropdown.Item
													style={{ fontSize: '12px' }}
													onClick={(e) => this.sendLinkToApp(this.state.appId)}>
													Send link via Email
												</Dropdown.Item>
												<Dropdown.Item
													style={{ fontSize: '12px' }}
													onClick={(e) => this.copyAppLink(this.state.appId)}>
													Copy App. Link
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item
													style={{ color: 'rgb(192, 0, 0)', fontSize: '12px' }}
													onClick={(e) => this.cancelApp(this.state.appId)}>
													Delete
												</Dropdown.Item>
											</DropdownButton>
										</>
									)}
								</div>
							</div>
							{this.state.payPointId !== null && (
								<div
									className="btn"
									style={{ border: 'solid 1px #92D050', width: '56.5%', textTransform: 'none', fontSize: '12px', cursor: 'text' }}>
									<span style={{ color: '#92D050' }}>
										This application was converted to a PayPoint on {Moment(this.state.createDatePayPoint).format('MMMM Do YYYY')} at {this.props.global.stringTimeFormat(this.state.createDatePayPoint)} {this.props.global.getTimeZone('v1')}{' '}
										{this.state.entryPoint && (
											<button
												className="btn btn-link"
												style={{ textDecoration: 'none', cursor: 'pointer' }}
												onClick={() => this.redirectSso(this.state.entryPoint)}
												rel="noreferrer">
												(GO TO PAYPOINT)
											</button>
										)}
									</span>
								</div>
							)}
							{this.state.boardingStatus === 10 && this.state.payPointId === null && (
								<div
									className="mb-5"
									style={{ width: '56.5%' }}>
									<button
										onClick={(e) => this.onCreatePaypoint(e)}
										className="btn full-w"
										style={{ color: '#fff', backgroundColor: '#92D050', border: 'solid 1px #92D050' }}>
										Click to Create Paypoint
									</button>
								</div>
							)}

							<div
								className="application-form view-application-form"
								style={{ marginTop: 0 }}>
								<div
									className="application-tabs"
									style={{ padding: 0 }}>
									{this.state.baseTemplate && (
										<Tabs
											defaultActiveKey={this.state.currentSection}
											className="flex-nowrap">
											<Tab
												eventKey="business"
												title={
													<>
														<BiDetail className="icon" />
														<br />
														<span>Business Details</span>
													</>
												}>
												<h5>Business Details</h5>
												<p className="small mb-4 dark-grey">You have an awesome business, tell us a little bit about it! </p>
												{this.getBusinessBlock()}
												{this.getBusinessAddressBlock()}
											</Tab>
											<Tab
												eventKey="owners"
												title={
													<>
														<BiUserCheck className="icon" />
														<br />
														<span>Ownership and Contacts</span>
													</>
												}>
												<h5>Ownership and Contacts</h5>
												<p className="small mb-4 dark-grey">Federal Law requires us to collect some information on the owners, the business, and whoever will be signing the Merchant Agreement.</p>
												{this.getOwnerBlock(0)}
												{this.state.additionalOwners.length > 0 &&
													this.state.additionalOwners.map((k) => (
														<Row
															className="mt-2"
															key={k}>
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownername && (
																<ReadOnlySimpleLink
																	iTitle="Owner Name"
																	iName={'ownername_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownername : this.state.baseTemplate.owners.own_list.ownername.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownertitle && (
																<ReadOnlySimpleLink
																	iTitle="Owner Title"
																	iName={'ownertitle_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownertitle : this.state.baseTemplate.owners.own_list.ownertitle.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerpercent && (
																<ReadOnlySimpleLink
																	iTitle="Ownership %"
																	iName={'ownerpercent_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerpercent : this.state.baseTemplate.owners.own_list.ownerpercent.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerssn && (
																<ReadOnlySimpleLink
																	iHideShow={true}
																	iTitle="Owner SSN"
																	iName={'ownerssn_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerssn : this.state.baseTemplate.owners.own_list.ownerssn.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerdob && (
																<ReadOnlySimpleLink
																	iTitle="Date of Birth"
																	iName={'ownerdob_' + k}
																	iValue={this.props.global.stringDateFormat(this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdob : this.state.baseTemplate.owners.own_list.ownerdob.value)}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerphone1 && (
																<ReadOnlySimpleLink
																	iTitle="Phone Number"
																	iName={'ownerphone1_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone1 : this.state.baseTemplate.owners.own_list.ownerphone1.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerphone2 && (
																<ReadOnlySimpleLink
																	iTitle="Additional Phone Number"
																	iName={'ownerphone2_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerphone2 : this.state.baseTemplate.owners.own_list.ownerphone2.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.owneremail && (
																<ReadOnlySimpleLink
																	iTitle="Email Address"
																	iName={'owneremail_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].owneremail : this.state.baseTemplate.owners.own_list.owneremail.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ownerdriver && (
																<ReadOnlySimpleLink
																	iTitle="Driver's License #"
																	iName={'ownerdriver_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ownerdriver : this.state.baseTemplate.owners.own_list.ownerdriver.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.odriverstate && (
																<ReadOnlySimpleLink
																	iTitle="Driver's State License"
																	iName={'odriverstate_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].odriverstate : this.state.baseTemplate.owners.own_list.odriverstate.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.oaddress && (
																<ReadOnlySimpleLink
																	iTitle="Owner Address"
																	iName={'oaddress_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].oaddress : this.state.baseTemplate.owners.own_list.oaddress.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ocity && (
																<ReadOnlySimpleLink
																	iTitle="City"
																	iName={'ocity_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocity : this.state.baseTemplate.owners.own_list.ocity.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ostate && (
																<ReadOnlySimpleLink
																	iTitle="State"
																	iName={'ostate_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ostate : this.state.baseTemplate.owners.own_list.ostate.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ozip && (
																<ReadOnlySimpleLink
																	iTitle="Zip"
																	iName={'ozip_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ozip : this.state.baseTemplate.owners.own_list.ozip.value}
																/>
															)}
															{this.state.baseTemplate.owners && this.state.baseTemplate.owners.own_list.ocountry && (
																<ReadOnlySimpleLink
																	iTitle="Country"
																	iName={'ocountry_' + k}
																	iValue={this.state.baseData.ownerData[k] ? this.state.baseData.ownerData[k].ocountry : this.state.baseTemplate.owners.own_list.ocountry.value}
																/>
															)}
														</Row>
													))}

												{this.getContactBlock(0)}
												{this.state.additionalContacts.length > 0 &&
													this.state.baseTemplate.owners &&
													this.state.baseTemplate.owners.contact_list !== null &&
													this.state.additionalContacts.map((k) => (
														<Row
															className="mt-2"
															key={k}>
															{this.state.baseTemplate.owners.contact_list.contactName && (
																<ReadOnlySimpleLink
																	iTitle="Contact Name"
																	iName={'contactName_' + k}
																	iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactName : this.state.baseTemplate.owners.contact_list.contactName.value}
																/>
															)}
															{this.state.baseTemplate.owners.contact_list.contactTitle && (
																<ReadOnlySimpleLink
																	iTitle="Contact Title"
																	iName={'contactTitle_' + k}
																	iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactTitle : this.state.baseTemplate.owners.contact_list.contactTitle.value}
																/>
															)}
															{this.state.baseTemplate.owners.contact_list.contactEmail && (
																<ReadOnlySimpleLink
																	iTitle="Contact Email"
																	iName={'contactEmail_' + k}
																	iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactEmail : this.state.baseTemplate.owners.contact_list.contactEmail.value}
																/>
															)}
															{this.state.baseTemplate.owners.contact_list.contactPhone && (
																<ReadOnlySimpleLink
																	iTitle="Contact Phone"
																	iName={'contactPhone_' + k}
																	iValue={this.state.baseData.contactData[k] ? this.state.baseData.contactData[k].contactPhone : this.state.baseTemplate.owners.contact_list.contactPhone.value}
																/>
															)}
														</Row>
													))}
											</Tab>
											<Tab
												eventKey="processing"
												title={
													<>
														<BiCog className="icon" />
														<br />
														<span>Processing Information</span>
													</>
												}>
												<h5>Processing Information</h5>
												<p className="small mb-4 dark-grey">We are so proud to power your payment processing, share with us your needs.</p>
												{this.getProcessingBlock()}
											</Tab>
											<Tab
												eventKey="services"
												title={
													<>
														<BiDollarCircle className="icon" />
														<br />
														<span>Services and Pricing</span>
													</>
												}>
												<h5>Services and Pricing</h5>
												<p className="small mb-4 dark-grey">Below you will find the rates associated with your Payment Processing Account.</p>
												{this.getCardAcceptance()}
												<h6 className="mb-2">Setup</h6>
												<Row className="mt-0">
													<div className="col-md-6">
														<InputSelectTemplate
															iDisabled={true}
															iNoToggle={true}
															iType="gateway_card"
															iTitle="Gateway"
															iVisible={true}
															iReadOnly={true}
															iName="card_gateway"
															iValue={this.state.template_data?.servicesData?.card.gateway?.value}
														/>
														<InputSelectTemplate
															iDisabled={true}
															iNoToggle={true}
															iType="processor_card"
															iTitle="Processor"
															iVisible={true}
															iReadOnly={true}
															iName="card_processor"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.card.processor?.value}
														/>
														<InputSelectTemplate
															iDisabled={true}
															iNoToggle={true}
															iType="provider"
															iTitle="Provider"
															iVisible={true}
															iReadOnly={true}
															iName="card_provider"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.card.provider?.value}
														/>
														<ReadOnlySimpleLink
															iTitle="Tier Name"
															iVisible={true}
															iReadOnly={true}
															iName="card_tierName"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.card.tierName?.value}
														/>
														<ReadOnlySimpleLink
															iTitle="PDF Template ID"
															iVisible={true}
															iReadOnly={true}
															iName="card_pdfTemplateId"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.card.pdfTemplateId?.value}
														/>
													</div>
													<div className="col-md-6">
														<InputSelectLink
															iDisabled={true}
															iType="discount-freq"
															iTitle="Discount Frequency"
															iVisible={this.state.template_data?.servicesData?.card.discountFrequency.visible}
															iReadOnly={this.state.template_data?.servicesData?.card.discountFrequency.readOnly}
															iName="card_discountFrequency"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.card.discountFrequency.value}
														/>
														<InputSelectLink
															iDisabled={true}
															iType="funding-type"
															iTitle="Funding RollUp"
															iVisible={this.state.template_data?.servicesData?.card.fundingRollup.visible}
															iReadOnly={this.state.template_data?.servicesData?.card.fundingRollup.readOnly}
															iName="card_fundingRollup"
															iValue={this.state.template_data?.servicesData?.card.fundingRollup.value}
														/>
														{/* <ReadOnlySimpleLink iTitle="Pass-Through Cost" iVisible={this.state.template_data?.servicesData?.card.passThroughCost.visible} iReadOnly={this.state.template_data?.servicesData?.card.passThroughCost.readOnly} iName="card_passThroughCost" iValue={this.state.template_data?.servicesData?.card.passThroughCost.value} /> */}
													</div>
												</Row>
												{this.state.baseTemplate.services && this.state?.baseTemplate?.services?.card !== null && (
													<Row className="mt-2">
														<ReadOnlyCheckLink
															iInvisible={true}
															iTitle="Accept Card Processing Services"
															iName="card"
															iValue={true}
														/>
													</Row>
												)}
												<h6 className=" mb-4">Pricing</h6>
												<Row className="mt-4">
													<div className="col-md-6">
														<InputSelectLink
															iDisabled={true}
															iType="card-pricing"
															iTitle="Pricing Type"
															iVisible={this.state.template_data?.servicesData?.card.pricingType.visible}
															iReadOnly={this.state.template_data?.servicesData?.card.pricingType.readOnly}
															iName="card_pricingType"
															iValue={this.state.template_data?.servicesData?.card.pricingType.value}
														/>
													</div>
												</Row>
												{this.getCardPricing()}
												{this.getCardFee()}
												<br />
												{this.getAchAcceptance()}
												<h6 className="mb-2">Setup</h6>
												<Row className="mt-2">
													<div className="col-md-6">
														<InputSelectTemplate
															iDisabled={true}
															iNoToggle={true}
															iType="gateway_ach"
															iTitle="Gateway"
															iVisible={true}
															iReadOnly={true}
															iName="ach_gateway"
															iValue={this.state.template_data?.servicesData?.ach?.gateway?.value}
														/>
														<InputSelectTemplate
															iDisabled={true}
															iNoToggle={true}
															iType="processor_ach"
															iTitle="Processor"
															iVisible={true}
															iReadOnly={true}
															iName="ach_processor"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.ach?.processor?.value}
														/>
														<InputSelectTemplate
															iDisabled={true}
															iNoToggle={true}
															iType="provider"
															iTitle="Provider"
															iVisible={true}
															iReadOnly={true}
															iName="ach_provider"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.ach?.provider?.value}
														/>
														<ReadOnlySimpleLink
															iTitle="Tier Name"
															iVisible={true}
															iReadOnly={true}
															iName="ach_tierName"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.ach?.tierName?.value}
														/>
														<ReadOnlySimpleLink
															iTitle="PDF Template ID"
															iVisible={true}
															iReadOnly={true}
															iName="ach_pdfTemplateId"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.ach?.pdfTemplateId?.value}
														/>
														<InputSelectLink
															iDisabled={true}
															iType="ach-pricing"
															iTitle="Pricing Type"
															iVisible={this.state.template_data?.servicesData?.ach?.pricingType.visible}
															iReadOnly={this.state.template_data?.servicesData?.ach?.pricingType.readOnly}
															iName="ach_pricingType"
															iValue={this.state.template_data?.servicesData?.ach?.pricingType.value}
														/>
													</div>
													<div className="col-md-6">
														<InputSelectLink
															iDisabled={true}
															iType="discount-freq"
															iTitle="Discount Frequency"
															iVisible={this.state.template_data?.servicesData?.ach?.discountFrequency.visible}
															iReadOnly={this.state.template_data?.servicesData?.ach?.discountFrequency.readOnly}
															iName="ach_discountFrequency"
															iPlaceholder=""
															iValue={this.state.template_data?.servicesData?.ach?.discountFrequency.value}
														/>
														<InputSelectLink
															iDisabled={true}
															iType="funding-type"
															iTitle="Funding RollUp"
															iVisible={this.state.template_data?.servicesData?.ach?.fundingRollup.visible}
															iReadOnly={this.state.template_data?.servicesData?.ach?.fundingRollup.readOnly}
															iName="ach_fundingRollup"
															iValue={this.state.template_data?.servicesData?.ach?.fundingRollup.value}
														/>
													</div>
												</Row>
												{this.state.baseTemplate.services && this.state?.baseTemplate?.services?.ach !== null && (
													<Row className="mt-0">
														<ReadOnlyCheckLink
															iInvisible={true}
															iTitle="Accept ACH Processing Services"
															iName="ach"
															iValue={true}
														/>
													</Row>
												)}
												<h6 className=" mb-2">Pricing</h6>
												{this.getAchPricing()}
												{this.getAchFee()}
											</Tab>
											<Tab
												eventKey="banking"
												title={
													<>
														<RiBankLine className="icon" />
														<br />
														<span>Banking and Documents</span>
													</>
												}>
												<h5>Banking and Documents</h5>
												<p className="small mb-4 dark-grey">We are almost done! Please provide us with some important supporting documents and where you want us to send your funds </p>
												{this.state.baseTemplate.banking !== null && (this.state?.baseTemplate?.banking?.depositAccount !== null || this.state?.baseTemplate?.banking?.withdrawalAccount !== null) && (
													<Row className="mt-4">
														{this.state?.baseTemplate?.banking?.depositAccount !== null && (
															<div className="col-6">
																<p className="mb-3">
																	<b>Deposit Account</b>
																</p>
																{this.state?.baseTemplate?.banking?.depositAccount.bankName && (
																	<ReadOnlySimpleLink
																		full
																		iTitle="Bank Name"
																		iName="deposit_bankName"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].bankName : this.state?.baseTemplate?.banking?.depositAccount.bankName.value}
																	/>
																)}
																{this.state?.baseTemplate?.banking?.depositAccount.routingAccount && (
																	<ReadOnlySimpleLink
																		full
																		iTitle="Routing Number"
																		iName="deposit_routingAccount"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].routingAccount : this.state?.baseTemplate?.banking?.depositAccount.routingAccount.value}
																	/>
																)}
																{this.state?.baseTemplate?.banking?.depositAccount.accountNumber && (
																	<ReadOnlySimpleLink
																		iHideShow={true}
																		full
																		iTitle="Account Number"
																		iName="deposit_accountNumber"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].accountNumber : this.state?.baseTemplate?.banking?.depositAccount.accountNumber.value}
																	/>
																)}
																{this.state?.baseTemplate?.banking?.depositAccount.typeAccount && (
																	<ReadOnlySimpleLink
																		full
																		iTitle="Account Type"
																		iName="deposit_typeAccount"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[0] ? this.state.baseData.bankData[0].typeAccount : this.state?.baseTemplate?.banking?.depositAccount.typeAccount.value}
																	/>
																)}
															</div>
														)}

														{this.state?.baseTemplate?.banking?.withdrawalAccount !== null && (
															<div className="col-6">
																<p className="mb-3">
																	<b>Withdrawal Account</b>
																</p>
																{this.state?.baseTemplate?.banking?.withdrawalAccount.bankName && (
																	<ReadOnlySimpleLink
																		full
																		iTitle="Bank Name"
																		iName="withdrawal_bankName"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].bankName : this.state?.baseTemplate?.banking?.withdrawalAccount.bankName.value}
																	/>
																)}
																{this.state?.baseTemplate?.banking?.withdrawalAccount.routingAccount && (
																	<ReadOnlySimpleLink
																		full
																		iTitle="Routing Number"
																		iName="withdrawal_routingAccount"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].routingAccount : this.state?.baseTemplate?.banking?.withdrawalAccount.routingAccount.value}
																	/>
																)}
																{this.state?.baseTemplate?.banking?.withdrawalAccount.accountNumber && (
																	<ReadOnlySimpleLink
																		iHideShow={true}
																		full
																		iTitle="Account Number"
																		iName="withdrawal_accountNumber"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].accountNumber : this.state?.baseTemplate?.banking?.withdrawalAccount.accountNumber.value}
																	/>
																)}
																{this.state?.baseTemplate?.banking?.withdrawalAccount.typeAccount && (
																	<ReadOnlySimpleLink
																		full
																		iTitle="Account Type"
																		iName="withdrawal_typeAccount"
																		iValue={this.state.baseData.bankData !== null && this.state.baseData.bankData[1] ? this.state.baseData.bankData[1].typeAccount : this.state?.baseTemplate?.banking?.withdrawalAccount.typeAccount.value}
																	/>
																)}
															</div>
														)}
													</Row>
												)}
												<br />
												{this.state.baseTemplate.attributes && this.state.baseTemplate.attributes.minimumDocuments !== null && parseInt(this.state.baseTemplate.attributes.minimumDocuments, 10) > 0 && this.getDocumentBlock()}
												{this.getSignerBlock()}
											</Tab>
											<Tab
												eventKey="message"
												title={
													<>
														<BiNotepad className="icon" />
														<br />
														<span>Notes & Events</span>
													</>
												}>
												<ManagementApplication>
													<NotesEventTabContaier
														appId={this.state.appId}
														notesEvents={this.state.notesEvents}
														setNotesEvents={(data) => this.setState({ notesEvents: data })}
													/>
												</ManagementApplication>
											</Tab>
										</Tabs>
									)}
								</div>
								<ToastContainer transition={Bounce} />
							</div>
						</div>
						<br />
						<br />
					</div>
				)}
			</Layout>
		)
	}
}

export { ViewApplication }
