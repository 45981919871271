import React from 'react';
import { inject, observer } from 'mobx-react';


@inject('global')
@observer
class CardPaymentInformation extends React.Component {

    constructor(props) {
        super(props);
        this.renderCardPaymentInformation = this.renderCardPaymentInformation.bind(this);
    }

    renderCardPaymentInformation(recordDetail){
        if (recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                            <label className="grey">Card Number</label>
                        <h5 style={{fontWeight: 500}}>
                        •••• •••• {recordDetail.PaymentData && recordDetail.PaymentData.MaskedAccount ? this.props.global.maskedCardNumber(recordDetail.PaymentData.MaskedAccount, 'v5') : '-'}
                        </h5>
                    </div> 
                    <div className="col-5 text-center">
                        <label className="grey">Expires on</label>
                        <h5 style={{fontWeight: 500}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.AccountExp ? this.props.global.expDateFormat(recordDetail.PaymentData.AccountExp) : '-'}
                        </h5>
                    </div>   
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="grey">Cardholder Name</label>
                        <h6 style={{fontWeight: 500}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.HolderName ? recordDetail.PaymentData.HolderName : '-'}
                        </h6>
                    </div>
                </div>
            </>);
        } else if (recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "ach") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                            <label className="grey">Account Number</label>
                        <h5 style={{fontWeight: 500}}>
                        •••• •••• {recordDetail.PaymentData && recordDetail.PaymentData.MaskedAccount ? this.props.global.maskedCardNumber(recordDetail.PaymentData.MaskedAccount, 'v5') : '-'}
                        </h5>
                    </div>           
                    <div className="col-5 text-center">
                        <label className="grey">Account Type</label>
                        <h5 style={{fontWeight: 500}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.AccountType ? recordDetail.PaymentData.AccountType  : '-'}
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="grey">Account Holder Name</label>
                        <h6 style={{fontWeight: 500}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.HolderName ? recordDetail.PaymentData.HolderName : '-'}
                        </h6>
                    </div>
                </div>
            </>);
        } else if (recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "check") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                    <label className="grey">Account Holder Name</label>
                        <h6 style={{fontWeight: 500, fontSize: '1rem'}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.HolderName ? recordDetail.PaymentData.HolderName : '-'}
                        </h6>    
                    </div>     
                    <div className="col-5 text-center">
                        <label className="grey">Payment Method</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>Physical Check</h5>
                    </div>   
                </div>
                <div className="row">
                    <div className="col-12">
                    <label className="grey">Check #</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>
                            {recordDetail.PaymentData && recordDetail.PaymentData.paymentDetails && recordDetail.PaymentData.paymentDetails.checkNumber ? recordDetail.PaymentData.paymentDetails.checkNumber : '-'}
                        </h5>
                    </div>
                </div>     
            </>);
        } else if (recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "cash") {
            return (<>
                <div className="row mb-2">
                    <div className="col-7">
                        <label className="grey">Payor Name</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>
                            {recordDetail.Customer ? (recordDetail.Customer.FirstName ? recordDetail.Customer.FirstName : "") + ' ' + (recordDetail.Customer.LastName ? recordDetail.Customer.LastName : "") : '-'}
                        </h5>
                    </div>
                    <div className="col-5">
                    <label className="grey">Payment Method</label>
                        <h5 style={{fontWeight: 500, fontSize: '1rem'}}>Cash</h5>
                    </div>    
                </div>
            </>);
        }
    }

    render() {
        let recordDetail = this.props.recordDetail;
        return (
            <>

                {recordDetail && 
                    this.renderCardPaymentInformation(recordDetail)
                }
            </>
        );
    }
}

export { CardPaymentInformation };